<template>
  <v-text-field
    :value="searchValue"
    placeholder="Find in view"
    solo
    flat
    height="29"
    :suffix="getSuffixPlaceholder"
    :loading="searchingLoader"
    prepend-inner-icon="mdi-magnify"
    dense
    hide-details
    @input="search">
    <template
      v-if="searchValue"
      #append>
      <v-icon
        v-for="arrow in arrows"
        :key="arrow.icon"
        class="v-icon v-icon__control-arrows"
        :disabled="searchingLoader || disabledSearchingActions"
        @click="arrowControl(arrow.method)">
        {{ arrow.icon }}
      </v-icon>
      <v-icon
        v-if="searchValue"
        class="ml-2"
        @click="searchValue = null">
        mdi-close
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { debounce } from 'lodash';
import { DEBOUNCE_TIME_FOR_SEARCHING } from '@/constants';
const DEBOUNCE_TIME_FOR_ARROW_CONTROL = 150;
export default {
  name: 'ProjectDetailsSearchCells',
  data() {
    return {
      arrows: {
        down: {
          icon: 'mdi-chevron-down',
          method: 'arrowUp',
        },
        up: {
          icon: 'mdi-chevron-up',
          method: 'arrowDown',
        },
      },
    };
  },
  computed: {
    ...mapState('SearchProjectCells', ['searchingLoader', 'currentIndexResultItem', 'searchString']),
    ...mapGetters('SearchProjectCells', ['searchingResultLength', 'getCurrentResultItem']),
    searchValue: {
      get() {
        return this.searchString;
      },
      set(val) {
        this.setSearchString(val);
      },
    },
    currentSearchingElementId() {
      const { field, rowId, isListing } = this.getCurrentResultItem || {
      };
      if (!rowId || (!field && !isListing)) return '';
      return isListing ? rowId : `${rowId}--${field}`;
    },
    currentIndex: {
      get() {
        return this.currentIndexResultItem;
      },
      set(val) {
        const { searchingResultLength: leng } = this;
        let value = val;
        if (val === 0) {
          value = leng;
        } else if (val > leng) {
          value = 1;
        }
        this.setCurrentIndexResultItem(value);
      },
    },
    disabledSearchingActions() {
      const { searchValue, searchingResultLength } = this;
      return !(searchValue && searchingResultLength);
    },
    getSuffixPlaceholder() {
      const { disabledSearchingActions: disabled, currentIndex: curr, searchingResultLength: leng, searchValue } = this;
      if (!searchValue) return '';
      if (disabled) return '0 of 0';
      return `${curr} of ${leng}`;
    },
  },
  watch: {
    searchValue(val) {
      if (val) {
        return;
      }
      this.resetSearching();
    },
    currentIndex: {
      async handler(val) {
        await this.$nextTick();
        const { currentSearchingElementId: id } = this;
        if (!val || !id) {
          return;
        }
        const el = document.getElementById(id);
        if (!el) {
          return;
        }
        el.scrollIntoView({
          block: 'center',
          inline: 'center',
        });
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.searchValue = null;
    this.resetSearching();
  },
  methods: {
    ...mapActions('SearchProjectCells', ['searchProjectCells', 'resetSearching']),
    ...mapMutations('SearchProjectCells', ['setCurrentIndexResultItem', 'setSearchString']),
    search(e) {
      this.resetSearching();
      this.debouncedSearch(e);
    },
    debouncedSearch: debounce(function(e) {
      this.searchHandle(e);
    }, DEBOUNCE_TIME_FOR_SEARCHING),
    debouncedArrowControl: debounce(function(method) {
      this[method]();
    }, DEBOUNCE_TIME_FOR_ARROW_CONTROL),
    arrowControl(method) {
      if (!method) return;
      this.debouncedArrowControl(method);
    },
    arrowDown() {
      this.currentIndex -= 1;
    },
    arrowUp() {
      this.currentIndex += 1;
    },
    searchHandle(val) {
      this.searchValue = val;
      if (!val) {
        return;
      }
      this.searchProjectCells({
        projectId: this.$route.params.id,
        query: val,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  div.v-text-field.v-input {
    border-radius: 50px !important;
  }
  .v-text-field, .v-input {
    border: 1px solid var(--v-borderGrey-base);
    max-width: 250px;
    ::v-deep input {
      font-size: 14px;
    }
    ::v-deep &__control {
      max-height: 27px !important;
      min-height: 27px !important;
    }
    ::v-deep &__suffix {
      font-size: 12px;
      color: var(--v-lightGrey-base)
    }
    .v-icon {
      font-size: 13px;
      &__control-arrows {
        background: #bac2c37a;
        color: var(--lightBlack-base);
        font-size: 19px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
</style>
