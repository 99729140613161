<template>
  <div
    class="wrapper d-flex align-center">
    <div
      v-if="!editMode && !isExpandedMode"
      class="d-flex align-center"
      style="height: 62px">
      <span
        class="ma-0 pointer"
        @click.exact="showCommentsModalHandler"
        @dblclick.stop="doubleClick">
        <template>
          <v-tooltip
            :disabled="showTooltip(value).showTool"
            bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on">
                {{ showTooltip(value).item }}
              </span>
            </template>
            <span>{{ item['Product Reference'] }}</span>
          </v-tooltip>
        </template>
        <span
          v-if="!expandedRow && !hiddenRow && userId"
          style="width: 0; height: 0"
          class="mb-0">
          <CommentBadge
            data-test="open_project_comments_button"
            :item="{
              id: item.id,
              commentsAmount: amount,
            }" />
        </span>
      </span>
    </div>
    <template
      v-else
      class="input-wrapper">
      <v-text-field
        v-model="newValue"
        counter
        :disabled="!editMode"
        :outlined="isExpandedMode"
        :clearable="!isExpandedMode"
        maxlength="10"
        :value=" Array.isArray(value) ? value.join(', ') : value "
        :autofocus="!expandedRow"
        @blur="update" />
    </template>
  </div>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
import CommentBadge from '@/components/ProjectDetails/ProjectDetailsCommentBadge';
import {
  mapState,
  mapMutations,
  mapGetters,
  mapActions,
} from 'vuex';
import { debounce } from 'lodash';
export default {
  name: 'ProductCellTypeColV2',
  components: {
    CommentBadge,
  },
  mixins: [EditModeChildComponent],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      required: true,
    },
    hiddenRow: {
      type: Boolean,
      default: false,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
  },
  data: function () {
    return {
      newValue: null,
      doubleClicked: false,
    };
  },
  computed: {
    ...mapState(['activeHeader']),
    ...mapGetters(['userId']),
    ...mapGetters('Comments', ['slant']),
    ...mapGetters(['userId']),
    isCollection() {
      return ['collection-library', 'community-collection'].includes(this.$route.name);
    },
    amount() {
      return this.slant(this.item.id)?.commentsAmount;
    },
  },
  watch: {
    value: {
      handler(val) {
        this.newValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('Collections', ['changeUpdateProductMode', 'changeProductModalVariant', 'setProductToUpdate']),
    ...mapActions('ProjectDetailsTableSchedule', ['showCommentsModalTrigger']),
    showCommentsModalHandler: debounce(function () {
      if (this.doubleClicked) {
        this.doubleClicked = false;
        return;
      }
      const { hiddenRow, $route: route, showCommentModal } = this;
      if (!hiddenRow && route.name === 'schedule-library') {
        showCommentModal();
      }
    }, 300),
    doubleClick() {
      this.doubleClicked = true;
      this.$emit('dblclickHandler');
    },
    update() {
      this.$emit('change', {
        value: this.newValue || '',
      });
      this.toggleEditMode();
    },
    showCommentModal() {
      if (this.item.order) {
        this.showCommentsModalTrigger({
          rowId: this.item.id,
        });
      }
    },
    showTooltip(val) {
      if (this.isCollection && val && val.length > 80) {
        const item = `${val.substr(0, 55)}...`;
        return {
          item,
          showTool: false,
        };
      } else {
        return {
          item: val,
          showTool: true,
        };
      }
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  word-break: break-all;
  width: 100%;
}
::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: var(--v-borderGrey-base) !important;
}
::v-deep .v-text-field--outlined {
  .v-input__control > .v-input__slot {
    min-height: 40px !important;
  }
}
</style>
