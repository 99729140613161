<template>
  <app-dialog
    v-model="collectionDuplicateModal"
    :value.sync="collectionDuplicateModal"
    :disabled="!getDuplicateOptions.access.allowed || noModal"
    width="440"
    content-class="v-dialog__form">
    <template
      #activator="{ on, attrs }">
      <div
        class="px-0"
        v-bind="attrs"
        v-on="on"
        @click="noModalButtonAction">
        <slot
          :btnText="getDuplicateOptions.btnText"
          :disableAction="!getDuplicateOptions.access.allowed"
          :loading="loading"
          name="duplicateButton">
          <p>
            <app-icon-btn
              :icon-height="getDuplicateOptions.iconHeight"
              wrapper-class="px-0"
              :access="getDuplicateOptions.access"
              show-tooltip
              bottom
              :icon-name="getDuplicateOptions.icon"
              icon-color="mainGrey">
              {{ getDuplicateOptions.btnText }}
            </app-icon-btn>
          </p>
        </slot>
      </div>
    </template>
    <div class="dialog-content">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <span>{{ getDuplicateOptions.headerText }}</span>
          </div>
          <v-icon
            color="#fff"
            @click="cancel">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text
          class="items-wrapper">
          <div
            class="items">
            <div
              class="item-wrapper">
              <p
                v-if="isFollowDialog"
                class="text--secondary ma-0 sub-text-info">
                {{ textTopInput }}
              </p>
              <v-autocomplete
                :value="duplicatedItems"
                attach
                :return-object="isFollowDialog"
                :search-input.sync="searchText"
                item-text="name"
                item-value="id"
                :allow-overflow="false"
                class="duplicate-products-autocomplete"
                :multiple="isMultiple"
                :menu-props="{
                  maxHeight: '220px',
                  contentClass:isFollowDialog ? 'multiple-menu' : ''
                }"
                chips
                :disabled="loading"
                :items="collectionsList"
                :placeholder="getDuplicateOptions.placeholder"
                @input="change">
                <template #no-data>
                  <span class="pl-4">
                    No collections available
                  </span>
                </template>
                <template
                  v-if="searchText && isFollowDialog"
                  #append-item>
                  <v-list-item
                    class="pl-5 create-collection"
                    @click="addCollectionToList">
                    Create new "{{ searchText }}" collection
                  </v-list-item>
                </template>
                <template v-slot:item="{ item, attrs }">
                  <v-tooltip
                    bottom
                    :disabled="!item.disabled">
                    <template #activator="{ on: onTooltip }">
                      <v-layout
                        style="padding: 0"
                        wrap
                        v-on="onTooltip">
                        <template v-if="isMultiple">
                          <v-list-item
                            v-if="isMultiple"
                            :class="{'v-list-item--active': attrs.inputValue}"
                            class="custom-list v-list-item--link">
                            <v-list-item-content>
                              <v-checkbox
                                class="center-label"
                                hide-details
                                :disabled="item.disabled"
                                :input-value="attrs.inputValue"
                                :color="savedCollectionIds.includes(item.id)? 'success' : '' "
                                :class="{'unchecked-color': removedCollectionIds.includes(item.id)}"
                                :label="item.name"
                                :ripple="false" />
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="productsForRendering[item.id] && (productsForRendering[item.id].length > 1) && isMultiple"
                              @click.stop="!item.disabled && openCollapse(item.id)">
                              <v-icon
                                class="icon-collapse"
                                color="lightBlack"
                                :class="{'collapse-icon': activeCollapse === item.id}">
                                mdi-chevron-down
                              </v-icon>
                            </v-list-item-action>
                          </v-list-item>
                          <template
                            v-if="(activeCollapse === item.id) && productsForRendering[item.id] && (productsForRendering[item.id].length > 1) && isMultiple">
                            <div
                              style="width: 100%"
                              @click.stop>
                              <v-checkbox
                                v-for="product in productsForRendering[item.id]"
                                :key="product.id"
                                v-model="valueProducts[item.id]"
                                class="sub-checkbox center-label"
                                :class="{'unchecked-color':removedProducts[item.id] && removedProducts[item.id].includes(product.id)}"
                                :color="savedProducts[item.id] && savedProducts[item.id].includes(product.id) ? 'success' : '' "
                                hide-details
                                multiple
                                :label="`${product[productHeaders.MANUFACTURER]}, ${product[productHeaders.MODEL]}`"
                                :value="product.id"
                                :disabled="item.disabled"
                                :ripple="false"
                                @change="changeCheckedProducts($event,item)" />
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-layout>
                    </template>
                    <span>
                      {{ item.tooltipText }}
                    </span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          class="pt-6 d-flex justify-end">
          <template>
            <v-btn
              outlined
              color="lightBlue"
              @click="cancel">
              Cancel
            </v-btn>
            <v-btn
              color="lightBlue"
              :disabled="disabledDoneBtn"
              :loading="loading"
              @click="duplicate">
              {{ textBtnDone }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </div>
  </app-dialog>
</template>
<script>
const BTN_FOLLOW = 'Follow';
const BTN_FOLLOWV2 = 'Done';
const BTN_DUPLICATE = 'Duplicate';
import CollectionProductsApi from '@/services/graphql/collectionProducts';
import { v4 as uuid } from 'uuid';
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import {
  TYPE_DUPLICATE, TYPE_FOLLOW,
} from '@/constants';
import { TYPE_VIEWER } from '@/constants/userPermissions';
import productHeaders from '@/constants/productHeaders';
import CollectionsApi from '@/services/graphql/collections';
import {
  accessHelper,
  isLimitOfProducts,
  setTitleToLimitOfProducts,
} from '@/utils';
import {
  EventBus,
  FOLLOWED_PRODUCT_IN_CURR_COLL,
  DUPLICATED_PRODUCT_IN_CURR_COLL,
} from '@/utils/eventBus';
export default {
  name: 'ProductDuplicateV2',
  components: {
  },
  props: {
    selectedProducts: {
      type: Array,
      default: () => [],
    },
    duplicatedCollectionId: {
      type: String,
      default: null,
    },
    duplicatedProductIds: {
      type: Array,
      default: () => [],
    },
    duplicateType: {
      type: String,
      default: TYPE_FOLLOW,
    },
    userRole: {
      type: String,
      default: TYPE_VIEWER,
    },
    productFromMenu: {
      type: Object,
      default: () => {
      },
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    // When we want duplicate/follow to specific collection without the follow dialog.
    // Need to set 'noModalCollection' - it's the target for dupe/follow actions.
    noModal: {
      type: Boolean,
      default: false,
    },
    noModalCollectionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchText: '',
      collectionDuplicateModal: false,
      selectedCollectionIds: [],
      duplicatedItems: [],
      productsForRendering: {
      },
      valueProducts: {
      },
      activeCollapse: null,
      isFollowedProduct: false,
      nextToken: null,
      followedProductInCollection: {
      },
      TYPE_FOLLOW,
      productHeaders,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('Collections', ['collectionGroupsForFollowProduct', 'collectionsWithPermissions', 'collectionsForDuplicate']),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    ...mapGetters('UserRoles', ['canAddProductToCollection']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('Collections', ['currentCollectionInfo', 'parsedObject', 'productCollectionName']),
    ...mapState(['accessToken', 'activeHeader']),
    ...mapState('Collections', ['currentCollectionInfo', 'parsedObject', 'privateCollectionsListToAutocomplete']),
    ...mapGetters('FeatureFlags', [
      'isToggleToMoveToVerifiedArea',
    ]),
    textTopInput() {
      return 'Select or un-select collections to follow or un-follow products';
    },
    btnUpdateFollow() {
      return BTN_FOLLOWV2;
    },
    textBtnDone() {
      return this.isFollowDialog ? this.btnUpdateFollow : BTN_DUPLICATE;
    },
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    collectionName() {
      const { productCollectionName, productFromMenu, activeHeader } = this;
      const { collectionName = '' } = productFromMenu;
      const { name = '' } = activeHeader || {
      };
      return collectionName || productCollectionName || name;
    },
    libraryId() {
      const { libraryId = '' } = this.$route.params || {
      };
      return libraryId || this.getDefaultLibraryId;
    },
    collectionListIds() {
      return this.collectionsList.map(e => e.id);
    },
    productIdForGetTitle() {
      return this.productFromMenu?.id || this.duplicatedProductIds[0];
    },
    disabledDoneBtn() {
      return this.isFollowDialog ? !Object.keys(this.removedProducts).length && !Object.keys(this.savedProducts).length : !this.duplicatedItems.length;
    },
    removedCollectionIds() {
      return this.currentFollowedCollectionsIds.filter(e => !this.checkedCollectionIds.includes(e) && this.followedProductInCollection[e].length === this.productsForRendering[e].length);
    },
    savedCollectionIds() {
      return this.checkedCollectionIds.filter(e => !this.currentFollowedCollectionsIds.includes(e) || this.followedProductInCollection[e]?.length < this.productsForRendering[e].length);
    },
    removedProducts() {
      if (this.noModal) return this.noModalProduct;

      const { followedProductInCollection, valueProducts } = this;
      const removedProducts = {
      };
      for (const property in followedProductInCollection) {
        const preFollowedProducts = followedProductInCollection[property];
        const currentFollowedProducts = valueProducts[property] || [];
        const filterRemovedProducts = preFollowedProducts.filter(e => !currentFollowedProducts.includes(e));
        if (filterRemovedProducts.length) removedProducts[property] = filterRemovedProducts;
      }
      return removedProducts;
    },
    savedProducts() {
      if (this.noModal) return this.noModalProduct;

      const { followedProductInCollection, valueProducts } = this;
      const newSavedProducts = {
      };
      for (const property in valueProducts) {
        const preFollowedProducts = followedProductInCollection[property];
        const currentFollowedProducts = valueProducts[property] || [];
        const filterNewSavedProducts = currentFollowedProducts.filter(e => !preFollowedProducts?.includes(e));
        if (filterNewSavedProducts.length) newSavedProducts[property] = filterNewSavedProducts;
      }
      return newSavedProducts;
    },
    noModalProduct() {
      const prod = {
      };
      if (this.noModalCollectionId && this.duplicatedProductIds.length) {
        prod[this.noModalCollectionId] = this.duplicatedProductIds[0];
      }
      return prod;
    },
    checkedCollectionIds() {
      if (!Array.isArray(this.duplicatedItems)) {
        return [this.duplicatedItems];
      }
      return this.duplicatedItems.map(e => e.id);
    },
    currentFollowedCollectionsIds() {
      return Object.keys(this.followedProductInCollection);
    },
    isMultipleProducts() {
      return this.duplicatedProductIds.length > 1;
    },
    isLimitedDuplicatedCollection() {
      return this.collectionsList.find(({ id }) => this.duplicatedCollectionId === id)?.disabled;
    },
    duplicatedProductIdsLength() {
      return this.duplicatedProductIds.length;
    },
    collectionsList() {
      return this.getDuplicateOptions?.collectionsList.map(collection => ({
        ...collection,
        ...(isLimitOfProducts({
          ...collection,
          addedProductsQty: this.duplicatedProductIdsLength,
        }) && !this.productsForRendering?.[collection.id] && {
          disabled: true,
          tooltipText: setTitleToLimitOfProducts({
            ...collection,
            addedProductsQty: this.duplicatedProductIdsLength,
          }),
        }),
      }));
    },
    isMultiple() {
      return this.getDuplicateOptions.multiple;
    },
    isFollowDialog() {
      return this.duplicateType === TYPE_FOLLOW;
    },
    textHeaderFollow() {
      return 'Follow or Un-Follow Products';
    },
    getDuplicateOptions() {
      const { textHeaderFollow: headerText } = this;
      if (this.duplicateType === TYPE_DUPLICATE) {
        return {
          action: this.copy,
          btnText: BTN_DUPLICATE,
          placeholder: 'Choose Collection for duplicate product',
          headerText: 'Duplicate Product',
          access: this.canAddProductToCollection(this.userRole),
          icon: 'mdi-content-copy',
          iconHeight: '18px',
          collectionsList: this.collectionsForDuplicate,
          multiple: false,
        };
      }
      return {
        action: this.follow,
        btnText: this.isFollowedProduct ? 'Followed' : BTN_FOLLOW,
        placeholder: 'Choose Collection for follow',
        headerText,
        access: accessHelper(true),
        icon: 'mdi-arrange-send-backward',
        iconHeight: '21px',
        collectionsList: this.currentFollowedCollectionsIds.includes(this.duplicatedCollectionId) ? this.allCollectionList : this.collectionListWithoutCurrent,
        multiple: true,
      };
    },
    allCollectionList() {
      return this.collectionsWithPermissions();
    },
    collectionListWithoutCurrent() {
      return this.collectionsWithPermissions(this.duplicatedCollectionId);
    },
  },
  watch: {
    productIdForGetTitle: {
      handler() {
        this.isFollowDialog && this.getFollowedCollectionForTitle();
      },
      immediate: true,
    },
    collectionDuplicateModal: {
      async handler(val) {
        if (val) {
          try {
            this.loading = true;
            await Promise.all(this.collectionGroupsForFollowProduct.map(async item => (
              await this.getCollectionsList({
                libraryId: this.getDefaultLibraryId,
                collectionGroup: item.group,
                isToAutocomplete: true,
                limit: 99,
                showSpinner: false,
                ...(this.accessToken && {
                  accessToken: this.accessToken,
                }),
              })
            )));
            if (this.isLimitedDuplicatedCollection) {
              this.duplicatedItems = [];
              return;
            }
            this.duplicatedItems = (this.duplicateType === TYPE_DUPLICATE) && !this.isLimitedDuplicatedCollection ? this.duplicatedCollectionId : [];
            this.isFollowDialog && await this.getFollowersCollection();
          } finally {
            this.loading = false;
          }
        } else {
          this.clearData();
        }
      },
    },
  },
  destroyed() {
    this.isFollowedProduct = false;
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      openSnackBar: 'openSnackBar',
      setPrivateCollectionsList: 'Collections/setPrivateCollectionsListToAutocomplete',
      clearSelectedControlProductsIds: 'Collections/clearSelectedControlProductsIds',
      setCurrentCollectionInfo: 'Collections/setCurrentCollectionInfo',
    }),
    ...mapActions({
      manageLastProducts: 'StoredProducts/manageLastProducts',
      getCollectionsList: 'Collections/getCollectionsList',
      handleError: 'handleError',
      parseGetRows: 'Collections/parseGetRows',
    }),
    change(values) {
      try {
        const { checkedCollectionIds } = this;
        const valuesIds = Array.isArray(values) ? values.map(e => e.id) : [values];
        if (valuesIds.length > checkedCollectionIds.length) {
          const newAddedId = valuesIds.find(e => !checkedCollectionIds.includes(e));
          this.$set(this.valueProducts, newAddedId, this.productsForRendering[newAddedId].map(e => e.id));
        } else {
          const removedId = checkedCollectionIds.find(e => !valuesIds.includes(e));
          this.activeCollapse = '';
          this.$set(this.valueProducts, removedId, []);
        }
        this.duplicatedItems = values;
      } catch (e) {
        console.log(e);
      }
    },
    changeCheckedProducts(value, item) {
      const { id } = item;
      if (!this.valueProducts[id].length) this.activeCollapse = '';
      if (this.valueProducts[id].length !== this.productsForRendering[id].length) {
        const index = this.duplicatedItems.findIndex(e => e.id === id);
        if (index >= 0) this.duplicatedItems.splice(index, 1);
      } else {
        this.duplicatedItems.push(item);
      }
    },
    openCollapse(id) {
      this.activeCollapse = this.activeCollapse === id ? null : id;
    },
    async getFollowersCollection() {
      const items = this.selectedProducts.length ? this.selectedProducts : [this.productFromMenu];
      const arrRequest = items.map(e => this.requestFollowedCollections(e));
      await Promise.all(arrRequest);
      //if all multiple products followed in collection, the collection have to checked
      this.currentFollowedCollectionsIds.forEach(e => {
        if (this.followedProductInCollection[e].length === this.productsForRendering[e].length) {
          this.duplicatedItems.push({
            id: e,
          });
        }
      });
      //set checked products
      this.valueProducts = {
        ...this.followedProductInCollection,
      };
    },
    async getFollowedCollectionForTitle(nextToken = null) {
      try {
        this.loading = true;
        const {
          activeWorkspaceId: workspaceId,
          duplicatedCollectionId,
          productIdForGetTitle,
          productFromMenu,
        } = this;
        const { data_key_pk = null, data_key_sk = null } = productFromMenu;
        let collectionId = data_key_pk?.split('#')?.pop() || duplicatedCollectionId;
        let productId = data_key_sk?.split('#')?.pop() || productIdForGetTitle;
        const { data } = await CollectionProductsApi.listCollectionsFollowersForProduct({
          workspaceId,
          collectionId,
          productId,
          limit: 99,
          nextToken,
        });
        const { nextToken: tokenFromRequest } = data.response;
        const collections = data.response?.data;
        if (collections.length) {
          let followed = false;
          if (this.noModal) {
            followed = collections.some((coll) => coll.id === this.noModalCollectionId);
          }
          if (followed) {
            this.isFollowedProduct = true;
            this.$emit('is-followed-product', true);
          } else {
            this.$emit('is-followed-product', false);
            this.isFollowedProduct = false;
          }
        }
        else if (tokenFromRequest) await this.getFollowedCollectionForTitle(tokenFromRequest);
        else {
          this.$emit('is-followed-product', false);
          this.isFollowedProduct = false;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    async requestFollowedCollections(product, nextToken = null) {
      try {
        const { activeWorkspaceId: workspaceId, duplicatedCollectionId } = this;
        const { id, data_key_pk = null, data_key_sk = null } = product;
        const collectionId = data_key_pk?.split('#')?.pop() || duplicatedCollectionId;
        const productId = data_key_sk?.split('#')?.pop() || id;
        const { data } = await CollectionProductsApi.listCollectionsFollowersForProduct({
          workspaceId,
          collectionId,
          productId,
          limit: 99,
          nextToken,
        });
        const { nextToken: tokenFromRequest } = data.response;
        await this.setFollowedCollection(data.response.data, product);
        if (tokenFromRequest) await this.requestFollowedCollections(productId, tokenFromRequest);
      } catch (err) {
        this.handleError(err);
      }
    },
    setFollowedCollection(collections, product) {
      const { MANUFACTURER, MODEL } = productHeaders;
      const filterCollections = collections.filter(e => this.allCollectionList.map(e => e.id).includes(e.id));
      const idsCollectionResponse = filterCollections.map(e => e.id);
      this.collectionsList.forEach(e => {
        if (!idsCollectionResponse.includes(e.id)) {
          this.productsForRendering[e.id] = [...this.productsForRendering[e.id] || [], {
            id: product.id,
            [MANUFACTURER]: product[MANUFACTURER],
            [MODEL]: product[MODEL] || product['Model'] || '-',
          }];
        }
      });
      filterCollections.forEach(e => {
        if (e?.productsIds.some(q => this.followedProductInCollection[e.id]?.includes(q))) return false;
        const arrProducts = [...this.followedProductInCollection[e.id] || [], ...e?.productsIds];
        this.$set(this.followedProductInCollection, e.id, arrProducts);
        const fillProduct = e.productsIds.map(q => ({
          id: q,
          [MANUFACTURER]: product[MANUFACTURER],
          [MODEL]: product[MODEL] || product['Model'] || '-',
        }));
        this.productsForRendering[e.id] = [...this.productsForRendering[e.id] || [], ...fillProduct];
      });
    },
    addCollectionToList() {
      const id = uuid();
      const newItem = {
        name: this.searchText,
        id,
        isNew: true,
      };
      this.setPrivateCollectionsList([...this.privateCollectionsListToAutocomplete, newItem]);
      this.duplicatedItems.push(newItem);
      this.$set(this.valueProducts, id, this.duplicatedProductIds);
    },
    async createNewCollections() {
      const {
        activeWorkspaceId: workspaceId,
        getDefaultLibraryId: libraryId,
        duplicatedItems,
        handleError,
      } = this;
      const newFilteredId = duplicatedItems.filter(e => e.isNew);
      if (!newFilteredId.length) return false;
      const create = this.isToggleToMoveToVerifiedArea ? 'createV3' : 'createV2';
      const newCreatedCollections = newFilteredId.map(async (e) => {
        const { data } = await CollectionsApi[create]({
          name: e.name,
          libraryId,
          workspaceId,
        });
        const { id, name } = data.response;
        const indexFakeId = this.duplicatedItems.findIndex(q => q.id === e.id);
        //overwrite fake id on response back id
        this.duplicatedItems.splice(indexFakeId, 1, {
          id,
          name,
        });
        const productsRestore = this.valueProducts[e.id];
        this.$delete(this.valueProducts, e.id);
        this.$set(this.valueProducts, id, productsRestore);
      });
      try {
        await Promise.all(newCreatedCollections);
      } catch (err) {
        this.loading = false;
        handleError(err);
      }
    },
    async duplicate() {
      this.loading = true;
      try {
        this.isFollowDialog && await this.createNewCollections();
        const {
          duplicatedProductIds,
          duplicatedCollectionId: collectionId,
          libraryId,
          productFromMenu: parsedProduct,
          collectionName,
          isStoreProductPreload,
        } = this;
        await this.getDuplicateOptions.action(collectionId, duplicatedProductIds);
        isStoreProductPreload && this.manageLastProducts({
          parsedProduct, collectionId, libraryId, collectionName,
        });
        this.clearSelectedControlProductsIds();
        return true;

      } catch (err) {
        this.handleError(err);
        return false;

      } finally {
        this.cancel();
        this.loading = false;
      }
    },
    async copy(copyFromCollectionId, copyProductId) {
      let collectionIds = this.noModal ? [this.noModalCollectionId] : this.duplicatedItems;

      for (const product of copyProductId) {
        const vars = {
          collectionIds,
          copyFromCollectionId,
          copyProductId: product,
          workspaceId: this.activeWorkspaceId,
        };
        if (this.noModal) vars.returnCopyToCollectionId = this.noModalCollectionId;
        const { data } = await CollectionProductsApi.copyProduct(vars);

        if (collectionIds.includes(copyFromCollectionId) || collectionIds.includes(this.noModalCollectionId)) {
          this.parseGetRows({
            fields: data.response.fields,
            index: this.currentCollectionInfo.length,
          });
          this.setCurrentCollectionInfo([...this.currentCollectionInfo, this.parsedObject]);
        }
      }
    },
    async follow() {
      await this.followProducts();
      await this.unfollowProducts();
      this.getFollowedCollectionForTitle();
    },
    async followProducts() {
      const {
        savedProducts,
        activeWorkspaceId: workspaceId,
        duplicatedCollectionId: followedCollectionId,
        accessToken,
      } = this;
      if (!Object.keys(savedProducts).length) return false;
      try {
        for (const collectionId in savedProducts) {
          const followedProductIds = savedProducts[collectionId];
          const collectionIds = [collectionId];
          const res = await CollectionProductsApi.followProduct({
            workspaceId,
            collectionIds,
            followedProductIds,
            followedCollectionId,
            ...(accessToken && {
              accessToken,
            }),
          });

          const followedResponse = res.data?.response;
          for (const prod of followedResponse) {
            const colId = prod.PK.split('#')[1];
            EventBus.$emit(FOLLOWED_PRODUCT_IN_CURR_COLL, {
              productId: prod.id,
              collectionId: colId,
            });
          }
        }
        return true;

      } catch (err) {
        this.handleError(err);
        return false;
      }
    },
    async unfollowProducts() {
      const { removedProducts, activeWorkspaceId: workspaceId } = this;
      if (!Object.keys(removedProducts).length) return false;
      try {
        for (const collectionId in removedProducts) {
          const productIds = removedProducts[collectionId];
          await CollectionProductsApi.deleteProduct({
            workspaceId,
            collectionId,
            productIds,
          });
        }
        return true;
      } catch (err) {
        this.handleError(err);
        return false;
      }
    },
    async noModalButtonAction() {
      if (!this.noModal) return;
      if (this.loading || this.showSpinner) return;
      if (!this.noModalCollectionId) return;

      this.spinner(true);
      this.loading = true;
      try {
        if (this.duplicateType === TYPE_FOLLOW) {
          const res = await this.followProducts();
          if (res) this.openSnackBar({
            title: `Product followed successfully`,
          });
          this.getFollowedCollectionForTitle();

        } else if (this.duplicateType === TYPE_DUPLICATE) {
          const res = await this.duplicate();
          if (res) this.openSnackBar({
            title: 'Product duplicated successfully.',
          });
          EventBus.$emit(DUPLICATED_PRODUCT_IN_CURR_COLL);
        }
      } finally {
        this.spinner(false);
        this.loading = false;
      }
    },
    clearData() {
      this.duplicatedItems = [];
      this.selectedCollectionIds = [];
      this.searchText = '';
      this.valueProducts = {
      };
      this.activeCollapse = '';
      this.followedProductInCollection = {
      };
      this.productsForRendering = {
      };
    },
    cancel() {
      this.collectionDuplicateModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-list-item--disabled {
  opacity: 0.5;
  pointer-events: auto;
}
.sub-text-info {
  font-size: 13px;
}
.duplicate-products-autocomplete {
  padding-top: 0 !important;
  ::v-deep.v-select__slot {
    max-height: 85px;
    overflow-y: auto;
    overflow-x: hidden
  }
}
.items-wrapper {
  min-height: 335px;
  max-height: 50vh;
}
.v-card__actions {
  padding: 16px;
}
::v-deep .create-collection.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-info-base) !important;
}
.create-collection {
  cursor: pointer;
  &:before {
    background-color: var(--v-info-base);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
::v-deep .theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}
</style>
