<template>
  <v-switch
    :input-value="hideResolvedComments"
    class="py-0"
    inset
    hide-details
    @change="toggleResolvedComments">
    <template v-slot:label>
      <div v-if="!hideResolvedComments">
        <span class="font-weight-bold">Hide</span> resolved comments
      </div>
      <div v-else>
        Resolved comments are <span class="font-weight-bold">hidden</span>
      </div>
    </template>
  </v-switch>
</template>
<script>
import {
  mapActions,
  mapState,
} from 'vuex';
export default {
  name: 'ToggleResolvedComments',
  computed: {
    ...mapState('Comments', ['hideResolvedComments']),
  },
  methods: {
    ...mapActions({
      toggleResolvedComments: 'Comments/toggleResolvedComments',
    }),
  },
};
</script>
