<template>
  <v-card
    min-width="280"
    width="280">
    <span
      class="back pb-8"
      @click="changeModal">
      <v-icon
        size="20"
        color="rgba(0, 0, 0, 0.45)">
        mdi-chevron-left
      </v-icon>
      Go Back
    </span>
    <div class="checkItems">
      <v-checkbox
        v-for="order in defaultFields"
        :key="order.id"
        v-model="compareColumns"
        :label="order"
        color="#67bed3"
        :value="order"
        hide-details />
    </div>
    <div
      class="sticky-bottom">
      <div
        :style="compareColumns.length ? 'grid-template-columns: 1fr 1fr;' : ''"
        class="action-buttons">
        <v-btn
          color="lightBlue"
          @click="compareColumns = defaultFields">
          Select all
        </v-btn>
        <v-btn
          v-if="compareColumns.length"
          style="border: 2px solid #71D6E0;"
          class="clear"
          color="#fff"
          @click="clearAllColumns()">
          Clear all
        </v-btn>
      </div>
      <v-btn
        v-if="compareColumns.length"
        color="#085C71"
        block
        @click="showCompareColumns">
        Compare
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
import ProjectVersionsApi from '@/services/graphql/projectVersions';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
export default {
  name: 'ChooseColumns',
  props: {
    menu: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      compareColumns: [],
    };
  },
  computed: {
    ...mapGetters({
      currentVersion: 'ProjectVersions/getCurrentVersion',
    }),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('ProjectDetailsTableSchedule', ['defaultFields']),
    ...mapState('ScheduleCompare', [
      'compareVersion',
    ]),
    columns() {
      return this.$store.state.ScheduleCompare.compareColumns;
    },
  },
  watch: {
    async menu() {
      if (this.menu) {
        this.compareColumns = this.columns;
      }
    },
  },
  methods: {
    ...mapMutations('ScheduleCompare', ['setCompareColumns', 'resetState']),
    ...mapMutations(['spinner']),
    ...mapActions('ScheduleCompare', ['getComparingColumns', 'setAllItemsForCompare']),
    changeModal() {
      this.$emit('changeModal', true, 'Compare');
      this.resetState();
    },
    async showCompareColumns() {
      const { compareVersion, currentVersion, compareColumns } = this;
      try {
        this.spinner(true);
        const { data } = await ProjectVersionsApi.compareProjectVersion({
          projectId: this.$route.params.id,
          primaryVersionId: currentVersion === DEFAULT_PROJECT_VERSION ?
            undefined : currentVersion,
          secondaryVersionId: compareVersion === DEFAULT_PROJECT_VERSION ?
            undefined : compareVersion,
          columns: compareColumns,
          workspaceId: this.activeWorkspaceId,
        });
        this.setAllItemsForCompare(data.response);
      } catch (err) {
        console.log(err);
      } finally {
        this.spinner(false);
        this.setCompareColumns(compareColumns);
        this.$emit('closeMenu', false);
      }
    },
    clearAllColumns() {
      this.compareColumns = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.back {
  cursor: pointer;
}
  p {
    margin-bottom: 0;
  }
  ::v-deep.v-card {
    padding: 16px;
  }
.checkItems {
  overflow-y: scroll;
  height: 320px;
  border-bottom: 1px solid #8080804d;
}
.v-input--selection-controls {
  margin-top: 4px!important;
}
.sticky-bottom {
  display: grid;
  gap: 10px;
  .action-buttons {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    ::v-deep.clear .v-btn__content {
      color: var(--v-lightBlack-base) !important;
    }
  }
  ::v-deep.v-btn {
    width: auto!important;
    padding: 0px!important;
    box-shadow: none;
    border-radius: 5px!important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 135.69%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}
</style>
