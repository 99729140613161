var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.header.value === 'order' && 'table-cell-wrapper__order',
    _vm.header.value === 'order' && _vm.getActiveRowIds.includes(_vm.item.id) && 'table-cell-wrapper__order--active',
    ("order-cell--" + _vm.selectedViewCriteria)
  ],on:{"mouseleave":_vm.onRemoveHeader,"mouseover":function($event){return _vm.onSetHeader(_vm.header.value)}}},[(_vm.header.value === 'order')?[(_vm.isResultCompare && _vm.compareData.status)?_c('table-actions-compare-modified',{ref:"testOrderActions",attrs:{"alert-status":_vm.compareData.status}}):_vm._e(),_c('product-cell-order',{attrs:{"order":_vm.item.order},scopedSlots:_vm._u([(_vm.showProjectDetailActions)?{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"brightness-icon",attrs:{"medium":"","color":"mainGrey"},on:{"click":function($event){return _vm.$emit('setMenuItem', {
            item: _vm.item,
            event: $event,
          })}}},[_vm._v(" mdi-dots-vertical ")])]},proxy:true}:null],null,true)}),_c('product-cell-expanded-view',_vm._g({},_vm.$listeners)),(_vm.useScheduleMultipleSelect)?_c('control-selects',{attrs:{"value":_vm.checkIsRowSelected(_vm.item.id)},on:{"change":function (v) { return _vm.onSelectedRowsChange(_vm.item.id , v); }}}):_vm._e()]:_c('product-cell',_vm._g({ref:"tableCell",class:[
      _vm.getActiveRowIds.includes(_vm.item.id) && 'table-container__active-cell',
      _vm.isCellSearchHighlighting({ rowId: _vm.item.id, columnName: _vm.header.value }) && 'v-data-table--schedule__search-highlighting',
      _vm.isSelectedCellSearchHighlighting({ rowId: _vm.item.id, columnName: _vm.header.value }) && 'v-data-table--schedule__search-highlighting__selected',
      _vm.header.value === _vm.productHeaders.TYPE_DESIGNATION && 'type-style',
      _vm.renderedDraggableIndex == _vm.index && !_vm.canBeActiveResized(_vm.index) && 'v-data-table--schedule__dragged-cell',
      ("table-cell--" + _vm.selectedViewCriteria)
    ],style:({ justifyContent: _vm.header.align === 'right' ? 'flex-end' : 'inherit' }),attrs:{"id":((_vm.item.id) + "--" + (_vm.header.value)),"is-expanded-mode":false,"header":_vm.header,"data":_vm.data,"is-opened-in-dialog":false,"allow-show-select-col":"","allow-set-multuple-cells":"","allow-save-on-esc":"","transform-images-to-data":_vm.transformImagesToData,"bordered-cell":_vm.borderedCell,"item":_vm.item,"disabled-edit-mode":_vm.disabledEditMode || _vm.item.isCompared},scopedSlots:_vm._u([{key:"additionalCellActions",fn:function(ref){
    var isPastingCell = ref.isCellForPasting;
    var isBorderedCell = ref.borderedCell;
return [(_vm.isResultCompare && _vm.compareData.rowId === _vm.item.id && _vm.compareData.modified.includes(_vm.header.value))?_c('ProductCellModifiedWarning',{ref:"testActions",attrs:{"row-id":_vm.item.id,"table-id":_vm.tableId}}):_vm._e(),(isBorderedCell && !_vm.isUnsuccessfulPayment)?_c('product-cell-copy',_vm._g({},_vm.$listeners)):_vm._e(),(
          isPastingCell && !_vm.isUnsuccessfulPayment)?_c('product-cell-paste',_vm._g({},_vm.$listeners)):_vm._e()]}}])},_vm.$listeners))],2)}
var staticRenderFns = []

export { render, staticRenderFns }