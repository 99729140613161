<template>
  <v-menu
    bottom
    transition="slide-y-transition"
    nudge-left="20"
    rounded="70"
    offset-y
    min-width="200"
    :close-on-content-click="true">
    <template
      #activator="{ on }">
      <v-icon
        size="18"
        color="mainGrey"
        v-on="on">
        mdi-filter
      </v-icon>
    </template>
    <v-list>
      <v-list-item
        v-for="(tag, index) in scheduleTagsList"
        :key="index"
        :class="{ 'highlighted': activeTags.includes(tag) }"
        @click="updateActiveTags(tag)">
        <v-list-item-title>{{ tag }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-btn
      v-if="activeTags"
      style="border: 2px solid #71D6E0; color: #000!important;"
      class="clear mt-3"
      color="#fff"
      @click="clearFilter">
      Clear filter
    </v-btn>
  </v-menu>
</template>
<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import { RECALCULATE_TAG_TOTAL_COST } from '@/constants/userPermissions';
export default {
  name: 'FilterMenu',
  props: {
    col: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tags: [],
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('ScheduleViews', ['activeTags']),
    ...mapGetters('ScheduleViews', ['selectedView']),
    ...mapGetters('ScheduleRows', ['scheduleTagsList']),
    ...mapState('ScheduleRows', ['viewInfo']),
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setTagTotal: 'ProjectAggregation/setTagTotal',
      setActiveTags: 'ScheduleViews/setActiveTags',
    }),
    ...mapActions({
      setViewsSorting: 'ScheduleViews/setViewsSorting',
      getTotalOfTag: 'ProjectAggregation/getTotalOfTag',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
    }),
    async updateActiveTags(tag) {
      if (this.activeTags.includes(tag)) {
        this.setActiveTags(this.activeTags.filter(t => t != tag));
      } else {
        this.setActiveTags([...this.activeTags, tag]);
      }
      this.totalCostOfTag(tag);
    },
    async totalCostOfTag(tagName) {
      try {
        await this.getTotalOfTag({
          tableId: this.scheduleId,
          tableType: 'schedule',
          tagName,
        });
      } catch (err) {
        console.log(err);
      }
    },
    clearFilter() {
      this.setActiveTags([]);
      this.setTagTotal(null);
      this.removeSubscriptions(RECALCULATE_TAG_TOTAL_COST);
    },
  },
};
</script>
<style lang="scss" scoped>
  .v-icon {
    cursor: pointer;
  }
  .v-menu__content .v-list-item {
    padding: 10px;
    border-radius: 10px;
  }
  .v-menu__content .v-list-item:hover {
    background: #e0e0e0;
    cursor: pointer;
  }
  .content {
    padding: 20px;
    display: grid;
    gap: 20px;
  }
  .tags {
    display: grid;
    gap: 5px;
    height: 115px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.356);
    overflow: auto;
  }
  .buttons {
    display: grid;
    gap: 10px;
    ::v-deep.clear .v-btn__content {
      color: var(--v-lightBlack-base) !important;
    }
  }
  ::v-deep.clear .v-btn__content {
    color: var(--v-lightBlack-base) !important;
  }
  .v-btn {
    padding: 0 5px!important;
    width: 100%!important;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
  .highlighted {
    border-bottom: 5px solid var(--v-lightBlue-base);
  }
</style>
