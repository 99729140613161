<template>
  <div
    v-if="img"
    class="owner-logo-wrapper ml-3">
    <img
      class="test-img"
      :src="img"
      @error="repairLogo">
  </div>
</template>
<script>
import {
  mapActions, mapGetters,
} from 'vuex';
export default {
  name: 'ProjectDetailsLogo',
  props: {
    transformImagesToData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    img: '',
  }),
  computed: {
    ...mapGetters({
      logoUrl: 'ProjectDetailsTableSchedule/getProjectLogoAccordingToWorkspace',
    }),
    ...mapGetters('FeatureFlags', [
      'isVisiblePrintButton',
    ]),
  },
  watch: {
    logoUrl(val) {
      this.toDataUrl(val).then(e => {
        this.img = e;
      });
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['repairLogo']),
    async toDataUrl(url = '') {
      if (!this.isVisiblePrintButton || !url || !this.transformImagesToData) {
        return url;
      } else {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.owner-logo-wrapper {
  img {
    max-width: 135px;
    max-height: 60px;
  }
}
</style>
