<template>
  <div
    :class="[
      header.value === 'order' && 'table-cell-wrapper__order',
      header.value === 'order' && getActiveRowIds.includes(item.id) && 'table-cell-wrapper__order--active',
      `order-cell--${selectedViewCriteria}`
    ]"
    @mouseleave="onRemoveHeader"
    @mouseover="onSetHeader(header.value)">
    <template v-if="header.value === 'order'">
      <table-actions-compare-modified
        v-if="isResultCompare && compareData.status"
        ref="testOrderActions"
        :alert-status="compareData.status" />
      <product-cell-order
        :order="item.order">
        <template
          v-if="showProjectDetailActions"
          #actions>
          <v-icon
            medium
            color="mainGrey"
            class="brightness-icon"
            @click="$emit('setMenuItem', {
              item,
              event: $event,
            })">
            mdi-dots-vertical
          </v-icon>
        </template>
      </product-cell-order>
      <product-cell-expanded-view
        v-on="$listeners" />
      <control-selects
        v-if="useScheduleMultipleSelect"
        :value="checkIsRowSelected(item.id)"
        @change="(v) => onSelectedRowsChange(item.id , v) " />
    </template>
    <product-cell
      v-else
      :id="`${item.id}--${header.value}`"
      ref="tableCell"
      :is-expanded-mode="false"
      :header="header"
      :data="data"
      :is-opened-in-dialog="false"
      allow-show-select-col
      allow-set-multuple-cells
      allow-save-on-esc
      :transform-images-to-data="transformImagesToData"
      :bordered-cell="borderedCell"
      :item="item"
      :disabled-edit-mode="disabledEditMode || item.isCompared"
      :class="[
        getActiveRowIds.includes(item.id) && 'table-container__active-cell',
        isCellSearchHighlighting({ rowId: item.id, columnName: header.value }) && 'v-data-table--schedule__search-highlighting',
        isSelectedCellSearchHighlighting({ rowId: item.id, columnName: header.value }) && 'v-data-table--schedule__search-highlighting__selected',
        header.value === productHeaders.TYPE_DESIGNATION && 'type-style',
        renderedDraggableIndex == index && !canBeActiveResized(index) && 'v-data-table--schedule__dragged-cell',
        `table-cell--${selectedViewCriteria}`
      ]"
      :style="{ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }"
      v-on="$listeners">
      <template
        #additionalCellActions="{ isCellForPasting: isPastingCell, borderedCell: isBorderedCell }">
        <ProductCellModifiedWarning
          v-if="isResultCompare && compareData.rowId === item.id && compareData.modified.includes(header.value)"
          ref="testActions"
          :row-id="item.id"
          :table-id="tableId" />
        <product-cell-copy
          v-if="isBorderedCell && !isUnsuccessfulPayment"
          v-on="$listeners" />
        <product-cell-paste
          v-if="
            isPastingCell && !isUnsuccessfulPayment"
          v-on="$listeners" />
      </template>
    </product-cell>
  </div>
</template>
<script>
import {
  mapGetters, mapState,
  mapMutations,
} from 'vuex';
import activeCellsMixin from '@/mixins/activeCellsMixin';
import productHeaders from '@/constants/productHeaders';
import ProductCellModifiedWarning
  from '@/components/Product/ProductCell/ProductCellModifiedWarning/index.vue';
import ProductCell from '@/components/Product/ProductCell/index.vue';
import ProductCellOrder from '@/components/Product/ProductCell/ProductCellOrder/index.vue';
import ProductCellExpandedView
  from '@/components/Product/ProductCell/ProductCellExpandedView/index.vue';
import TableActionsCompareModified
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableActions/TableActionsCompareModified/index.vue';
import ProductCellCopy from '@/components/Product/ProductCell/ProductCellCopy/index.vue';
import ProductCellPaste from '@/components/Product/ProductCell/ProductCellPaste/index.vue';
import ControlSelects from '@/components/Product/ProductCell/ProductCellControlSelects';
export default {
  name: 'TableCellBlock',
  components: {
    ProductCellPaste,
    ProductCellCopy,
    TableActionsCompareModified,
    ProductCellExpandedView,
    ProductCellOrder,
    ProductCell,
    ProductCellModifiedWarning,
    ControlSelects,
  },
  mixins: [
    activeCellsMixin,
  ],
  props: {
    header: {
      type: Object,
      default: () => {
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    renderedDraggableIndex: {
      type: Number,
      default: null,
    },
    showProjectDetailActions: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    borderedCell: {
      type: Boolean,
      default: false,
    },
    isUnsuccessfulPayment: {
      type: Boolean,
      default: false,
    },
    transformImagesToData: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      productHeaders,
    };
  },
  computed: {
    ...mapGetters({
      isCellSearchHighlighting: 'SearchProjectCells/isCellSearchHighlighting',
      selectedViewCriteria: 'ScheduleViews/selectedViewCriteria',
      isResultCompare: 'ScheduleCompare/isResultCompare',
    }),
    ...mapGetters('FeatureFlags', [ 'useScheduleMultipleSelect']),
    ...mapState('ScheduleRows', ['checkboxSelectedRows']),
    selectedRowsIds: {
      get() {
        return this.checkboxSelectedRows;
      },
      set(val) {
        this.setCheckboxSelectedRows(val);
      },
    },
    compareData() {
      const {
        item = {
        },
      } = this;
      return item.compareData || {
      };
    },
  },
  methods: {
    ...mapMutations('ScheduleRows', [ 'setCheckboxSelectedRows']),
    onSelectedRowsChange(id, value) {
      if (value) {
        this.selectedRowsIds.push(id);
      } else {
        this.selectedRowsIds = this.selectedRowsIds.filter((itemId)=>itemId !== id);
      }
    },
    checkIsRowSelected(id) {
      return this.selectedRowsIds.includes(id);
    },
  },
};
</script>
<style scoped lang="scss">
.table-container {
  .type-style {
    color: var(--v-success-base);
    font-size: 150%;
    font-weight: bold;
  }
}
</style>
