<template>
  <app-action-btn
    :access="canModifyTableRows(role)"
    wrapper-class="mb-0 pointer"
    show-tooltip
    top
    @click="canModifyTableRows(role).allowed && onManageRow({
      tableId: scheduleId,
      tableType,
      rowId,
      projectId: $route.params.id,
      status
    })">
    <template #iconWrapper>
      <v-icon small>
        {{ status === 'hidden' ? 'mdi-eye' : 'mdi-eye-off' }}
      </v-icon>
    </template>
    {{ status === 'hidden' ? 'Unhide' : 'Hide' }}
  </app-action-btn>
</template>
<script>
import {
  mapState, mapActions, mapGetters,
} from 'vuex';
export default {
  name: 'TableControlTypes',
  props: {
    rowId: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    tableType: {
      type: String,
      default: 'schedule',
    },
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('ScheduleRows', ['checkboxSelectedRows']),
    ...mapState('ScheduleRows', ['viewInfo']),
    ...mapState(['role']),
    ...mapGetters('UserRoles', ['canModifyTableRows']),
    ...mapGetters('ScheduleRows', ['isMultipleRowsSelected']),
  },
  methods: {
    ...mapActions('ScheduleRows', ['manageRowVisibility']),
    async onManageRow(rowData) {
      if (this.isMultipleRowsSelected) {
        await Promise.all(this.checkboxSelectedRows.map( async (rowId)=>{
          return await this.manageRowVisibility(
            {
              ...rowData,
              rowId: rowId,
            }
          );
        }));
      } else {
        await this.manageRowVisibility(rowData);
      }
    },
  },
};
</script>
