<template>
  <app-dialog
    v-model="statusDialog"
    :value.sync="statusDialog"
    content-class="v-dialog__form attachments-documents-wrapper"
    width="1450px">
    <v-card>
      <ProjectListDocuments
        ref="document"
        is-restore-mode
        :can-work-with-project-documents="canWorkWithProjectDocuments"
        :active-tab.sync="activeTab"
        :document-type="typeDocument"
        :is-modal-open="statusDialog"
        @closeDialog="closeDialog">
        <template #title>
          Deleted Documents
        </template>
        <template #customActions="{item}">
          <app-action-btn
            show-tooltip
            bottom
            :access="item.canModifyNotOwnProjectDocuments"
            @click="restoreDocument(item)">
            <v-icon
              size="17">
              mdi-backup-restore
            </v-icon>
            Restore
          </app-action-btn>
        </template>
      </ProjectListDocuments>
    </v-card>
  </app-dialog>
</template>
<script>
export default {
  name: 'RestoreTableDocument',
  components: {
    ProjectListDocuments: () => import('@/components/CollectionDetails/ProjectListDocuments'),
  },
  props: {
    typeDocument: {
      type: String,
      default: '',
    },
    canWorkWithProjectDocuments: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data: function () {
    return {
      statusDialog: false,
      activeTab: this.typeDocument,
    };
  },
  methods: {
    restoreDocument(item) {
      if (!item?.canModifyNotOwnProjectDocuments?.allowed) return;
      this.$emit('restoreDeletedDocument', item, this.activeTab);
      this.closeDialog();
    },
    closeDialog() {
      this.statusDialog = false;
    },
    openDialog() {
      this.statusDialog = true;
    },
  },
};
</script>
<style scoped>
</style>
