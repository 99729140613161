<template>
  <div class="d-inline-block">
    <v-tooltip
      :disabled="Boolean(userId)"
      content-class="btn-tooltip"
      bottom>
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on">
          <v-menu
            v-model="menu"
            offset-y
            :disabled="!Boolean(userId)"
            rounded="70"
            content-class="edit-menu"
            :close-on-content-click="false"
            nudge-bottom="2">
            <template #activator="{ on: onMenu }">
              <v-tooltip
                :disabled="showTitle === 'Compare' && showTitle.length < 10"
                top>
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    outlined
                    :disabled="!Boolean(userId)"
                    color="borderGrey"
                    class="select-view-container__edit-button mr-2 customButtonStyle"
                    v-on="{ ...onMenu, ...onTooltip }">
                    <span class="buttonText">
                      <v-icon
                        size="20"
                        color="rgba(0, 0, 0, 0.45)">
                        mdi-compare
                      </v-icon>
                      {{ showTitle }}
                    </span>
                  </v-btn>
                </template>
                <span>{{ showTitle }}</span>
              </v-tooltip>
            </template>
            <ChooseVersions
              v-if="changeMenu"
              @closeMenu="closeMenu"
              @changeModal="changeModal" />
            <ChooseColumns
              v-else
              :menu="menu"
              @closeMenu="closeMenu"
              @changeModal="changeModal" />
          </v-menu>
        </div>
      </template>
      <span>{{ NOT_PERMITTED_ACCESS }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { NOT_PERMITTED_ACCESS } from '@/constants/userPermissions';
import ChooseVersions from './ChooseVersions';
import ChooseColumns from './ChooseColumns';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
export default {
  name: 'ProjectDetailsCompareViews',
  components: {
    ChooseVersions,
    ChooseColumns,
  },
  data() {
    return {
      menu: false,
      changeMenu: true,
      NOT_PERMITTED_ACCESS,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('ScheduleCompare', [
      'compareVersionLength',
      'compareListWithVersionsLength',
    ]),
    ...mapState('ScheduleCompare', [
      'compareVersion',
      'compareColumns',
    ]),
    ...mapState('ProjectVersions', ['listOfVersions']),
    showTitle() {
      let name = 'Compare';
      if (this.compareVersion === DEFAULT_PROJECT_VERSION) {
        return 'Live version';
      } else if (this.compareVersion) {
        this.listOfVersions.filter(element =>
          element.id === this.compareVersion ? name = element.title : '');
        return name;
      } else {
        return name;
      }
    },
  },
  watch: {
    menu() {
      if (!this.compareVersionLength) {
        this.changeMenu = true;
      } else if (this.compareVersionLength && this.compareListWithVersionsLength) {
        this.changeMenu = false;
      } else if (!this.menu && this.compareColumns.length === 0) {
        this.resetState();
        this.changeMenu = true;
      } else if (!this.menu && this.compareVersionLength) {
        this.changeMenu = false;
      }
    },
  },
  methods: {
    ...mapMutations('ScheduleCompare', ['resetState']),
    changeModal(item) {
      this.changeMenu = item;
    },
    closeMenu(modalState) {
      this.menu = modalState;
    },
  },
};
</script>
<style lang="scss" scoped>
  .v-menu__content.edit-menu {
    box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
  }
  .buttonText {
    width: 100px!important;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto', sans-serif;
  }
  .buttons-wrapper .v-btn {
    max-width: 115px;
  }
  .buttons-wrapper .v-icon {
    margin-right: 5px!important;
  }
</style>
