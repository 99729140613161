<template>
  <div
    :class="{'is-edited': editMode}"
    class="wrapper textarea-wrapper d-flex align-center">
    <div
      v-if="!editMode && !isExpandedMode"
      v-safe-html="formatTextareaMessage" />
    <div v-else>
      <v-textarea
        v-model="newValue"
        class="border-outline"
        :rows="isExpandedMode && !shouldExpandHeight ? 1 : 6"
        :placeholder="hint"
        :disabled="!editMode"
        :outlined="isExpandedMode"
        :autofocus="!isExpandedMode"
        hide-details
        :auto-grow="isExpandedMode ? true : false"
        @blur="onFocus" />
    </div>
  </div>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
export default {
  name: 'ProductCellTextareaV2',
  mixins: [EditModeChildComponent],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    shouldExpandHeight: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      text: '',
      newValue: null,
      options: {
        modules: {
          toolbar: [['bold', 'italic', 'underline'], [{
            'list': 'ordered',
          }, {
            'list': 'bullet',
          }]],
        },
        placeholder: '',
        readOnly: true,
        theme: 'snow',
      },
    };
  },
  computed: {
    formatTextareaMessage() {
      const { value } = this;
      const valueForRendering = Array.isArray(value) ? value.join(',') : value;
      return valueForRendering ? valueForRendering.toString().replace(/\r?\n/g, '<br>') : ''; // Textarea can return a number so we will get a bug when call replace
    },
  },
  watch: {
    value: {
      handler(val) {
        this.newValue = Array.isArray(val) ? val.join(', ') : val;
      },
      immediate: true,
    },
  },
  methods: {
    onFocus() {
      this.toggleEditMode();
      this.update();
    },
    update() {
      // Якщо тип хедера від початку array передаємо дані як масив з одним рядком , в іншому випадку просто як стрінг
      if (this.isArray) {
        this.$emit('change', {
          values: [this.newValue],
        });
      } else {
        this.$emit('change', {
          value: this.newValue,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  min-width: 160px !important;
}
::v-deep.textarea-wrapper {
  div:not(.v-input__append-inner) {
    width: 100% !important;
  }
}
::v-deep textarea {
  overflow: auto !important;
}
::v-deep .v-text-field.v-input--is-dirty textarea {
  color: black !important;
}
::v-deep p {
  font-size: unset !important;
  line-height: unset !important;
  margin-bottom: 0;
}
</style>
