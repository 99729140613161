<template>
  <v-menu
    offset-y
    nudge-bottom="10"
    nudge-left="100"
    content-class="edit-menu">
    <template #activator="{ on }">
      <span
        style="width: 16px; height: 16px;">
        <v-icon
          v-show="isShowActionIcon"
          small
          class="pl-2 pointer"
          v-on="on">
          mdi-chevron-down
        </v-icon>
      </span>
    </template>
    <v-card class="nav-card">
      <p
        @click="$emit('deleteRowComment')">
        <img
          src="@/assets/icons/delete-icon.svg">
        Delete
      </p>
      <p
        v-if="isAllowEdit"
        @click="$emit('editRowComment')">
        <img
          style="margin-left: 2px"
          src="@/assets/icons/rename-icon.svg">
        Edit
      </p>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'RowCommentsActions',
  props: {
    comment: {
      type: Object,
      default: () => null,
    },
    hoverRowId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      userId: 'userId',
    }),
    creatorId() {
      const { creator } = this.comment ?? {
      };
      const { id } = creator ?? {
      };
      return id;
    },
    commentId() {
      const { id } = this.comment ?? {
      };
      return id;
    },
    isShowActionIcon() {
      const { creatorId, commentId, hoverRowId, userId } = this;
      if (!creatorId || !hoverRowId, !userId) return false;
      return commentId === hoverRowId && userId === creatorId;
    },
    isAllowEdit() {
      const { commentType: type } = this.comment ?? {
      };
      if (!type) return false;
      return type !== 'attachment';
    },
  },
};
</script>