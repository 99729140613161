<template>
  <app-kebab-actions
    v-bind="$attrs"
    @showMenu="showMenu"
    v-on="$listeners">
    <app-icon-btn
      v-if="isAllowUpdateReferencedProduct"
      icon-name="mdi-update"
      bottom
      :show-tooltip="!isMultipleRowsSelected"
      icon-height="16"
      icon-width="18"
      :access="canWorkWithUpdateReferencedProduct"
      :disabled="isMultipleRowsSelected"
      @click="showUpdateReferencedProduct">
      Update Referenced Product
    </app-icon-btn>
    <app-action-btn
      :show-tooltip="!isMultipleRowsSelected"
      bottom
      icon-height="16"
      icon-width="18"
      alt="product icon grey"
      :icon-src="require('@/assets/icons/product-icon-grey.svg')"
      :access="canSaveAsNewProduct(role)"
      :disabled="isMultipleRowsSelected"
      @click="showSaveAsNewModal">
      Save as new product
    </app-action-btn>
    <CopyRow
      :row-id="rowId" />
    <DeleteRow
      :row-id="rowId" />
    <ControlTypes
      v-if="hasCreatorAccess(role)"
      :row-id="rowId"
      :status="status" />
  </app-kebab-actions>
</template>
<script>
import DeleteRow
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableActions/TableDeleteRow';
import CopyRow
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableActions/TableCopyRow';
import ControlTypes
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableActions/TableControlTypes';
import AppKebabActions from '@/components/App/AppKebabActions';
import CollectionsApi from '@/services/graphql/collections';
import { hasCreatorAccess } from '@/utils';
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import {
  TYPE_SAVE_AS_NEW,
  TYPE_UPDATE_REFERENCED_PRODUCT,
} from '@/constants';
import PRODUCT_MODE from '@/constants/productMode';
export default {
  name: 'TableActions',
  components: {
    CopyRow,
    DeleteRow,
    ControlTypes,
    AppKebabActions,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      productMode: '',
      collectionId: '', // collection id of not original product
    };
  },
  computed: {
    ...mapState(['role', 'roleInResource']),
    ...mapGetters({
      canSaveAsNewProduct: 'UserRoles/canSaveAsNewProduct',
      canUpdateReferencedProduct: 'UserRoles/canUpdateReferencedProduct',
      canModifyProduct: 'UserRoles/canModifyProduct',
      useUpdateReferencedProductWithProjectDataV2: 'FeatureFlags/useUpdateReferencedProductWithProjectDataV2',
      isUpdateReferencedProduct: 'Collections/isUpdateReferencedProduct',
      isMultipleRowsSelected: 'ScheduleRows/isMultipleRowsSelected',
    }),
    followedProductInfo() {
      const { collectionId, referenceId, productMode, masterCollectionId, masterProductId } = this;
      return {
        referencedCollectionId: collectionId,
        referencedProductId: referenceId,
        mode: productMode,
        masterCollectionId,
        masterProductId,
      };
    },
    status() {
      const { status = null } = this.item || {
      };
      return status;
    },
    rowId() {
      const { id } = this.item || {
      };
      if (!id) return '';
      return this.item?.id || '';
    },
    canWorkWithUpdateReferencedProduct() {
      const { canUpdateReferencedProduct, productMode } = this;
      return canUpdateReferencedProduct(productMode);
    },
    referenceKey() {
      const { reference_key: key = null } = this.item || {
      };
      return !key ? null : key;
    },
    referenceId() {
      const { reference_id: referenceId = null } = this.item || {
      };
      return !referenceId ? null : referenceId;
    },
    typeOfReferenceKey() {
      return typeof this.referenceKey;
    },
    isAllowUpdateReferencedProduct() {
      return this.masterCollectionId;
    },
    masterCollectionId() {
      const { referenceKey: key } = this;
      if (!key) return null;
      const { typeOfReferenceKey: type } = this;
      if (type === 'object') {
        return key?.PK?.split('#')?.[1];
      }
      return key.split('PK=COLLECTION#')?.[1]?.split('}')?.[0];
    },
    masterProductId() {
      const { referenceKey: key } = this;
      if (!key) return null;
      const { typeOfReferenceKey: type } = this;
      if (type === 'object') {
        return key?.SK?.split('#')?.[1];
      }
      return key.split('SK=ROW#')?.[1]?.split(',')?.[0];
    },
  },
  watch: {
    item(val) {
      if (this.useUpdateReferencedProductWithProjectDataV2) {
        this.onUpdateItemNewFlow(val);
        return;
      }
      this.onUpdateItem(val);
    },
  },
  methods: {
    ...mapMutations('Collections', ['changeProductModalVariant', 'setProductToUpdate']),
    ...mapMutations(['setRoleInResource']),
    ...mapActions(['getMyRoleInResource']),
    hasCreatorAccess,
    async onUpdateItem(val) {
      if (!val) return;
      const { referenceId: referenceId } = this;
      if (referenceId && this.isAllowUpdateReferencedProduct) {
        const { data } = await CollectionsApi.getProductMode({
          productId: referenceId,
        });
        const { mode = '', PK = '' } = data?.response ?? {
        };
        this.productMode = mode;
        this.collectionId = PK.split('COLLECTION#').join('');
        if (this.productMode === PRODUCT_MODE.FOLLOW || !this.collectionId) return;
        await this.getMyRoleInResource({
          resourceId: this.collectionId,
        });
      }
    },
    async onUpdateItemNewFlow(val) {
      if (!val) return;
      const { referenceId: referenceId } = this;
      const showNewFlow = referenceId && this.isAllowUpdateReferencedProduct;
      if (!showNewFlow) return;
      const { data } = await CollectionsApi.getProductMode({
        productId: referenceId,
      });
      const { mode = '', PK = '' } = data?.response ?? {
      };
      this.productMode = mode;
      this.collectionId = PK.split('COLLECTION#').join('');
      await this.getMyRoleInResource({
        resourceId: this.collectionId,
      });
    },
    async showMenu(val) {
      if (!val) {
        this.$emit('update:item', null);
        if (!this.isUpdateReferencedProduct) {
          this.setRoleInResource(null);
        }
      }
    },
    showSaveAsNewModal() {
      if (!this.canSaveAsNewProduct(this.role).allowed) {
        return;
      }
      const product = {
        ...this.item,
        projectId: this.$route.params?.id,
      };
      const statusDialogProduct = true;
      this.changeProductModalVariant(TYPE_SAVE_AS_NEW);
      this.$emit('openProductDialog', {
        product, statusDialogProduct,
      });
    },
    showUpdateReferencedProduct() {
      if (!this.canWorkWithUpdateReferencedProduct.allowed) {
        return;
      }
      const product = {
        ...this.item,
        ...this.followedProductInfo,
        masterCollectionId: this.masterCollectionId,
      };
      this.changeProductModalVariant(TYPE_UPDATE_REFERENCED_PRODUCT);
      const statusDialogProduct = true;
      this.$emit('openProductDialog', {
        product, statusDialogProduct,
      });
    },
  },
};
</script>
