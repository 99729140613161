<template>
  <v-checkbox
    :ripple="false"
    :input-value="value"
    class="v-input--checkbox__select-products"
    hide-details
    @change="handleChange" />
</template>
<script>
export default {
  name: 'ProductCellControlSelects',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>
<style scoped lang="scss">
  .v-input--checkbox {
    &__select-products {
      padding: 0;
      margin: 0;
      ::v-deep.v-input__slot {
        display: flex;
        justify-content: center;
        .v-input--selection-controls__input {
          margin-right: 0;
        }
      }
    }
  }
</style>
