<template>
  <div
    v-if="!isArray">
    <slot
      :single="true"
      :header="item" />
  </div>
  <v-row
    v-else
    class="ma-0">
    <v-col
      v-for="el in item"
      :key="el.value"
      sm
      cols="12"
      class="pa-0">
      <slot
        :single="false"
        :header="el" />
    </v-col>
    <v-col
      v-if="renderAdditionalCell"
      cols="1" />
  </v-row>
</template>
<script>
export default {
  name: 'WrapperFormRow',
  props: {
    item: {
      type: [Object, Array],
      required: true,
    },
    renderAdditionalCell: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
