<template>
  <div
    class="dropdown-wrapper"
    style="width: 100%">
    <v-combobox
      ref="menu"
      :value="select"
      class="custom-combobox border-outline"
      :class="{'disabled-input':!editMode,'hide-border': !isExpandedMode}"
      :items="items"
      item-text="name"
      :search-input.sync="textSearch"
      :menu-props="{ closeOnContentClick:false,
                     maxWidth: isExpandedMode ? 'inherit' : '250px',
      }"
      return-object
      hide-selected
      :multiple="multipleSelects"
      dense
      :hide-no-data="isHideNoDataText"
      flat
      deletable-chips
      :outlined="isExpandedMode"
      :disabled="readMode"
      append-icon=""
      hide-details
      allow-overflow
      chips
      @change="save">
      <template v-slot:no-data>
        <v-list-item
          v-if="tradeShowInMobile"
          :ripple="false">
          Click
          <v-btn
            color="lightBlue"
            class="custom-combobox__here-btn"
            @click="addNewItemByClickingHereBtn">
            HERE
          </v-btn>
          to add item
        </v-list-item>
        <v-list-item
          v-else
          :ripple="false">
          <v-list-item-content>
            <v-list-item-title>
              Press
              <v-chip
                x-small
                color="mainTurquoise"
                label>
                enter
              </v-chip>
              to add item
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="{ item,attrs }">
        <v-chip
          v-bind="attrs"
          :ripple="false"
          :outlined="item.outlined"
          :color="item.backgroundColor"
          :text-color="item.textColor"
          class="ma-1 pointer">
          <div>
            {{ item.name }}
          </div>
        </v-chip>
      </template>
      <template #selection="{parent,item,attrs,selected}">
        <v-chip
          v-bind="attrs"
          :ripple="false"
          :outlined="item.outlined"
          :color="item.backgroundColor"
          close-icon="mdi-close"
          :text-color="item.textColor"
          :input-value="selected"
          class="ma-1 selection-chip"
          :close="!disabledEditMode"
          @click:close=" multipleSelects ? parent.selectItem(item) : parent.deleteCurrentItem()">
          <div
            class="chip-text"
            :style="{ ...header.columnSize && header.columnSize.size && { 'max-width': getColumnSize }}">
            {{ item.name }}
          </div>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
import {
  customColor, getNextFixedColor,
} from '@/utils/dropdownColors';
import {
  mapMutations, mapGetters, mapState,
} from 'vuex';
import ScheduleCells from '@/services/graphql/scheduleCells';
import CollectionProductsApi from '@/services/graphql/collectionProducts';
import {
  cloneDeep, debounce, isEmpty,
} from 'lodash';
import { waitTimeout } from '@/utils';
const DEBOUNCE_TIME = 0;
export default {
  name: 'ProductCellDropdownV2',
  mixins: [EditModeChildComponent],
  props: {
    collectionId: {
      type: String,
      default: '',
    },
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Object,
      default: null,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    isOverlayed: {
      type: Boolean,
      default: false,
    },
    mappedDropdownsOfView: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      select: null,
      items: [],
      newValue: [],
      textSearch: '',
    };
  },
  computed: {
    ...mapState({
      scheduleId: state => state.ProjectDetailsTableSchedule.scheduleId,
      activeHeader: state => state.activeHeader,
      scrollValueInProductDialog: state => state.scrollValueInProductDialog,
    }),
    ...mapGetters({
      dropdownsProjectAsObj: 'ProjectDetailsTableSchedule/dropdownsProjectAsObj',
      dropdownsCollectionAsObj: 'Collections/dropdownsCollectionAsObj',
      updatedProduct: 'Collections/updatedProduct',
      multiSelectCustomValuesQuickAdd: 'FeatureFlags/multiSelectCustomValuesQuickAdd',
      mobileViewOptimizationsForTradeShow: 'FeatureFlags/mobileViewOptimizationsForTradeShow',
    }),
    tradeShowInMobile() {
      const { mobileViewOptimizationsForTradeShow: flag } = this;
      return flag && this.$vuetify.breakpoint.smAndDown;
    },
    isHideNoDataText() {
      return Boolean(!this.textSearch?.length);
    },
    getColumnSize() {
      const size = this.header?.columnSize?.size || 180;
      return !this.isOverlayed ? `${size - 70}px` : '';
    },
    selectNames() {
      const { select } = this;
      return Array.isArray(select) ? select.map(e => e?.name) || [] : select?.name || select || '';
    },
    multipleSelects() {
      return this.getCurrentDropdown?.multipleSelect || false;
    },
    dropdowns() {
      const { mappedDropdownsOfView: dropdowns } = this;
      if (dropdowns && Object.keys(dropdowns).length) return dropdowns;
      if (this.isCollectionLibrary || this.isCollections) {
        return this.dropdownsCollectionAsObj;
      }
      if (isEmpty(this.dropdownsProjectAsObj)) return this.dropdownsCollectionAsObj;
      return this.dropdownsProjectAsObj;
    },
    isCollectionLibrary() {
      return ['collection-library', 'community-collection', 'shared-collection'].includes(this.$route.name);
    },
    isCollections() {
      return ['collections', 'community-collections', 'collection-product-link', 'community-collection-product-link', 'shared-collection-product-link'].includes(this.$route.name);
    },
    isCollectionEdit() {
      return (this.isCollectionLibrary || this.isCollections) && this.updatedProduct;
    },
    getCurrentDropdown() {
      if (!this.dropdowns) {
        return {
        };
      }
      return this.dropdowns[this.col] ?? {
      };
    },
    dropdownColors() {
      const { values = [] } = this.getCurrentDropdown ?? {
      };
      if (!values) return [];
      return values.map((d, idx) => ({
        name: d,
        ...getNextFixedColor(idx),
      }));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        const { multipleSelects } = this;
        const defaultValue = multipleSelects ? [] : '';
        const checkedValue = val ?? defaultValue;
        const _val = multipleSelects ? cloneDeep(checkedValue) : checkedValue;
        this.select = !_val ? null : this.mappingItemsColor(_val);
      },
    },
    scrollValueInProductDialog(val) {
      if (!val) return;
      this.$refs.menu.isMenuActive = false;
    },
    editMode(val) {
      this.actionsChangeMenu(val);
    },
  },
  async mounted() {
    await waitTimeout(0);
    this.$watch(
      () => {
        return Boolean(this.$refs.menu?.isMenuActive);
      },
      (val) => {
        this.setScrollValueInProductDialog(false);
        if (!val) {
          this.setEditMode(false);
        }
      }
    );
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setScrollValueInProductDialog: 'setScrollValueInProductDialog',
      setNotSavedDataFlag: 'setNotSavedDataFlag',
    }),
    mappingItemsColor(val) {
      const isArray = Array.isArray(val);
      const currentValue = isArray ? val : [val];
      const arrResults = currentValue.map((el) => {
        const isFixedColor = this.dropdownColors.find(d => d.name === el);
        return {
          ...(isFixedColor ? isFixedColor : {
            name: el,
            ...customColor,
          }),
        };
      });
      return isArray ? arrResults : arrResults[0];
    },
    async update() {
      const { multipleSelects, selectNames } = this;
      let data = {
      };
      multipleSelects ? data = {
        values: selectNames,
      } : data = {
        value: selectNames,
      };
      this.$emit('change', {
        ...data,
      });
    },
    async actionsChangeMenu(val) {
      if (val) await this.getDropdownValues();
      await waitTimeout(0);
      if (!this.$refs.menu) await waitTimeout(500);
      this.$refs.menu.isMenuActive = val;
      this.$refs.menu.isFocused = val;
    },
    async getDropdownValues() {
      try {
        const { collectionId } = this;
        this.spinner(true);
        let query = 'getDropdownValues';
        let variables = {
          col: this.col,
          projectId: this.$route.params.id,
          scheduleId: this.scheduleId,
        };
        if (this.isCollections || this.isCollectionLibrary) {
          query = 'getCollectionDropdownValues';
          variables = {
            colId: this.header.id,
            collectionId,
          };
        }
        const { data } = query === 'getDropdownValues'
          ? await ScheduleCells[query](variables) : await CollectionProductsApi[query](variables);
        this.items = this.mappingItemsColor([...data.response.fixedValues, ...data.response.customValues]);
      } catch (e) {
        console.error(e);
      } finally {
        this.spinner(false);
      }
    },
    addNewItemByClickingHereBtn: debounce(function() {
      const keyboardEvent = new KeyboardEvent('keydown', {
        code: 'Enter',
        key: 'Enter',
        charCode: 13,
        keyCode: 13,
        view: window,
        bubbles: false,
      });
      const { input } = this.$refs?.menu?.$refs;
      if (!input) return;
      input.dispatchEvent(keyboardEvent);
      input.focus();
    }, 10),
    save(val) {
      const { multiSelectCustomValuesQuickAdd: flag, save1, saveV2 } = this;
      flag ? saveV2(val) : save1(val);
    },
    save1: debounce(function(val) {
      this.modifyDropdownSelection(val);
      this.sendData();
    }, DEBOUNCE_TIME),
    saveV2(val) {
      this.modifyDropdownSelection(val);
      this.setNotSavedDataFlag(true);
      this.debouncedSendData();
    },
    modifyDropdownSelection(val) {
      const isArray = Array.isArray(val);
      const arrLength = val?.length ?? 0;
      const lastEl = isArray ? val[arrLength - 1] : {
      };
      if (isArray && arrLength && !lastEl?.name) {
        const filterItems = val.filter(e => e?.name);
        this.select = [...filterItems, this.mappingItemsColor(lastEl)];
      } else if (val?.value) {
        this.select = this.mappingItemsColor(val?.value);
      } else {
        this.select = val;
      }
    },
    debouncedSendData: debounce(function() {
      this.sendData();
      this.setNotSavedDataFlag(false);
    }, DEBOUNCE_TIME),
    sendData() {
      this.update();
      if (!this.multipleSelects) this.setEditMode(false);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.custom-combobox {
  height: 100%;
  &__here-btn {
    height: 28px !important;
    padding: 4px !important;
    margin: 4px !important
  }
}
.v-card {
  .chip-text {
    @include textOverflow($width: null);
    max-width: 700px !important;
  }
}
::v-deep .v-text-field {
  input {
    padding: 0 !important;
  }
  .v-input__control > .v-input__slot:before {
    content: none !important;
  }
}
::v-deep .disabled-input .v-input__control .v-select__slot input {
  display: none !important;
}
.hide-border ::v-deep .v-input__control > .v-input__slot {
  border: 0 !important;
  cursor: pointer !important;
  padding: 0 !important;
}
::v-deep .v-chip {
  height: 23px !important;
  &__close {
    color: #fff !important;
  }
  &__content {
    font-size: 16px !important;
  }
}
</style>
