<template>
  <div
    class="wrapper d-flex align-center"
    :class="getWrapperClass">
    <div
      v-if="!editMode && !isExpandedMode"
      class="link-wrapper">
      <div class="link-wrapper__text">
        {{ Array.isArray(value) ? value.join(', ') : value }}
      </div>
    </div>
    <div
      v-else
      style="width: inherit;height: inherit;">
      <v-textarea
        v-model="newValue"
        class="border-outline"
        hide-details
        style="height: inherit"
        :value="Array.isArray(value) ? value.join(', ') : value"
        :autofocus="!isExpandedMode"
        :auto-grow="isExpandedMode"
        :disabled="!editMode"
        :rows="isExpandedMode ? 1 : 6"
        :placeholder="hint"
        :outlined="isExpandedMode"
        :clearable="!isExpandedMode"
        no-resize
        @blur="update"
        @keydown.enter.prevent />
    </div>
  </div>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
export default {
  name: 'ProductCellStringV2',
  mixins: [EditModeChildComponent],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      newValue: null,
    };
  },
  computed: {
    getWrapperClass() {
      const { editMode, expandedRow } = this;
      if (editMode && !expandedRow) {
        return 'wrapper__wide';
      }
      return 'wrapper__initial';
    },
  },
  watch: {
    value: {
      handler(val) {
        this.newValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    update() {
      if (this.isArray) {
        if (this.newValue) {
          this.$emit('change', {
            values: this.newValue || [],
          });
        } else {
          this.$emit('change', {
            values: this.newValue,
          });
        }
      } else {
        if (this.newValue) {
          this.$emit('change', {
            value: this.newValue || [],
          });
        } else {
          this.$emit('change', {
            value: this.newValue,
          });
        }
      }
      this.toggleEditMode();
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  width: 100%;
  ::v-deep.v-text-field {
    height: 100%;
    .v-input__control {
      height: 100%;
    }
  }
}
.wrapper__initial {
  height: initial;
}
.wrapper__wide {
  height: 200px;
  min-width: 250px;
}
::v-deep .v-text-field.v-textarea .v-text-field__slot textarea {
  overflow: auto !important;
}
</style>
