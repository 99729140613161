<template>
  <div
    class="d-flex align-center app-header-content"
    :style="styleBinding"
    style="overflow: hidden;">
    <ColoredIcon
      v-if="showHeaderElements || shareLink"
      :share-link="shareLink" />
    <p
      v-if="showHeaderElements || shareLink"
      style="max-width: 100%"
      class="modal-title mb-0 align-center overflow-dots">
      {{ getDetailProjectTitle }}
    </p>
    <template
      v-if="showSettingsIcon && showHeaderElements && needShowControls">
      <app-icon-btn
        class="d-flex align-self-center"
        wrapper-class="d-flex"
        btn-class="ml-6 inverted-icon pointer setting-project-icon"
        :icon-name="require('@/assets/icons/settings-icon.svg')"
        :is-icon="false"
        bottom
        show-tooltip
        icon-height="20px"
        icon-width="20px"
        :disabled="!Boolean(userId)"
        @click="userId ? showProjectInfoModalAction({id: detailProjectData.id, role }) : null" />
    </template>
    <template
      v-if="showHeaderElements && needShowControls">
      <app-icon-btn
        class="d-flex align-self-center"
        wrapper-class="d-flex align-self-center"
        btn-class="ml-6 inverted-icon pointer"
        :is-icon="false"
        :icon-name="require('@/assets/icons/share-action-icon.svg')"
        bottom
        icon-height="18px"
        icon-width="17px"
        show-tooltip
        @click="onShareModalAction" />
    </template>
  </div>
</template>
<script>
import {
  mapMutations, mapState, mapGetters,
} from 'vuex';
export default {
  name: 'ProjectDetailsTitle',
  components: {
    ColoredIcon: () => import('@/components/ProjectDetails/ProjectDetailsSetColoredIcon'),
  },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
    detailProjectData: {
      type: Object,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
    needShowControls: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['role']),
    ...mapGetters('ProjectDetails', ['getDetailProjectTitle']),
    shareLink() {
      return ['project-library'].includes(this.$route.name);
    },
    styleBinding() {
      return !this.shareLink ? 'margin-left: auto' : 'width: 100%; justify-content: center;';
    },
    showHeaderElements() {
      return ['schedule-library'].includes(this.$route.name) && this.detailProjectData !== null && this.userInfo;
    },
    showSettingsIcon() {
      return this.detailProjectData?.status === 'active';
    },
  },
  methods: {
    ...mapMutations(['showProjectInfoModalAction', 'showShareModalAction']),
    onShareModalAction() {
      this.showShareModalAction();
    },
  },
};
</script>
