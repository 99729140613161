<template>
  <AppListingBlockElement
    :is-shown="isShown"
    :data="dataToRendering"
    :data-listing-to-show="dataListingToShow"
    @openUpdateWindow="showCommentsModalTrigger({ rowId: $event.id })">
    <template #badge="{ id }">
      <span
        v-if="getCommentsUnread(id)"
        class="unread-comments-badge">
        {{ getCommentsUnread(id) }}
      </span>
    </template>
  </AppListingBlockElement>
</template>
<script>
import {
  mapGetters, mapActions,
} from 'vuex';
export default {
  name: 'ProjectDetailsGalleryBlock',
  components: {
    AppListingBlockElement: () => import('@/components/App/AppListingElements/AppListingBlockElement'),
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataListingToShow: [
        'Type / Designation',
        'Description',
        'Manufacturer',
        'Model (Manufacturer Series Name)',
        'Location',
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCommentsUnread: 'Comments/getCommentsUnread',
      isCellSearchHighlighting: 'SearchProjectCells/isCellSearchHighlighting',
      isSelectedCellSearchHighlighting: 'SearchProjectCells/isSelectedCellSearchHighlighting',
    }),
    dataToRendering() {
      const { data, isSelectedCellSearchHighlighting, isCellSearchHighlighting } = this;
      const mappingArr = data.map(item => ({
        ...item,
        isSelectedCellSearchHighlighting: isSelectedCellSearchHighlighting({
          rowId: item.id,
        }),
        isCellSearchHighlighting: isCellSearchHighlighting({
          rowId: item.id,
        }),
      }));
      return mappingArr;
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['showCommentsModalTrigger']),
  },
};
</script>
<style scoped lang="scss">
  .unread-comments-badge {
    background: linear-gradient(270deg, #D41D6A -4.89%, #E8924C 116.61%);
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    height: 20px;
    line-height: 1;
    min-width: 20px;
    padding: 4px 7px;
    position: absolute;
    text-indent: 0;
    z-index: 5;
    top: -5px;
    right: -5px;
  }
</style>
