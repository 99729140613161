<template>
  <div>
    <v-tooltip
      content-class="btn-tooltip"
      :disabled="Boolean(userId)"
      bottom>
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on">
          <v-autocomplete
            v-model="currentVersion"
            :menu-props="{ closeOnContentClick: true, contentClass: 'c-menu' }"
            :disabled="!Boolean(userId)"
            autocomplete="off"
            attach="#select-version-container"
            :items="versions"
            item-text="title"
            item-value="id"
            placeholder="Select the version"
            class="mr-2 v-autocomplete__views v-autocomplete__version"
            solo
            flat
            hide-details
            hide-no-data
            content-class="select-view-container__content"
            style="max-width: 136px; width: auto;"
            :style="!userId ? 'opacity: 0.5' : ''"
            @click="getListProjectVersions({projectId})">
            <template #item="data">
              <v-list-item
                :class="{'v-list-item--active': data.item.id === currentVersion}"
                @click="selectVersionAction(data.item.id)">
                <v-list-item-content>
                  <v-list-item-title>
                    <div>
                      <img
                        v-if="data.item.status === ARCHIVED"
                        class="mr-1"
                        width="12"
                        height="12"
                        src="@/assets/icons/archive-icon.svg">
                      {{ data.item.title }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
      </template>
      <span>{{ NOT_PERMITTED }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import { NOT_PERMITTED } from '@/constants/userPermissions';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
import { ARCHIVED } from '@/constants';
export default {
  name: 'ProjectDetailsVersions',
  data: () => ({
    NOT_PERMITTED,
    ARCHIVED,
  }),
  computed: {
    ...mapGetters({
      userId: 'userId',
      getCurrentVersion: 'ProjectVersions/getCurrentVersion',
    }),
    ...mapState('ProjectVersions', ['listOfVersions']),
    projectId() {
      return this.$route.params.id;
    },
    versions() {
      return [{
        title: 'Live version',
        id: DEFAULT_PROJECT_VERSION,
      }, ...this.sortByDate(this.listOfVersions)];
    },
    currentVersion: {
      get() {
        return this.getCurrentVersion;
      },
      set(val) {
        this.setCurrentVersion(val);
      },
    },
  },
  methods: {
    ...mapActions({
      getListProjectVersions: 'ProjectVersions/getListProjectVersions',
    }),
    ...mapMutations({
      setCurrentVersion: 'ProjectVersions/setCurrentVersion',
    }),
    ...mapMutations('ScheduleCompare', ['resetState']),
    ...mapMutations('ScheduleRows', ['setViewInfo']),
    ...mapMutations('Comments', ['clearSlants']),
    ...mapMutations('SearchProjectCells', ['clearSearchString']),
    resetSlantsRows() {
      this.setViewInfo([]);
      this.clearSlants();
    },
    selectVersionAction(id) {
      if (this.currentVersion === id) {
        return;
      }
      this.clearSearchString(),
      this.resetState();
      this.currentVersion = id;
      const version = id === DEFAULT_PROJECT_VERSION ? null : id;
      const versionForQuery = id === DEFAULT_PROJECT_VERSION ? undefined : id;
      const { query } = this.$route;
      this.$router.replace({
        query: {
          ...query,
          version: versionForQuery,
        },
      });
      this.resetSlantsRows();
      this.$emit('updateVersionRow', {
        id: version,
      });
    },
    sortByDate(array) {
      // Important to use here slice().
      // We're getting the warning (You may have an infinite update loop in a component render function.)
      // because we're changing the value of array within sortBy.
      // This data change will cause the render function to run again.
      return [...array].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.v-menu__content {
  max-width: 100%;
}
.sub-head {
  font-size: 12px;
}
</style>
