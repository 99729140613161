<template>
  <div class="d-inline-block">
    <v-menu
      v-model="isOpenedMenu"
      :disabled="!canModifyIssuance(role).allowed"
      offset-y
      offset-overflow
      content-class="edit-menu"
      :close-on-content-click="false"
      nudge-bottom="2">
      <template #activator="{ on: onMenu }">
        <app-btn
          bottom
          btn-class="select-view-container__edit-button mr-2 customButtonStyle"
          outlined
          color="darkGrey"
          data-test="create_issuance_button"
          :access="canModifyIssuance(role)"
          :disabled="!canModifyIssuance(role).allowed"
          show-tooltip
          v-on="onMenu">
          <v-icon
            size="20"
            color="rgba(0, 0, 0, 0.45)">
            mdi-history
          </v-icon>
          Create Issuance
        </app-btn>
      </template>
      <v-card
        width="360">
        <div
          v-if="editVersion"
          class="row mx-0 mt-0">
          <v-icon
            title="return"
            dense
            @click="closeEditVersionAction">
            mdi-arrow-left
          </v-icon>
        </div>
        <v-card-text
          class="pa-0">
          <v-text-field
            v-model="version.title"
            single-line
            filled
            dense
            clearable
            counter
            maxlength="50"
            data-test="issuance_capture_version_input"
            autocomplete="off"
            class="mt-5 v-text-field__rounded"
            @focus="onFocus">
            <template #label>
              Capture version
            </template>
            <template #append>
              <v-icon color="success">
                mdi-plus-circle
              </v-icon>
            </template>
          </v-text-field>
          <v-expand-transition>
            <v-text-field
              v-if="isInputCaptureVersionFocused"
              v-model="version.shortName"
              single-line
              filled
              clearable
              dense
              data-test="issuance_short_name_input"
              counter
              maxlength="10"
              autocomplete="off"
              class="mt-6 v-text-field__rounded">
              <template #label>
                Short name
              </template>
            </v-text-field>
          </v-expand-transition>
          <v-expand-transition>
            <app-date-picker
              v-if="isInputCaptureVersionFocused"
              :menu-value="menuDate"
              data-test="issuance_date_input"
              :date-picker-value="version.versionDate"
              @on-date-picker-value-event="setVersionDate"
              @on-cancel="menuDate = false;"
              @on-save="menuDate = false;" />
          </v-expand-transition>
          <v-list
            v-if="versions.length"
            class="nav-card mt-3">
            <v-list-item
              v-for="(item) in versions"
              :key="item.id"
              ripple
              style="position: relative; min-height: 29px"
              :class="{ 'selected': setSelectedClass(item.id)}"
              class="px-3"
              @click="selectVersion(item.id)">
              <v-fade-transition>
                <div
                  style="word-break: break-word; height: auto"
                  class="px-0 list-item align-start">
                  <p
                    style="height: auto"
                    class="pl-0">
                    <img
                      v-if="item.status === ARCHIVED"
                      class="mr-1"
                      width="12"
                      height="12"
                      src="@/assets/icons/archive-icon.svg">
                    {{ item.title }}
                  </p>
                  <div
                    v-if="item.shortName"
                    class="pl-0">
                    {{ item.shortName }}
                    {{ item.versionDate }}
                  </div>
                </div>
              </v-fade-transition>
              <ActionMenu
                :status="item.status"
                @click="actionClick(item.id)"
                @manageArchive="manageArchiving"
                @delete="deleteVersion"
                @edit-version="editVersionAction({title: item.title, shortName: item.shortName, versionDate: item.versionDate})" />
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions
          class="sticky-bottom pt-4">
          <template v-if="!version.title && selectedVersion">
            <v-btn
              v-if="currentVersion !== selectedVersion"
              color="lightBlue"
              @click="selectVersionAction">
              Select version
            </v-btn>
          </template>
          <v-fade-transition mode="out-in">
            <v-btn
              v-if="version.title && !editVersion"
              color="lightBlue"
              @click="createProjectVersion">
              Create version
            </v-btn>
          </v-fade-transition>
          <v-fade-transition mode="out-in">
            <v-btn
              v-if="editVersion"
              color="lightBlue"
              :disabled="!isEditedVersion"
              @click="editVersionRequest">
              Edit
            </v-btn>
          </v-fade-transition>
          <v-fade-transition>
            <v-btn
              v-if="editVersion"
              color="lightBlue"
              outlined
              @click="closeEditVersionAction">
              Cancel
            </v-btn>
          </v-fade-transition>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import ActionMenu from './ActionMenu';
import AppDatePicker from '@/components/App/AppDatePicker';
import ProjectVersionsApi from '@/services/graphql/projectVersions';
import {
  ARCHIVED, ACTIVE,
} from '@/constants';
export default {
  name: 'ProjectDetailsCreateIssuance',
  components: {
    ActionMenu, AppDatePicker,
  },
  data() {
    return {
      selectedVersion: null,
      actionedVersionId: null,
      menuDate: false,
      editVersion: false,
      isInputCaptureVersionFocused: false,
      isEditedVersion: false,
      version: {
        title: null,
        shortName: null,
        versionDate: null,
      },
      clonedVersion: {
        title: null,
        shortName: null,
        versionDate: null,
      },
      formatMask: '####-##-##',
      ARCHIVED,
    };
  },
  computed: {
    ...mapState({
      role: state => state.role,
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
      listOfVersions: state => state.ProjectVersions.listOfVersions,
      issuanceMenu: state => state.ProjectVersions.issuanceMenu,
    }),
    ...mapGetters({
      getCurrentVersion: 'ProjectVersions/getCurrentVersion',
      canModifyIssuance: 'UserRoles/canModifyIssuance',
    }),
    isOpenedMenu: {
      get() {
        return this.issuanceMenu;
      },
      set(val) {
        return this.setIssuanceMenu(val);
      },
    },
    versions: {
      get() {
        return this.listOfVersions;
      },
      set(val) {
        this.setListOfVersions(val);
      },
    },
    currentVersion: {
      get() {
        return this.getCurrentVersion;
      },
      set(val) {
        this.setCurrentVersion(val);
      },
    },
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    async menu(val) {
      if (val) {
        const { id: projectId } = this;
        this.spinner(true);
        await this.getListProjectVersions({
          projectId,
        });
        this.spinner(false);
      } else {
        this.version.shortName = null;
        this.version.title = null;
        this.selectedVersion = null;
        this.version.versionDate = '';
        this.isInputCaptureVersionFocused = false;
        this.actionedVersionId = -1;
      }
    },
    version: {
      handler() {
        if (!this.editVersion) {
          return;
        }
        this.isEditedVersion = !this.lodash.isEqual(this.version, this.clonedVersion);
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setListOfVersions: 'ProjectVersions/setListOfVersions',
      setCurrentVersion: 'ProjectVersions/setCurrentVersion',
      setIssuanceMenu: 'ProjectVersions/setIssuanceMenu',
    }),
    ...mapActions({
      handleError: 'handleError',
      getListProjectVersions: 'ProjectVersions/getListProjectVersions',
    }),
    onFocus() {
      this.isInputCaptureVersionFocused = true;
    },
    actionClick(id) {
      if (!this.editVersion) {
        this.actionedVersionId = id;
      }
    },
    closeVersionMenu(isNewVersion = false) {
      this.currentVersion = this.selectedVersion;
      this.$emit('updateVersionRow', {
        id: this.selectedVersion,
        isNewVersion,
      });
      this.isOpenedMenu = false;
    },
    returnToLiveVersion() {
      this.selectVersion(null);
      this.$router.replace({
        query: {
          ...this.$route.query,
          version: undefined,
        },
      });
      this.closeVersionMenu();
    },
    editVersionAction(props) {
      this.editVersion = true;
      this.version = props;
      this.clonedVersion = this.lodash.cloneDeep(this.version);
      this.isInputCaptureVersionFocused = true;
    },
    closeEditVersionAction() {
      this.editVersion = false;
      this.version = {
        title: null,
        shortName: null,
        date: null,
      };
      this.clonedVersion = this.version;
      this.isInputCaptureVersionFocused = false;
    },
    async manageArchiving() {
      this.spinner(true);
      const { status } = this.versions.find(version => version.id === this.actionedVersionId);
      const { id: projectId, activeWorkspaceId: workspaceId, actionedVersionId: versionId } = this;
      const variables = {
        projectId,
        versionId,
        workspaceId,
      };
      const isActiveProject = status === ACTIVE;
      try {
        const { data } = isActiveProject ?
          await ProjectVersionsApi.archiveProjectVersion(variables) :
          await ProjectVersionsApi.unarchiveProjectVersion(variables);
        const { status, id } = data.response;
        if (this.versions.length) {
          this.versions = this.versions.map((item) => ({
            ...item,
            status: item.id === id ? status : item.status,
          }));
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async editVersionRequest() {
      this.spinner(true);
      try {
        const { data } = await ProjectVersionsApi.updateProjectVersion({
          projectId: this.id,
          versionId: this.actionedVersionId,
          workspaceId: this.activeWorkspaceId,
          ...this.version,
        });
        this.selectedVersion = null;
        const { title, shortName, versionDate, id } = data.response;
        if (this.versions.length) {
          this.versions = this.versions.map((item) => ({
            ...item,
            ...item.id === id
              ? {
                title,
                shortName,
                versionDate,
              }
              : {
              },
          }));
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
        this.closeEditVersionAction();
      }
    },
    async deleteVersion() {
      this.spinner(true);
      try {
        const { data } = await ProjectVersionsApi.deleteProjectVersion({
          projectId: this.id,
          versionId: this.actionedVersionId,
          workspaceId: this.activeWorkspaceId,
        });
        this.actionedVersionId = null;
        const { id } = data.response;
        this.versions = this.versions.filter(version => version.id !== id);
        if (this.currentVersion === id) {
          this.returnToLiveVersion();
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    setSelectedClass(id) {
      const { selectedVersion, currentVersion } = this;
      return id === (selectedVersion || currentVersion);
    },
    selectVersion(id) {
      this.selectedVersion = id;
      this.version.title = null;
      this.version.shortName = null;
    },
    selectVersionAction(isNewVersion = false) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          version: this.selectedVersion,
        },
      });
      this.closeVersionMenu(isNewVersion);
    },
    async createProjectVersion() {
      this.spinner(true);
      try {
        const { data } = await ProjectVersionsApi.createProjectVersion({
          projectId: this.id,
          workspaceId: this.activeWorkspaceId,
          ...this.version,
        });
        const { id } = data.response;
        this.selectVersion(id);
        this.selectVersionAction(true);
        this.versions = [
          ...this.versions, data.response,
        ];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    setVersionDate(date) {
      this.version.versionDate = date;
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
::v-deep.v-card {
  padding: 12px;
  input {
    margin-left: 0;
  }
  .v-text-field.edit-input {
    height: 30px;
    .v-input__control {
      padding: 0;
      height: 27px;
      margin-top: 0;
      .v-text-field__slot {
        padding-left: 0;
        height: 27px;
      }
    }
    padding-left: 0;
    padding-top: 0;
    input {
      padding: 0 !important;
    }
  }
  .v-btn {
    buttons-wrapper {
      .v-input__slot {
        border-radius: 50px !important;
      }
    }
    &__content {
      text-transform: none;
    }
  }
}
.buttons-wrapper .v-btn {
  border-radius: 50px;
}
.list-item {
  display: flex;
  flex-direction: column;
}
.v-counter {
  font-size: 10px;
}
::v-deep .v-btn.v-btn--active .v-btn__content {
  color: white !important;
}
</style>

