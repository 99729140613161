var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',_vm._b({scopedSlots:_vm._u([{key:"list",fn:function(ref){
  var filterHeadersByFlexItem = ref.filterHeadersByFlexItem;
  var filterNamesGroupItems = ref.filterNamesGroupItems;
  var isRequiredField = ref.isRequiredField;
  var canBeRendered = ref.canBeRendered;
  var isDialogCollection = ref.isDialogCollection;
  var collectionId = ref.collectionId;
  var renderClass = ref.renderClass;
  var searchMode = ref.searchMode;
  var showPensil = ref.showPensil;
  var view = ref.view;
  var disabledEditMode = ref.disabledEditMode;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{staticClass:"mx-0 my-4 title-of-product"},_vm._l((_vm.item),function(product){return _c('v-col',{key:product.title,staticStyle:{"padding-left":"10px"},attrs:{"md":12 / _vm.productsLength,"cols":"12"}},[_c('h2',{staticClass:"title-of-product__title"},[_vm._v(" "+_vm._s(product.title)+" ")])])}),1):_vm._e(),_vm._l((_vm.mappedFilterHeadersByFlexItem(filterHeadersByFlexItem)),function(headerItem,i){return _c('wrapper-form-row',{key:((headerItem.value) + "__" + i),attrs:{"render-additional-cell":!Array.isArray(headerItem),"item":headerItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var head = ref.header;
  var single = ref.single;
return [(single)?_c('v-row',{staticClass:"ma-0"},_vm._l((_vm.item),function(ref,index){
            if ( ref === void 0 ) ref = {
            };
            var product = ref.product;
            var readOnly = ref.readOnly; if ( readOnly === void 0 ) readOnly = true;
            var shortTitle = ref.shortTitle;
            var loading = ref.loading; if ( loading === void 0 ) loading = false;
            var notAccessible = ref.notAccessible; if ( notAccessible === void 0 ) notAccessible = false;
return _c('v-col',{key:index,staticClass:"pa-0",class:[
              shortTitle === 'Reference' && _vm.$vuetify.breakpoint.smAndDown ? 'bg-f7' : ''
            ],attrs:{"cols":12,"md":12 / _vm.productsLength}},[_c('AppFormRowToProductCell',{attrs:{"can-be-rendered":canBeRendered(head),"is-required-field":isRequiredField(head.id),"single-item":single,"filter-names-group-items":filterNamesGroupItems,"search-mode":searchMode,"header":head,"mapped-dropdowns-of-view":_vm.mappedDropdownsOfView,"product":product,"short-title":shortTitle,"read-only":readOnly,"loading":loading,"not-accessible":notAccessible,"cell-class":renderClass(head),"view":view,"is-dialog-collection":isDialogCollection,"disabled-edit-mode":disabledEditMode,"expanded-row":!showPensil(head.column.type),"collection-id":collectionId},on:{"updateProductField":function($event){return _vm.$emit('updateProductField', $event)}}})],1)}),1):_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":""}},[_c('v-row',{staticClass:"ma-0"},_vm._l((head.columns),function(header,index){return _c('v-col',{key:index,staticClass:"pa-0",class:[
                  head.item.shortTitle === 'Reference' && _vm.$vuetify.breakpoint.smAndDown ? 'bg-f7' : ''
                ],attrs:{"cols":12,"md":12 / _vm.item.length}},[_c('AppFormRowToProductCell',{attrs:{"sm-cols":12,"can-be-rendered":canBeRendered(header),"is-required-field":isRequiredField(header.id),"single-item":single,"filter-names-group-items":filterNamesGroupItems,"search-mode":searchMode,"header":header,"product":head.item.product,"short-title":head.item.shortTitle,"read-only":head.item.readOnly,"cell-class":renderClass(header),"view":view,"loading":head.item.loading,"not-accessible":head.item.notAccessible,"is-dialog-collection":isDialogCollection,"disabled-edit-mode":head.item.readOnly ? true : disabledEditMode,"expanded-row":!showPensil(header.column.type),"collection-id":collectionId},on:{"updateProductField":function($event){return _vm.$emit('updateProductField', $event)}}})],1)}),1)],1),_c('v-col',{attrs:{"cols":"1"}})],1)]}}],null,true)})})]}}])},'BaseLayout',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }