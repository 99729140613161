<template>
  <div
    :key="key"
    class="table-cell-wrapper"
    :class="{
      'inline-field': isExpandedMode,
      'table-cell-wrapper__selected': isSelectedCell && allowShowSelectCol,
      'table-cell-wrapper__selected--one': isOneCellSelected,
      'table-cell-wrapper__attachment': isAttachmentCell,
    }"
    @keydown="editActions"
    @click.exact="onExactClick"
    @click.ctrl.exact="setMultipleCtrlCells"
    @click.shift.exact="setMultipleShiftCells"
    @click.meta.exact="setMultipleCtrlCells"
    @mousedown="onSetKeyboardControllerDisabled"
    @dblclick="doubleClickCell">
    <div
      class="table-cell-wrapper__selected-wrapper"
      :class="{
        'table-cell-wrapper__selected--one-overlay': isSelectedCell && allowShowSelectCol && isAllowedToSelectOne,
        'table-cell-wrapper__selected--edited-cell' : isOverlayedEditCell
      }">
      <slot
        v-if="!hideActions"
        name="additionalCellActions"
        :borderedCell="borderedCell"
        :isCellForPasting="isPasteCell" />
      <div
        v-if="component"
        class="component-wrapper">
        <component
          :is="component"
          :ref="getRefOfCell"
          :collection-id="collectionId"
          :has-access-editor="hasAccessToField"
          :col="columnName"
          :hint="header.column.hint"
          :header="header"
          :is-expanded-mode="isExpandedMode"
          :expanded-row="expandedRow"
          :should-expand-height="shouldExpandHeight"
          :is-array="isHeaderTypeArray"
          :value="value"
          :table-id="scheduleId"
          :edit-mode="editMode"
          :disabled-edit-mode="disabledEditMode"
          :show-pensil="showPensil"
          :item="item"
          :style-filled-img="styleFilledImg"
          :hidden-row="hiddenRow"
          :is-opened-in-dialog="isOpenedInDialog"
          :bordered-cell="borderedCell"
          :view="view"
          :select-items="header.items ? header.items : []"
          :current-row-id="currentRowId"
          :is-overlayed="isOneCellSelected"
          :open-link-in-new-tab="openLinkInNewTab"
          :is-dialog-collection="isDialogCollection"
          :transform-images-to-data="transformImagesToData"
          :mapped-dropdowns-of-view="mappedDropdownsOfView"
          v-on="$listeners"
          @messagesNoHasAccessEditor="messagesNoHasAccessEditor"
          @dblclickHandler="doubleClickCell"
          @toggleEditMode="toggleEditMode"
          @setEditMode="setEditMode"
          @change="updateCell($event, columnName, currentRowId)"
          @saveFileProcess="onUpdateImages" />
        <div
          v-if="isLoading"
          class="loading-overlay">
          <img
            src="@/assets/icons/circle-loader.gif"
            class="loading-overlay-spinner">
        </div>
      </div>
      <div v-else>
        {{ Array.isArray(value) ? value.join(', ') : value }}
      </div>
    </div>
  </div>
</template>
<script>
import ProjectViewsApi from '@/services/graphql/projectViews';
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
import { hasEditorAccess } from '@/utils';
import {
  NOT_PERMITTED_ACCESS, TYPE_EDITOR,
} from '@/constants/userPermissions';
import { LOCKED_FIELD } from '@/constants/notificationMessages';
import { KEYBOARD_KEYS } from '@/constants/keyboardController';
import productCellsTypes from '@/constants/productCellsTypes';
export default {
  name: 'ProductCell',
  props: {
    isDialogCollection: {
      type: Boolean,
      default: true,
    },
    collectionId: {
      type: String,
      default: '',
    },
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    allowShowSelectCol: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
    expandedRow: {
      type: Boolean,
      default: false,
    },
    shouldExpandHeight: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    openLinkInNewTab: {
      type: Boolean,
      default: false,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
    borderedCell: {
      type: Boolean,
      default: false,
    },
    searchMode: {
      type: Boolean,
      default: false,
    },
    allowSetMultupleCells: {
      type: Boolean,
      default: false,
    },
    isOpenedInDialog: {
      type: Boolean,
      default: true,
    },
    allowSaveOnEsc: {
      type: Boolean,
      default: false,
    },
    transformImagesToData: {
      type: Boolean,
      default: false,
    },
    mappedDropdownsOfView: {
      type: Object,
      default: () => {
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: this.expandedRow && !this.disabledEditMode,
      key: 0,
      escapePressed: false,
      defaultFieldsCollection: [],
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', [
      'scheduleId',
    ]),
    ...mapState(['role']),
    ...mapGetters({
      navigationWithKeyboardInSchedule: 'FeatureFlags/navigationWithKeyboardInSchedule',
      mappingControlFields: 'ProjectDetailsTableSchedule/mappingControlFields',
      isReadOnlyCell: 'ProjectDetailsTableSchedule/isReadOnlyCell',
      isReadOnlyModalVariant: 'Collections/isReadOnlyModalVariant',
      isUnsuccessfulPayment: 'UserProfile/isUnsuccessfulPayment',
      userId: 'userId',
      copiedContent: 'ProjectDetailsTableSchedule/copiedContent',
      canModifyProjectDocuments: 'UserRoles/canModifyProjectDocuments',
      isVersionMode: 'ProjectVersions/isVersionMode',
      findSelectedCell: 'ProjectDetailsTableSchedule/isSelectedCell',
      selectedCellsLength: 'ProjectDetailsTableSchedule/selectedCellsLength',
    }),
    isDefaultSaveCell() {
      const { columnType: type } = this;
      return ['ARRAY_STRING', 'MULTILINE', 'URL_MULTILINE', 'DROPDOWN', 'COMBOBOX'].includes(type);
    },
    isOverlayedEditCell() {
      const { editMode, associatedDocumentsType: docs, isOpenedInDialog } = this;
      if (docs || isOpenedInDialog) return false;
      return editMode;
    },
    getRefOfCell() {
      return `cell-${this.scheduleId}`;
    },
    styleFilledImg() {
      return ['Image', 'Line Drawing'].includes(this.columnName) ? 'style1' : 'style2';
    },
    isRoleEditor() {
      return this.role === TYPE_EDITOR;
    },
    hasAccessEditor() {
      if (!this.header.permissions?.length) return true;
      return this.header.permissions.find(e => e?.type === TYPE_EDITOR && this.isRoleEditor)?.edit ?? true;
    },
    hasAccessToField() {
      return !this.hasAccessEditor ? this.hasAccessEditor : !this.noHasAccessEditorToProductReference;
    },
    noHasAccessEditorToProductReference() {
      const fields = this.mappingControlFields(this.defaultFieldsCollection);
      return fields.some(e => e.permissions?.some(e => e?.type === TYPE_EDITOR && this.isRoleEditor && e?.edit === false)) || false;
    },
    hideActions() {
      return this.associatedDocumentsType;
    },
    associatedDocumentsType() {
      return this.columnType === 'ASSOCIATED_DOCUMENTS';
    },
    canModifyProjectDocumentsCell() {
      return this.associatedDocumentsType && this.canModifyProjectDocuments;
    },
    currentRowIndex() {
      const { mappedDataIds, currentRowId } = this;
      return mappedDataIds.findIndex(id => id === currentRowId);
    },
    currentRowId() {
      return this.item?.id;
    },
    mappedDataIds() {
      return this.data.map(item => item.id);
    },
    columnName() {
      return this.header.value;
    },
    columnType() {
      return this.header.column.type;
    },
    isSelectedCell() {
      const { columnName, currentRowId } = this;
      return this.findSelectedCell({
        columnName,
        currentRowId,
      });
    },
    isPasteCell() {
      const { isSelectedCell, columnName, copiedContent } = this;
      if (!isSelectedCell) return false;
      if (!Array.isArray(copiedContent)) return false;
      return copiedContent.some(e => e?.col === columnName);
    },
    allowSpinnerOnUpdate() {
      return this.isAttachmentCell;
    },
    isAttachmentCell() {
      return this.columnType === 'ARRAY_AIRTABLE_IMAGE';
    },
    isOneCellSelected() {
      return !!this.selectedCellsLength && this.borderedCell && this.isAllowedToSelectOne;
    },
    isAllowedToSelectOne() {
      return [
        'DROPDOWN',
        'COMBOBOX',
        'ARRAY_STRING',
        'MULTILINE',
        'SINGLE',
        'FORMULA_STRING',
        'FIELD_PRODUCT_REFERENCE',
        'FIELD_PRODUCT_EQUAL',
        'NUMBER',
        'URL',
        'URL_MULTILINE',
        'ASSOCIATED_DOCUMENTS',
      ].includes(this.columnType);
    },
    showPensil() {
      const {
        isUnsuccessfulPayment,
        hiddenTypes,
        allowedForAppropriateRoles,
        isHeaderTypeFormula,
        isVersionMode,
        isReadOnlyModalVariant,
        $route,
      } = this;
      if (isUnsuccessfulPayment) {
        return false;
      } else if (this.view === 'productCreate' || $route.name == 'collection-library') {
        return !hiddenTypes && !isReadOnlyModalVariant && !isHeaderTypeFormula;
      }
      return !hiddenTypes && allowedForAppropriateRoles && !isHeaderTypeFormula && !isVersionMode;
    },
    isHeaderTypeArray() {
      return this.columnType.includes('ARRAY');
    },
    isHeaderTypeFormula() {
      const { columnType, columnName, isReadOnlyCell, header } = this;
      return columnType.includes('FORMULA') || isReadOnlyCell(columnName) || isReadOnlyCell(header.id);
    },
    isNumberOrCurrencyField() {
      return ['NUMBER', 'CURRENCY'].includes(this.columnType);
    },
    value() {
      if (this.header.value == 'Choose Collection' || this.header.type == 'SELECT') {
        return this.header.selectValue;
      }
      let value = this.item[this.columnName];
      if (this.isAttachmentCell) {
        if (!Array.isArray(value)) {
          value = [];
        }
      }
      return this.formatValue(value);
    },
    hiddenRow() {
      const { item, role } = this;
      return !hasEditorAccess(role) && item.status === 'hidden';
    },
    hiddenTypes() {
      return ['CHECKBOX', 'ORDER_COL', 'ORDER_COL'].includes(this.columnType);
    },
    allowedForAppropriateRoles() {
      return hasEditorAccess(this.role) && !!this.userId;
    },
    component() {
      const { columnType: type } = this;
      return productCellsTypes[type] || null;
    },
  },
  watch: {
    disabledEditMode: {
      handler() {
        this.setInitEditMode();
      },
    },
    expandedRow: {
      handler() {
        this.setInitEditMode();
      },
    },
    editMode(val) {
      this.setEditCellEditMode(val);
    },
    showPensil: {
      handler(val) {
        if (!val && this.expandedRow && !this.searchMode) {
          this.editMode = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.columnType === 'FIELD_PRODUCT_REFERENCE' && this.isRoleEditor) {
      this.getDefaultFieldsProductReference();
    }
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      openSnackBar: 'openSnackBar',
      changeUpdateProductMode: 'Collections/changeUpdateProductMode',
    }),
    ...mapActions({
      handleError: 'handleError',
      setCopiedContent: 'ProjectDetailsTableSchedule/setCopiedContent',
      setUndoActions: 'UndoActions/setUndoActions',
      setSelectedCells: 'ProjectDetailsTableSchedule/setSelectedCells',
      setEditCellEditMode: 'ProjectDetailsTableSchedule/setEditCellEditMode',
      setDisableKeyboardController: 'ProjectDetailsTableSchedule/setDisableKeyboardController',
    }),
    onUpdateImages(event = false) {
      this.$emit('saveFileProcess', event );
    },
    setInitEditMode() {
      this.editMode = this.expandedRow && !this.disabledEditMode;
    },
    async getDefaultFieldsProductReference() {
      try {
        this.spinner(true);
        const { data } = await ProjectViewsApi.getDefaultFieldsMapping({
          viewToSortBy: 'collection',
        });
        this.defaultFieldsCollection = data.response?.fieldsMapping.map(e => e?.collectionFieldName).filter(e => e) || [];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    messagesNoHasAccessEditor() {
      const { noHasAccessEditorToProductReference, hasAccessEditor } = this;
      const title = !hasAccessEditor ? LOCKED_FIELD : '';
      const text = noHasAccessEditorToProductReference && hasAccessEditor ? NOT_PERMITTED_ACCESS : '';
      this.openSnackBar({
        title,
        text,
      });
    },
    doubleClickCell() {
      const { editMode, showPensil, setEditMode, hasAccessToField } = this;
      if (!hasAccessToField) {
        this.messagesNoHasAccessEditor();
        return false;
      }
      if (!editMode && showPensil) {
        setEditMode(true);
      }
    },
    formatValue(value) {
      if (this.isNumberOrCurrencyField) {
        return value ? value.toString() : '0';
      }
      return value === '\b' || value === ' ' ? '' : value;
    },
    editActions(event) {
      const { navigationWithKeyboardInSchedule: flag, allowSaveOnEsc } = this;
      const isEscapeKey = event.key === 'Escape';
      if (isEscapeKey && allowSaveOnEsc && flag) return false;
      else if (isEscapeKey) {
        this.toggleEditMode();
        this.escapePressed = true;
        this.key += 1;
      }
    },
    onSetKeyboardControllerDisabled() {
      this.setDisableKeyboardController(false);
    },
    onExactClick() {
      if (!this.isOpenedInDialog) {
        this.setSelectedCellAction();
        return;
      }
      if (!this.editMode && this.showPensil) {
        if (this.columnType === 'ARRAY_AIRTABLE_IMAGE' && Array.isArray(this.value) && this.value.length > 0) {
          return;
        } else {
          this.setEditMode(true);
        }
      }
    },
    setSelectedCellAction() {
      const {
        currentRowIndex,
        currentRowId: rowId,
        columnName: col,
        allowShowSelectCol,
        value,
      } = this;
      if (!allowShowSelectCol) return;
      const index = currentRowIndex;
      this.setSelectedCells([{
        rowId,
        col,
        index,
        value,
      }]);
      this.setCopiedContent();
    },
    setMultipleCtrlCells() {
      const {
        currentRowIndex,
        currentRowId,
        columnName,
        value,
        allowSetMultupleCells,
      } = this;
      if (!allowSetMultupleCells) return;
      this.$emit('setMultipleCtrlCells', {
        currentRowIndex,
        currentRowId,
        columnName,
        value,
      });
    },
    setMultipleShiftCells() {
      const {
        mappedDataIds,
        columnName,
        allowSetMultupleCells,
      } = this;
      if (!allowSetMultupleCells) return;
      this.setMultipleCtrlCells();
      this.$emit('setMultipleShiftCells', {
        mappedDataIds, columnName,
      });
    },
    async updateCell(ev, col, rowId, isPasteAction = false) {
      const {
        $route: route,
        view,
        allowSpinnerOnUpdate,
        columnName,
      } = this;
      if (this.escapePressed) {
        this.escapePressed = false;
        return;
      }
      this.escapePressed = false;
      if (view === 'productCreate' || route.name == 'collection-library') {
        const mode = this.item?.mode;
        this.$emit('updateProductField', {
          ev, col, rowId, mode, allowSpinnerOnUpdate, colHeader: this.header,
        });
        if (!allowSpinnerOnUpdate) {
          this.$set(this.item, columnName, ev.value ? ev.value : ev.values ? ev.values : null);
        }
      } else {
        this.$emit('updateCell', {
          ev,
          col,
          rowId,
          isPasteAction,
          columnName,
          columnType: this.columnType,
          oldVal: this.value,
        });
      }
    },
    toggleEditMode() {
      if (!this.expandedRow) {
        this.editMode = !this.editMode;
      }
    },
    setEditMode(val) {
      const {
        isVersionMode,
        disabledEditMode,
        isUnsuccessfulPayment,
        canModifyProjectDocumentsCell,
      } = this;
      if (isVersionMode || (disabledEditMode && !canModifyProjectDocumentsCell?.allowed) || isUnsuccessfulPayment) {
        return;
      }
      if (!this.expandedRow) {
        this.editMode = val;
      }
    },
    //NOTE: Method for Table Controller
    onUpdateAndClose(el, key = null) {
      const isTab = key === KEYBOARD_KEYS.TAB;
      const isEsc = key === KEYBOARD_KEYS.ESC;
      const { isDefaultSaveCell } = this;
      const inputCell = el.querySelector('textarea') || el.querySelector('input');
      if (isDefaultSaveCell && !isTab && !isEsc) return false;
      else if (inputCell) inputCell.blur();
    },
  },
};
</script>
<style scoped lang="scss">
td {
  .table-cell-wrapper {
    &__attachment {
      height: inherit;
      .table-cell-wrapper__selected-wrapper {
        height: inherit;
      }
      .component-wrapper {
        height: inherit;
      }
      ::v-deep .attachment-documents-wrapper {
        max-height: 164px;
        height: 94%;
        //overflow: hidden;
        display: flex;
        align-self: center;
      }
    }
  }
}
.table-cell-wrapper {
  padding: 0 16px;
  position: relative;
  min-height: 21px;
  height: 100%;
  display: flex;
  align-items: center;
  &_no-height {
    height: auto;
  }
  &__selected {
    border: 1px solid var(--v-lightBlue-base);
    &--edited-cell {
      z-index: 10 !important;
    }
  }
  &__pasting-cells {
    background: #eef4f5;
  }
  .edit-mode-activator {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: -16px;
    display: none;
  }
  &:hover {
    .edit-mode-activator {
      display: block;
    }
  }
  ::v-deep.action-icons {
    position: absolute;
    top: 0;
    right: 0;
    display: block !important;
    z-index: 1;
    cursor: pointer;
    &__copy-icon {
      right: 24px;
    }
    &__paste-icon {
      right: 48px;
    }
  }
}
.component-wrapper {
  width: 100%;
  ::v-deep.v-input {
    input, textarea {
      font-size: 16px !important;
    }
    input::placeholder, textarea::placeholder {
      font-size: 10px !important;
      opacity: 0.7;
      line-height: 13px;
    }
  }
  ::v-deep.v-chip__content {
    font-size: 16px;
  }

  .loading-overlay {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: var(--v-lightGrey-lighten1);
    opacity: 0.25;
  }
  .loading-overlay-spinner {
    // purposely set larger than box, because the spinner gif has some empty space
    max-height: 130%;
  }
}
</style>
