import { mapMutations } from 'vuex';
import { AUTO_CREATE_VERSION_ON_EXPORT } from '@/constants/exportScheduleItems';
export default {
  methods: {
    ...mapMutations('ProjectVersions', ['setIssuanceMenu']),
    async useExportMessage() {
      const confirmToProceed = await this.$openConfirm({
        text: AUTO_CREATE_VERSION_ON_EXPORT,
        accept: 'Yes',
        cancel: 'No',
      });
      if (confirmToProceed) {
        this.setIssuanceMenu(true);
      }
    },
  },
};
