<template>
  <v-row
    no-gutters
    class="justify-end pointer mt-1 mr-2"
    @click="expandActions">
    <p class="x-small-p mb-0">
      {{ expandedTitle }}
    </p>
    <v-icon small>
      {{ expandedIcon }}
    </v-icon>
  </v-row>
</template>
<script>
export default {
  name: 'AppExpandMobileActions',
  props: {
    showMobileActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    expandedTitle() {
      return this.showMobileActions ? 'Collapse Tools' : 'Expand Tools';
    },
    expandedIcon() {
      return this.showMobileActions ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
  },
  methods: {
    expandActions() {
      this.$emit('update:showMobileActions', !this.showMobileActions);
    },
  },
};
</script>
