<template>
  <!-- Dialog component to display comments -->
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    width="1391">
    <!-- Activator template slot -->
    <template #activator="{ on, attrs: dialogAttrs }">
      <div
        v-bind="{...dialogAttrs, ...$attrs}"
        v-on="on">
        <!-- Badge to show the total unread comments -->
        <v-badge
          :content="totalUnreadCommentsLength"
          class="pointer v-badge__summary-comments"
          color="mainBlue"
          :value="totalUnreadCommentsLength > 0">
          <!-- Button to open the comments -->
          <v-btn
            outlined
            class="select-view-container__edit-button mr-2 customButtonStyle">
            <v-icon
              size="20"
              color="#fff">
              mdi-comment-multiple-outline
            </v-icon>
            Comments
          </v-btn>
        </v-badge>
      </div>
    </template>
    <!-- Expansion panels for comments -->
    <v-expansion-panels
      v-if="dialog"
      v-model="commentsPanel"
      class="main-panel"
      accordion
      data-test="project_comments_window"
      multiple>
      <!-- Header items for comments section -->
      <div class="header-items">
        <div class="d-flex align-center">
          <span class="main-text"><b>General Chat</b></span>
          <ToggleResolvedComments class="mt-0 ml-8" />
        </div>
        <div class="comments">
          <span class="main-text mr-2"><b>Comments</b></span>
          <div
            class="action-buttons">
            <!-- Buttons for comment actions -->
            <template v-if="rowsWithCommentsLength">
              <v-btn
                :disabled="disabledReadAllComments"
                large
                class="ex-and-collapse"
                @click="readAllComments">
                <span>MARK ALL AS READ</span>
              </v-btn>
              <v-btn
                class="ex-and-collapse"
                @click="expandAll">
                <span>Expand all</span>
                <v-icon
                  small>
                  mdi-arrow-expand
                </v-icon>
              </v-btn>
              <v-btn
                class="ex-and-collapse"
                @click="collapseAll">
                <span>Collapse all</span>
                <v-icon
                  small>
                  mdi-arrow-collapse
                </v-icon>
              </v-btn>
            </template>
            <!-- Message when there are no comments -->
            <p
              v-else-if="!isRowsLoading && !isRowsCommentsLoading"
              class="mb-0">
              There are no comments yet.
            </p>
            <!-- Close button for the comments dialog -->
            <v-icon
              color="lightBlack"
              class="ml-2"
              @click="dialog = false">
              mdi-close
            </v-icon>
          </div>
        </div>
      </div>
      <!-- Container for all comments -->
      <div class="all-comments">
        <div
          class="general-comments">
          <div
            class="cards">
            <!-- CommentsCard component for general comments -->
            <CommentsCard
              ref="projectComments"
              :show-comments-modal="generalCommentsModal"
              hide-resolved-toggle
              type="project"
              :mode="null" />
          </div>
        </div>
        <!-- Default comments section -->
        <div class="default-comments">
          <!-- Skeleton loader for row comments -->
          <fragment v-if="isShowRowsCommentsSkeleton">
            <v-skeleton-loader
              v-for="i in 4"
              :key="i"
              class="default-comments__skeleton-expansion-item"
              max-height="116"
              type="image" />
          </fragment>
          <!-- Iterating through row comments -->
          <v-expansion-panel
            v-for="(item) in rowsWithComments"
            :key="item.rowId">
            <!-- Header of the row comment panel -->
            <v-expansion-panel-header class="font-weight-bold">
              {{ item.type === '\b' ? '' : item.type }}
              <p class="pb-5">
                <!-- CommentBadge component to display comment information -->
                <CommentBadge
                  class="ml-1 v-badge__expanded"
                  :item="{
                    commentsAmount: slant(item.rowId).commentsAmount,
                    id: item.rowId,
                  }" />
              </p>
              <v-tooltip top>
                <template #activator="{ on }">
                  <router-link
                    style="max-width: 16px"
                    :to="{ query: {
                      rowId: item.rowId,
                      fromGeneralComments: true,
                    }}"
                    @click.native="goToRowComments(item.rowId)">
                    <v-icon
                      small
                      v-on="on">
                      mdi-link-variant
                    </v-icon>
                  </router-link>
                </template>
                <span>Go to row comments</span>
              </v-tooltip>
            </v-expansion-panel-header>
            <!-- Content of the row comment panel -->
            <v-expansion-panel-content>
              <!-- Comments component to display row comments -->
              <Comments
                :row-id="item.rowId"
                :data="{
                  comments: item.comments,
                  nextToken: item.nextToken
                }"
                :files-src.sync="filesSrc"
                :edit-mode="false"
                @getRowComments="getRowComments(item)" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
    </v-expansion-panels>
  </app-dialog>
</template>
<script>
import { Storage } from 'aws-amplify';
import Comments from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/RowComments';
import CommentsCard
  from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/RowCommentsCard';
import CommentBadge from '@/components/ProjectDetails/ProjectDetailsCommentBadge';
import ToggleResolvedComments from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/ToggleResolvedComments';
import GetCommenCreatorAvatar from '@/mixins/getCommentCreatorAvatar';
import {
  mapActions,
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';
import CommentsApi from '@/services/graphql/comments';
/**
 * Vue component for managing project summary comments.
 *
 * @component ProjectDetailsSummaryComments
 */
export default {
  name: 'ProjectDetailsSummaryComments',
  components: {
    Comments,
    CommentsCard,
    CommentBadge,
    ToggleResolvedComments,
  },
  mixins: [GetCommenCreatorAvatar],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    generalCommentsModal: {
      type: Boolean,
      default: false,
    },
    /**
     * A boolean flag indicating whether rows are currently loading.
     *
     * @type {boolean}
     * @default true
     */
    isRowsLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      commentsPanel: [],
      commentForRows: [],
      filesSrc: {
      },
      /**
       * A boolean flag indicating whether rows comments are currently loading.
       *
       * @type {boolean}
       */
      isRowsCommentsLoading: true,
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState(['showSpinner', 'avatars']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters({
      rowIdsForSlants: 'ProjectDetailsTableSchedule/rowIdsForSlants',
      totalUnreadCommentsLength: 'Comments/totalUnreadCommentsLength',
    }),
    ...mapGetters('Comments', ['slant']),
    rowsWithCommentsLength() {
      const { rowsWithComments: arr = [] } = this;
      return arr.length;
    },
    isShowRowsCommentsSkeleton() {
      const { isRowsLoading, isRowsCommentsLoading, rowsWithCommentsLength: length } = this;
      if (length) return false;
      return isRowsLoading || isRowsCommentsLoading;
    },
    disabledReadAllComments() {
      return !this.totalUnreadCommentsLength;
    },
    dialog: {
      get() {
        return this.generalCommentsModal;
      },
      set(val) {
        this.$emit('update:generalCommentsModal', val);
      },
    },
    rowsWithComments() {
      let sortedRowComments = [];
      if (this.commentForRows.length) {
        this.data
          .forEach(item => (this.commentForRows.find(i => {
            if (i.rowId === item.id && this.slant(item.id)?.commentsAmount) {
              sortedRowComments = [...sortedRowComments, i];
            }
          })));
      }
      return sortedRowComments;
    },
  },
  watch: {
    /**
      * Vue lifecycle method that is called when the `dialog` prop changes.
      *
      * @param {boolean} val - The new value of the `dialog` prop.
      */
    async dialog(val) {
      if (val) {
        if (!this.isRowsLoading) {
          /**
           * Check if rows are not currently loading and initiate the process to retrieve them.
           */
          this.handleGetRowsComments();
        }
        this.$router.replace({
          query: {
            ...this.$route.query,
            generalComments: true,
          },
        }).catch(() => {
        });
        return;
      }
      await this.$nextTick();
      this.$router.replace({
        query: {
          ...this.$route.query,
          generalComments: undefined,
          commentId: undefined,
        },
      }).catch(() => {
      });
      this.commentForRows = [];
      this.commentsPanel = [];
    },
    isRowsLoading: {
      handler(val) {
        if (val || !this.dialog) return;
        this.handleGetRowsComments();
      },
    },
  },
  methods: {
    ...mapActions({
      readAllCommentsSlant: 'Comments/readAllCommentsSlant',
      handleError: 'handleError',
    }),
    ...mapMutations({
      spinner: 'spinner',
    }),
    /**
     * Asynchronously retrieves comments for rows and updates loading state.
     */
    async handleGetRowsComments() {
      /**
       * Set the `isRowsCommentsLoading` flag to `true` to indicate that comments are being loaded.
       * @type {boolean}
       */
      this.isRowsCommentsLoading = true;

      const promiseAllArray = [];

      this.data.map(({ id: rowId, type }) => {
        let promiseInput = this.getRowComments({
          rowId,
          type,
        });
        promiseAllArray.push(promiseInput);
      });

      /**
       * Wait for all promises to resolve concurrently.
       * @type {Promise}
       */
      await Promise.all(promiseAllArray);

      /**
       * Set the `isRowsCommentsLoading` flag back to `false` when comments loading is complete.
       * @type {boolean}
       */
      this.isRowsCommentsLoading = false;
    },
    async readAllComments() {
      if (!this.totalUnreadCommentsLength) return;
      try {
        this.spinner(true);
        await CommentsApi.readAllComments({
          projectId: this.$route.params.id,
          workspaceId: this.activeWorkspaceId,
        });
        await this.readAllCommentsSlant();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    goToRowComments() {
      this.$emit('goToRowComments');
      this.dialog = false;
    },
    async getRowComments({ rowId, nextToken, type, comments }) {
      if (nextToken) {
        this.spinner(true);
      }
      try {
        const response = await CommentsApi.getRowComments({
          projectId: this.$route.params.id,
          rowId,
          tableId: this.scheduleId,
          tableType: 'schedule',
          nextToken,
          scanIndexForward: false,
          versionId: this.$route.query.version,
          workspaceId: this.activeWorkspaceId,
          limit: 1000,
        });
        const { comments: newComments } = response.data.response;
        const sortedComments = this.lodash.orderBy(nextToken
          ? [...comments, ...newComments] : newComments, ['createdDate'], ['asc']);
        sortedComments.forEach(comment => {
          if (comment.thumbnailId && !this.filesSrc.hasOwnProperty(comment.thumbnailId)) {
            this.getThumbnail(comment.thumbnailId);
          }
          this.getAvatarsForComments(comment);
        });
        const updatedRowIndex = this.commentForRows.findIndex(item => item.rowId === rowId);
        if (updatedRowIndex !== -1) {
          this.$set(this.commentForRows, updatedRowIndex, {
            ...this.commentForRows[updatedRowIndex],
            ...response.data.response,
            comments: [...sortedComments],
          });
        } else {
          this.commentForRows = [...this.commentForRows, {
            ...response.data.response,
            rowId,
            type,
            comments: [
              ...sortedComments,
            ],
          }];
        }
      } catch (err) {
        console.log(err);
      } finally {
        if (nextToken) {
          this.spinner(false);
        }
      }
    },
    async getThumbnail(id) {
      try {
        const url = await Storage.get(id, {
          customPrefix: {
            public: '',
          }, level: 'public',
        });
        this.$set(this.filesSrc, id, url);
      } catch (err) {
        console.log('err with set image', err);
      }
    },
    // Create an array the length of our items
    // with all values as true
    expandAll() {
      this.commentsPanel = this.data.map((item, index) => index);
    },
    // Reset the panel
    collapseAll() {
      this.commentsPanel = [];
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.main-panel {
  border-radius: 10px !important;
  background: #EDF0F7 !important;
  display: grid;
  gap: 24px;
  padding: 28px 32px 55px 32px;
  justify-content: normal;
  grid-template-columns: 1fr;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 16px;
  }
  .header-items {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .main-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
    .comments {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .action-buttons {
        display: flex;
        gap: 7px;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        .ex-and-collapse {
          border: 1px solid #B7C2C5;
          border-radius: 5px;
          padding: 0px !important;
          height: 28px !important;
          .v-icon {
            background: #B7C2C5;
            border-radius: 4px;
            padding: 6px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 126.19%;
            color: var(--v-secondary-base);
            padding: 2px 8px;
          }
        }
      }
    }
  }
  @media (max-width: 960px) {
    .all-comments {
      grid-template-columns: 1fr !important;
      .cards, .default-comments {
        height: fit-content !important;
      }
    }
  }
  .all-comments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .cards {
      height: 546px !important;
    }
    .default-comments {
      height: 546px;
      overflow: auto;
      &__skeleton-expansion-item {
        border: 1px solid #fff0;
      }
    }
    .v-card {
      box-shadow: none;
    }
  }
}
.v-expansion-panel-header {
  padding: 24px 48px 24px 24px;
}
.v-expansion-panel {
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0px 0px 24px !important;
    max-height: 310px;
    overflow: auto;
    margin-right: 5px;
    .comments-wrapper {
      padding-top: 0px !important;
    }
  }
}
.buttons-wrapper .v-btn {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(270deg, #CF0072 -19.12%, #E9994A 102.38%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-width: 0px;
}
::v-deep .v-btn__content {
  color: #fff !important;
}
::v-deep.v-badge {
  &__summary-comments {
    .v-badge__wrapper {
      top: 19px;
      left: -128px;
    }
  }
}
</style>
