<template>
  <div
    v-if="component"
    v-click-outside="onClickOutsideStandard"
    class="wrapper-cell"
    :style="[selectRead ? {'border': '1px solid var(--v-lightBlue-base)'} :{}]"
    @click="selectRow"
    @dblclick="handleDblclick">
    <div
      :class="{'select-overlay':selectRead,'img-size': imgType}">
      <component
        :is="component"
        :profile="!isRestoreMode ?item.uploadedBy : item.lastModifiedBy "
        :date-uploaded=" !isRestoreMode ? item.uploadedAt :item.lastModified "
        size-img="large"
        is-only-thumbnails
        :arr-type-designation="arrTypeDesignation"
        :selected-associated-types="item.associatedTypes"
        :edit-mode="editMode && !isRestoreMode"
        :is-expanded-mode="false"
        :expanded-row="true"
        :item="item"
        :header="header"
        :tags="tags"
        :selected-tag="item.tag"
        :read-mode="isRestoreMode"
        :document-id="item.id"
        :value="value"
        :col="col"
        v-on="$listeners"
        @change="update"
        @controlEditMode="controlEditMode" />
    </div>
  </div>
  <div v-else>
    {{ value }}
  </div>
</template>
<script>
import ProductCellString from '@/components/Product/ProductCell/ProductCellStringV2';
import DocumentReviewAttachment from '@/components/App/AppDocumentReviewAttachment';
import DocumentCellFileNameSize
  from '@/components/ProjectDetails/DocumentCell/DocumentCellFileNameSize';
import DocumentCellIcon from '@/components/ProjectDetails/DocumentCell/DocumentCellIcon';
import DocumentCellDate from '@/components/ProjectDetails/DocumentCell/DocumentCellDate';
import DocumentCellAssociatedType
  from '@/components/ProjectDetails/DocumentCell/DocumentCellAssociatedType';
import DocumentCellTags from '@/components/ProjectDetails/DocumentCell/DocumentCellTags';
export default {
  name: 'DocumentCell',
  components: {
    DocumentCellTags,
    DocumentCellAssociatedType,
    DocumentCellDate,
    DocumentCellIcon,
    DocumentCellFileNameSize,
    DocumentReviewAttachment,
    ProductCellString,
  },
  props: {
    isRestoreMode: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    arrTypeDesignation: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    header: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      editMode: false,
      selectRead: false,
    };
  },
  computed: {
    component() {
      const { type } = this;
      if (['SINGLE'].includes(type)) {
        return ProductCellString;
      } else if (['IMAGE'].includes(type)) {
        return DocumentReviewAttachment;
      } else if (['NAME_SIZE'].includes(type)) {
        return DocumentCellFileNameSize;
      } else if (['ICON'].includes(type)) {
        return DocumentCellIcon;
      } else if (['UPLOADED_DATE'].includes(type)) {
        return DocumentCellDate;
      } else if (['ASSOCIATED_TYPE'].includes(type)) {
        return DocumentCellAssociatedType;
      } else if (['TAGS'].includes(type)) {
        return DocumentCellTags;
      }
      return null;
    },
    imgType() {
      return this.type === 'IMAGE';
    },
    type() {
      return this.header.type;
    },
    col() {
      return this.header.text;
    },
    value() {
      const { item, header } = this;
      return item[header.value];
    },
  },
  methods: {
    onClickOutsideStandard() {
      this.selectRead = false;
    },
    selectRow() {
      if (['SINGLE', 'ASSOCIATED_TYPE', 'TAGS'].includes(this.type)) this.selectRead = true;
    },
    controlEditMode(val) {
      this.editMode = val;
    },
    handleDblclick() {
      if (!this.item?.canModifyNotOwnProjectDocuments?.allowed) {
        return;
      }
      const { editMode: val } = this;
      if (!val) {
        this.editMode = true;
      }
    },
    update({ value }) {
      this.$emit('addDescription', value, this.item.description, this.item.id);
      this.editMode = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.img-size {
  height: 100%;
}
.select-overlay {
  justify-content: center;
  flex-direction: column;
  position: absolute;
  display: flex;
  min-width: calc(100% + 2px);
  min-height: calc(100% + 2px);
  top: 0;
  left: 0;
  padding: 10px 16px;
  z-index: 10;
  background-color: white;
  border: 1px solid var(--v-lightBlue-base);
  margin: -1px;
  .wrapper__wide {
    min-width: 50px !important;
    height: 120px !important;
  }
  ::v-deep .wrapper {
    .link-wrapper, .content-types {
      overflow: auto !important;
      scroll-behavior: smooth !important;
      height: max-content !important;
      max-height: 120px !important;
      -webkit-line-clamp: unset !important;
      width: 100% !important;
    }
  }
  ::v-deep .input-wrapper .v-text-field > .v-input__control > .v-input__slot {
    textarea {
      font-size: 16px !important;
      line-height: 20px !important;
    }
    .v-text-field__slot {
      height: 80%;
    }
    &:after, &:before {
      content: none;
    }
  }
}
.wrapper-cell {
  height: 100px !important;
  padding: 10px 15px;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  ::v-deep .link-wrapper {
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    max-height: 100px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    scroll-behavior: smooth;
    word-break: break-word;
    width: 100%;
  }
}
</style>
