<template>
  <v-card-actions class="pa-sm-8 pt-6 pa-4 d-flex justify-center">
    <ProductDuplicateV2
      :no-modal="true"
      :no-modal-collection-id="actionCollectionId"
      :duplicate-type="TYPE_FOLLOW"
      :product-from-menu="item"
      :user-role="findUserRoleInLibraryForActiveHeader"
      :duplicated-collection-id="followedCollectionId"
      :duplicated-product-ids="[productId]">
      <template #duplicateButton="{ disableAction, btnText, loading }">
        <div class="d-flex">
          <app-btn
            bottom
            :disabled="disableAction"
            show-tooltip
            color="lightBlue"
            class="mt-3 ml-3"
            :loading="loading"
            outlined>
            {{ btnText }}
          </app-btn>
        </div>
      </template>
    </ProductDuplicateV2>
  </v-card-actions>
</template>
<script>
import ProductDuplicateV2 from '@/components/Product/ProductDuplicateV2/index.vue';
import {
  mapGetters, mapState,
} from 'vuex';
import {
  TYPE_DUPLICATE, TYPE_FOLLOW,
} from '@/constants';
export default {
  name: 'ProductSearchResDialogActions',
  components: {
    ProductDuplicateV2,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    productId: {
      type: String,
      default: '',
    },
    followedCollectionId: {
      type: String,
      default: '',
    },
    userRoleInCollection: {
      type: String,
      default: '',
    },
    actionCollectionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      TYPE_DUPLICATE,
      TYPE_FOLLOW,
    };
  },
  computed: {
    ...mapState(['showSpinner', 'activeHeader']),
    ...mapGetters({
      findUserRoleInLibraryForActiveHeader: 'UserRoles/findUserRoleInLibraryForActiveHeader',
    }),
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-application p {
  margin-bottom: 0px !important;
}
::v-deep.v-btn {
  width: 154px;
}
</style>
