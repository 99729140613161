<template>
  <div>
    <project-detail-controls-view
      :users-initial="usersInitial"
      :set-selected-view="setSelectedView"
      :users-to-delete="usersToDelete"
      :roles-to-delete="rolesToDelete"
      :item="selectedItem"
      :query-information="queryInformation"
      :dialog-status.sync="dialogStatus"
      @deleteView="deleteView"
      @update-headers="updateHeaders"
      @closeAutocomplete="closeAutocomplete"
      @initSelectedView="initSelectedView"
      @initRolesToDelete="initRolesToDelete"
      @usersToRemove="usersToRemove" />
    <project-detail-view-copy
      :views="views"
      :selected-view="selectedCopiedView"
      :dialog-status.sync="copyDialogStatus"
      @onCopy="onCopy"
      @updateCopiedView="updateCopiedView"
      @closeAutocomplete="closeAutocomplete" />
    <v-autocomplete
      ref="views"
      v-model="selected"
      autocomplete="off"
      attach="#select-view-container"
      :items="views"
      item-text="name"
      item-value="id"
      return-object
      hide-details
      placeholder="Select the view"
      class="mr-2 v-autocomplete__views"
      solo
      flat
      hide-no-data
      content-class="select-view-container__content"
      style="max-width: 177px; width: auto">
      <v-list-item
        v-if="createNewProjectView"
        slot="prepend-item"
        class="v-list-item--link create-new-view"
        @click.stop="showCopyPopup">
        <template>
          <span>
            {{ createNewView }}
          </span>
        </template>
      </v-list-item>
      <template
        #item="{item}">
        <v-tooltip
          :disabled="showViewName(item.name).showTool"
          top>
          <template
            v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              :data-test="setDataTest(item)"
              v-on="on">
              {{ showViewName(item.name).item }}
            </span>
            <div
              class="action-wrapper">
              <app-icon-btn
                v-if="isViewCustom(item)"
                :access="canWorkWithModifyingBasedOnTag"
                bottom
                show-tooltip
                icon-color="rgb(177 177 177)"
                icon-name="mdi-delete"
                btn-class="pl-5"
                icon-height="23px"
                @click.stop="canWorkWithModifyingBasedOnTag.allowed && deleteView(item)" />
              <app-icon-btn
                bottom
                class="d-flex align-self-center"
                btn-class="ml-2 inverted-icon pointer setting-project-icon"
                :access="canViewInfo"
                :icon-name="require('@/assets/icons/settings-icon.svg')"
                icon-height="19px"
                icon-width="19px"
                show-tooltip
                :is-icon="false"
                @click.stop="canViewInfo.allowed && showItems(item)" />
            </div>
          </template>
          <span>
            {{ item.name || item }}
          </span>
        </v-tooltip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import ProjectViewsApi from '@/services/graphql/projectViews';
import ProjectDetailControlsView from './ProjectDetailControlsView';
import ProjectDetailViewCopy from './ProjectDetailViewCopy';
import LoadingSpinnerTimeout from '@/mixins/LoadingSpinnerTimeout';
import {
  sortHelper,
  convertStringToArray,
} from '@/utils';
import {
  DATA_TEST_OF_VIEWS,
  CREATE_NEW_VIEW,
  SORT_TOP,
} from '@/constants/scheduleViews';
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
export default {
  name: 'ProjectDetailsViews',
  components: {
    ProjectDetailControlsView,
    ProjectDetailViewCopy,
  },
  mixins: [LoadingSpinnerTimeout],
  data() {
    return {
      selectedItem: {
      },
      selectedCopiedView: {
      },
      usersInitial: [],
      usersToDelete: [],
      rolesToDelete: [],
      rolesToCompareForDelete: [],
      setSelectedView: [],
      dialogStatus: false,
      copyDialogStatus: false,
      queryInformation: {
      },
      createNewView: CREATE_NEW_VIEW,
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['createNewProjectView']),
    ...mapGetters('ScheduleViews', ['selectedView']),
    ...mapGetters('UserRoles', ['canModifyCustomViewBasedOnTag', 'canModifyViews']),
    ...mapState(['role']),
    ...mapState('ScheduleViews', ['views']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', ['createNewProjectView']),
    projectId() {
      return this.$route.params.id;
    },
    canViewInfo() {
      return this.canModifyViews(this.role);
    },
    canWorkWithModifyingBasedOnTag() {
      return this.canModifyCustomViewBasedOnTag(this.role);
    },
    selected: {
      get() {
        return this.selectedView;
      },
      set(val) {
        clearTimeout(this.syncTime);
        if (this.selectedView?.id !== val?.id) {
          this.setUploadingSpinner(true);
          this.syncTime = setTimeout(() => {
            if (this.selectedView?.id !== val?.id) {
              this.setViewId(val);
            }
          }, 10);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setViewId: 'ScheduleViews/setSelectedViewId',
      setViews: 'ScheduleViews/setViews',
      handleError: 'handleError',
    }),
    ...mapMutations({
      spinner: 'spinner',
      setActiveTags: 'ScheduleViews/setActiveTags',
    }),
    updateHeaders() {
      const { id = '' } = this.selectedItem || {
      };
      const updatedView = this.views.find(e => e?.id === id);
      if (updatedView) this.selectedItem = updatedView;
      this.$emit('update-headers');
    },
    initSelectedView(data) {
      this.selectedView = data;
    },
    initRolesToDelete(roles) {
      this.rolesToDelete = this.rolesToCompareForDelete.reduce((arr, currentValue) => {
        const { role: currentRole = '' } = currentValue || {
        };
        const isDeletedRol = !roles.includes(currentRole);
        return isDeletedRol ? [...arr, currentRole] : arr;
      }, []);
      console.log(this.rolesToDelete);
    },
    usersToRemove(item) {
      this.usersToDelete.push(item);
    },
    async showItems(item) {
      this.spinner(true);
      const { projectId } = this;
      this.selectedItem = item;
      this.queryInformation = {
        projectId,
        tableId: this.scheduleId,
        tableType: 'schedule',
        viewId: item.id,
        workspaceId: this.activeWorkspaceId,
      };
      this.usersInitial = [];
      this.usersToDelete = [];
      this.rolesToDelete = [];
      try {
        const info = await ProjectViewsApi.getUsersWithSpecialAccessToView(this.queryInformation);
        const { data } = await ProjectViewsApi.getRolesPermissionsToView(this.queryInformation);
        this.usersInitial = info.data.response.map(el => el.user);
        this.setSelectedView = data.response;
        this.rolesToCompareForDelete = data.response;
        this.dialogStatus = true;
      } catch (err) {
        console.log('getUsersWithSpecialAccessToView err', err);
      } finally {
        this.spinner(false);
      }
    },
    showCopyPopup() {
      this.spinner(true);
      try {
        this.copyDialogStatus = true;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    updateCopiedView(val) {
      const updatedView = this.views.find(e => e?.id === val.id);
      if (updatedView) this.selectedCopiedView = updatedView;
    },
    async onCopy() {
      this.spinner(true);
      const {
        scheduleId,
        selectedCopiedView,
        activeWorkspaceId,
      } = this;
      try {
        const { data } = await ProjectViewsApi.saveCustomView({
          scheduleId: scheduleId,
          projectId: this.$route.params.id,
          viewId: selectedCopiedView.id,
          viewName: 'New ' + selectedCopiedView.name,
          filterTag: '',
          workspaceId: activeWorkspaceId,
        });
        const { response } = data;
        const item = {
          ...response,
          filterTag: !response.filterTag ? [] : convertStringToArray(response.filterTag),
          sortingData: sortHelper(response.id, response.sortingField, SORT_TOP),
        };
        this.setViews({
          arrViews: [...this.views, item],
        });
        this.setViewId(item);
        this.showItems(item);
        this.$refs.views.isMenuActive = false;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    setDataTest({ name = null } = {
    }) {
      if (!name) null;
      return DATA_TEST_OF_VIEWS[name];
    },
    setAppropriateView() {
      const { views } = this;
      const currentView = views[views.length - 1];
      this.setViewId(currentView);
    },
    closeAutocomplete() {
      this.$refs.views.isMenuActive = false;
      this.$refs.views.blur();
    },
    async deleteView({ id }) {
      const { selectedView, views } = this;
      try {
        this.spinner(true);
        const { data } = await ProjectViewsApi.deleteView({
          scheduleId: this.scheduleId,
          viewId: id,
          projectId: this.$route.params.id,
          workspaceId: this.activeWorkspaceId,
        });
        const responseView = data.response;
        const arrViews = views.filter(element => element.id !== responseView.id);
        this.setViews({
          arrViews,
        });
        if (selectedView.id === responseView.id) {
          this.setAppropriateView();
        }
        this.$refs.views.isMenuActive = false;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    showViewName(val) {
      if (val && val.length > 30) {
        const item = `${val.substr(0, 30)}...`;
        return {
          item,
          showTool: false,
        };
      } else {
        return {
          item: val,
          showTool: true,
        };
      }
    },
    isViewCustom(item) {
      return item.type === 'custom';
    },
  },
};
</script>
<style scoped lang="scss">
$accent-color: #71d6e0;
.v-text-field.v-autocomplete__views {
  border: none;
}
::v-deep.v-text-field.v-autocomplete__views .v-input__control .v-input__slot {
  background: var(--v-lightBlue-base);
}
::v-deep.v-text-field.v-input--is-dirty input {
  color: #085C71 !important;
}
::v-deep.v-text-field .v-icon.v-icon {
  color: #439CB1;
}
::v-deep .primary--text {
  color: var(--v-lightBlack-base) !important;
  font-weight: 600;
}
::v-deep .v-list-item--active::before {
  opacity: 0 !important;
  font-weight: 600;
  z-index: 1;
  background: var(--v-lightBlack-base) !important;
  font-size: 16px !important;
  color: var(--v-lightBlack-base) !important;
}
.action-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.create-new-view {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: $accent-color;
  z-index: 1;
}
</style>
