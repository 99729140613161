<template>
  <BaseLayout v-bind="$attrs">
    <template
      #list="{
      filterHeadersByFlexItem,
      filterNamesGroupItems,
      isRequiredField,
      canBeRendered,
      isDialogCollection,
      collectionId,
      renderClass,
      searchMode,
      showPensil,
      view,
      disabledEditMode,
      isAsideForm,
      isLoading,
    }">
      <WrapperFormRow
        v-for="(headerItem, i) in filterHeadersByFlexItem"
        :key="`${headerItem.value}__${i}`"
        :item="headerItem">
        <template
          #default="{ header, single }">
          <AppFormRow
            v-if="canBeRendered(header , item)"
            :class="{ 'hide-when-aside-shows': isAsideHeader(header.text) && !isAsideForm }"
            :single-item="single"
            :filter-names-group-items="filterNamesGroupItems"
            :is-required-field="isRequiredField(header.id)"
            :header-text="header.value"
            :split-header-and-cell="isAsideHeader(header.text)">
            <template #rowCell>
              <ProductCell
                :is-dialog-collection="isDialogCollection"
                :collection-id="collectionId"
                :is-loading="isHeaderLoading(header.text)"
                :class="renderClass(header)"
                :view="view"
                :expanded-row="!showPensil(header.column.type)"
                :should-expand-height="isAsideHeader(header.text)"
                :header="header"
                :disabled-edit-mode="isHeaderDisabledForEditing(disabledEditMode, header)"
                :item="item"
                class="pa-0"
                open-link-in-new-tab
                :mapped-dropdowns-of-view="mappedDropdownsOfView"
                :search-mode="searchMode"
                :style="{ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }"
                @updateProductField="$emit('updateProductField', $event)" />
            </template>
          </AppFormRow>
        </template>
      </WrapperFormRow>
      <template v-if="isLoading">
        <ProductCellFormSkeleton v-if="!isAsideForm" />
        <ProductCellFormAsideSkeleton v-else />
      </template>
    </template>
  </BaseLayout>
</template>
<script>
import AppFormRow from '@/components/App/AppFormRow';
import ProductCell from '@/components/Product/ProductCell';
import BaseLayout from './ProductCellCollectionsFormListBaseLayout';
import WrapperFormRow from '@/components/App/AppFormRow/WrapperFormRow';
import ProductCellFormSkeleton from '@/components/Product/ProductCell/ProductCellFormSkeleton';
import ProductCellFormAsideSkeleton from '@/components/Product/ProductCell/ProductCellFormAsideSkeleton';
import { isAsideHeaderName } from '@/utils/productHeaders';
export default {
  name: 'ProductCellCollectionsFormList',
  components: {
    WrapperFormRow,
    AppFormRow,
    ProductCell,
    ProductCellFormSkeleton,
    ProductCellFormAsideSkeleton,
    BaseLayout,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
      }),
    },
    mappedDropdownsOfView: {
      type: Object,
      default: () => {
      },
    },
    loadingHeaders: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isAsideHeader(headerName) {
      return isAsideHeaderName(headerName);
    },
    isHeaderLoading(headerName) {
      return this.loadingHeaders?.includes(headerName);
    },
    isHeaderDisabledForEditing(disabledEditMode, headerName) {
      return disabledEditMode || this.isHeaderLoading(headerName);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.hide-when-aside-shows {
  display: none;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    // The aside items show at the bottom of the original now.
    display: block;
  }
}
.field-error {
  border-bottom: 2px solid red;
}
</style>
