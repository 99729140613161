var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key,staticClass:"table-cell-wrapper",class:{
    'inline-field': _vm.isExpandedMode,
    'table-cell-wrapper__selected': _vm.isSelectedCell && _vm.allowShowSelectCol,
    'table-cell-wrapper__selected--one': _vm.isOneCellSelected,
    'table-cell-wrapper__attachment': _vm.isAttachmentCell,
  },on:{"keydown":_vm.editActions,"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.onExactClick.apply(null, arguments)},function($event){if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.setMultipleCtrlCells.apply(null, arguments)},function($event){if(!$event.shiftKey){ return null; }if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }return _vm.setMultipleShiftCells.apply(null, arguments)},function($event){if(!$event.metaKey){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey){ return null; }return _vm.setMultipleCtrlCells.apply(null, arguments)}],"mousedown":_vm.onSetKeyboardControllerDisabled,"dblclick":_vm.doubleClickCell}},[_c('div',{staticClass:"table-cell-wrapper__selected-wrapper",class:{
      'table-cell-wrapper__selected--one-overlay': _vm.isSelectedCell && _vm.allowShowSelectCol && _vm.isAllowedToSelectOne,
      'table-cell-wrapper__selected--edited-cell' : _vm.isOverlayedEditCell
    }},[(!_vm.hideActions)?_vm._t("additionalCellActions",null,{"borderedCell":_vm.borderedCell,"isCellForPasting":_vm.isPasteCell}):_vm._e(),(_vm.component)?_c('div',{staticClass:"component-wrapper"},[_c(_vm.component,_vm._g({ref:_vm.getRefOfCell,tag:"component",attrs:{"collection-id":_vm.collectionId,"has-access-editor":_vm.hasAccessToField,"col":_vm.columnName,"hint":_vm.header.column.hint,"header":_vm.header,"is-expanded-mode":_vm.isExpandedMode,"expanded-row":_vm.expandedRow,"should-expand-height":_vm.shouldExpandHeight,"is-array":_vm.isHeaderTypeArray,"value":_vm.value,"table-id":_vm.scheduleId,"edit-mode":_vm.editMode,"disabled-edit-mode":_vm.disabledEditMode,"show-pensil":_vm.showPensil,"item":_vm.item,"style-filled-img":_vm.styleFilledImg,"hidden-row":_vm.hiddenRow,"is-opened-in-dialog":_vm.isOpenedInDialog,"bordered-cell":_vm.borderedCell,"view":_vm.view,"select-items":_vm.header.items ? _vm.header.items : [],"current-row-id":_vm.currentRowId,"is-overlayed":_vm.isOneCellSelected,"open-link-in-new-tab":_vm.openLinkInNewTab,"is-dialog-collection":_vm.isDialogCollection,"transform-images-to-data":_vm.transformImagesToData,"mapped-dropdowns-of-view":_vm.mappedDropdownsOfView},on:{"messagesNoHasAccessEditor":_vm.messagesNoHasAccessEditor,"dblclickHandler":_vm.doubleClickCell,"toggleEditMode":_vm.toggleEditMode,"setEditMode":_vm.setEditMode,"change":function($event){return _vm.updateCell($event, _vm.columnName, _vm.currentRowId)},"saveFileProcess":_vm.onUpdateImages}},_vm.$listeners)),(_vm.isLoading)?_c('div',{staticClass:"loading-overlay"},[_c('img',{staticClass:"loading-overlay-spinner",attrs:{"src":require("@/assets/icons/circle-loader.gif")}})]):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(Array.isArray(_vm.value) ? _vm.value.join(', ') : _vm.value)+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }