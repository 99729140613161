<template>
  <app-action-btn
    :access="canModifyTableRows(role)"
    :icon-src="require('@/assets/icons/delete-icon.svg')"
    img-class="inverted-icon"
    show-tooltip
    top
    @click="canModifyTableRows(role).allowed && askAndPerform(rowsDeleteAction, getRemoveText)({
      tableType,
      rowId,
      projectId: $route.params.id
    })">
    Delete
  </app-action-btn>
</template>
<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import { askAndPerform } from '@/utils';
export default {
  name: 'TableDeleteRow',
  props: {
    rowId: {
      type: String,
      default: null,
    },
    tableType: {
      type: String,
      default: 'schedule',
    },
  },
  computed: {
    ...mapState(['role']),
    ...mapState('ScheduleRows', ['checkboxSelectedRows']),
    ...mapGetters('UserRoles', ['canModifyTableRows']),
    ...mapGetters('ScheduleRows', ['isMultipleRowsSelected']),
    getRemoveText() {
      return 'Are you sure you want to delete selected row(s)?';
    },
    rowsDeleteAction() {
      if (this.isMultipleRowsSelected) {
        return this.deleteMultipleRows;
      }
      return this.deleteRow;
    },
  },
  methods: {
    askAndPerform,
    ...mapActions({
      handleError: 'handleError',
      deleteRow: 'ScheduleRows/deleteRow',
      clearSelectedRows: 'ScheduleRows/clearSelectedRows',
    }),
    async deleteMultipleRows() {
      await Promise.all(this.checkboxSelectedRows.map(async (rowId)=>{
        return await this.deleteRow(
          {
            tableType: this.tableType,
            rowId,
            projectId: this.$route.params.id,
          }
        );
      } ));
      this.clearSelectedRows();
    },
  },
};
</script>
