<template>
  <ProductCellCurrencyLayout
    :id="item.id"
    :edit-mode="editMode"
    :is-expanded-mode="isExpandedMode"
    :disabled-edit-mode="disabledEditMode"
    :col="col"
    :value-for-rendering="valueForRendering">
    <template #inputBlock>
      <v-text-field
        v-model="newValue"
        class="border-outline"
        :clearable="!isExpandedMode"
        :outlined="isExpandedMode"
        :disabled="!editMode"
        :hide-details="!currencyErrorMessage.length"
        :error-messages="currencyErrorMessage"
        prefix="$"
        :autofocus="!expandedRow"
        @blur="update" />
    </template>
  </ProductCellCurrencyLayout>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
export default {
  name: 'ProductCellCurrencyWithUnitV2',
  components: {
    ProductCellCurrencyLayout: () => import('../ProductCellCurrencyV2/ProductCellCurrencyLayout'),
  },
  mixins: [EditModeChildComponent],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: () => null,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
  },
  data: function () {
    return {
      newValue: this.value || 0,
    };
  },
  computed: {
    valueForRendering() {
      const { value, $formatCurrency, checkIsInteger, extractCurrencyUnit, extractCurrency } = this;
      const currencyVal = extractCurrency(value);
      const currencyToRendering = checkIsInteger($formatCurrency(currencyVal));
      const currencyUnitToRendering = extractCurrencyUnit({
        value, pos: currencyVal?.length,
      }) || '';
      return `${currencyToRendering}<strong>${currencyUnitToRendering}</strong>`;
    },
    currencyErrorMessage() {
      if (this.isFirstCharNum) {
        return [];
      }
      return ['Must start with number'];
    },
    isFirstCharNum() {
      const { newValue: str } = this;
      return !str || this.parseCurrencyString(str);
    },
  },
  methods: {
    checkIsInteger(val) {
      let splited = val.toString().split('.');
      return splited[1] === '00' || splited[1] === '0' ? splited[0] : val;
    },
    parseCurrencyString(str) {
      try {
        const string = str.toString();
        const isFirstElNumber = /^\d/.test(string);
        const numbers = string.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g);
        return isFirstElNumber && numbers;
      } catch (err) {
        console.log('err', err);
        return '';
      }
    },
    extractCurrency(str) {
      if (!str) {
        return 0;
      }
      return this.parseCurrencyString(str)?.[0];
    },
    extractCurrencyUnit({ value, pos }) {
      if (!value || !pos) {
        return '';
      }
      const stringValue = value?.toString();
      return stringValue?.substr(pos);
    },
    update() {
      if (!this.isFirstCharNum) {
        return;
      }
      this.$emit('change', {
        value: this.newValue?.toString() || '0',
      });
      this.toggleEditMode();
    },
  },
};
</script>

