var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-actions',{staticClass:"pa-sm-8 pt-6 pa-4 d-flex justify-center"},[(_vm.isNeedToShowAddToProjectButton)?_c('ProductAddToProject',{attrs:{"item":_vm.item},scopedSlots:_vm._u([{key:"buttonWrapper",fn:function(ref){
var btnText = ref.btnText;
return [_c('v-btn',{class:[ _vm.showAllActionsForReadOnly ? 'mt-3' : 'mr-2'],attrs:{"outlined":"","color":"lightBlue"}},[_vm._v(" "+_vm._s(btnText)+" ")])]}}],null,false,9623046)}):_vm._e(),_c('div',{staticClass:"px-0"},[(_vm.isHideDeleteButton)?_c('app-btn',{staticClass:"mt-3 ml-3",attrs:{"show-tooltip":"","bottom":"","access":_vm.canModifyOwnProduct,"color":"mainRed"},on:{"click":function($event){return _vm.$emit('delete-current-product')}}},[_vm._v(" delete ")]):_vm._e(),(_vm.isFollowButtonVisible)?_c('ProductDuplicateV2',{attrs:{"duplicate-type":_vm.TYPE_FOLLOW,"is-store-product-preload":_vm.isStoreProductPreload,"product-from-menu":_vm.item,"user-role":_vm.findUserRoleInLibraryForActiveHeader,"duplicated-collection-id":_vm.followedCollectionId,"duplicated-product-ids":[_vm.productId]},scopedSlots:_vm._u([{key:"duplicateButton",fn:function(ref){
var disableAction = ref.disableAction;
var btnText = ref.btnText;
var loading = ref.loading;
return [_c('div',{staticClass:"d-flex"},[_c('app-btn',{staticClass:"mt-3 ml-3",attrs:{"bottom":"","disabled":disableAction,"show-tooltip":"","color":"lightBlue","loading":loading,"outlined":""}},[_vm._v(" "+_vm._s(btnText)+" ")])],1)]}}],null,false,2536416584)}):_vm._e()],1),(!_vm.showAllActionsForReadOnly)?[_c('v-btn',{attrs:{"outlined":"","color":"lightBlue"},on:{"click":function($event){return _vm.$emit('show-product-modal', false)}}},[_vm._v(" Cancel ")]),(_vm.isEditModalRelatedVariant || _vm.isUserCanEditProduct)?_c('app-btn',{attrs:{"bottom":"","btn-class":"ml-3","disabled":_vm.disabledEditMode,"access":_vm.canModifyOwnProduct,"show-tooltip":!_vm.canModifyOwnProduct.allowed,"color":"lightBlue"},on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" Update ")]):_c('v-btn',{attrs:{"disabled":_vm.isDisabledCreateButton,"color":"lightBlue"},on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" Save ")])]:(_vm.showAllActionsForReadOnly && !_vm.$route.path.includes('/shared'))?[(_vm.hasAccessToProduct || _vm.isUserCanEditProduct || _vm.showAllActionsForCollectionFollower)?_c('ProductDuplicateV2',{attrs:{"is-store-product-preload":_vm.isStoreProductPreload,"duplicate-type":_vm.TYPE_DUPLICATE,"user-role":_vm.findUserRoleInLibraryForActiveHeader,"duplicated-collection-id":_vm.followedCollectionId,"product-from-menu":_vm.item,"duplicated-product-ids":[_vm.productId]},scopedSlots:_vm._u([{key:"duplicateButton",fn:function(ref){
var disableAction = ref.disableAction;
var btnText = ref.btnText;
return [_c('div',{staticClass:"d-flex"},[_c('app-btn',{staticClass:"mt-3 ml-3",attrs:{"bottom":"","disabled":disableAction,"show-tooltip":"","color":"lightBlue","loading":_vm.showSpinner,"outlined":""}},[_vm._v(" "+_vm._s(btnText)+" ")])],1)]}}],null,false,334377338)}):_vm._e(),_c('ProductDuplicateV2',{attrs:{"duplicate-type":_vm.TYPE_FOLLOW,"is-store-product-preload":_vm.isStoreProductPreload,"product-from-menu":_vm.item,"user-role":_vm.findUserRoleInLibraryForActiveHeader,"duplicated-collection-id":_vm.followedCollectionId,"duplicated-product-ids":[_vm.productId]},scopedSlots:_vm._u([{key:"duplicateButton",fn:function(ref){
var disableAction = ref.disableAction;
var btnText = ref.btnText;
var loading = ref.loading;
return [_c('div',{staticClass:"d-flex"},[_c('app-btn',{staticClass:"mt-3 ml-3",attrs:{"bottom":"","disabled":disableAction,"show-tooltip":"","color":"lightBlue","loading":loading,"outlined":""}},[_vm._v(" "+_vm._s(btnText)+" ")])],1)]}}])}),(_vm.hasAccessToProduct || _vm.isUserCanEditProduct || _vm.showAllActionsForCollectionFollower)?_c('app-btn',{attrs:{"access":_vm.showAllActionsForCollectionFollower ? _vm.showAllActionsForCollectionFollowerAccessDetails :_vm.canModifyOwnProduct,"color":"lightBlue","show-tooltip":_vm.showAllActionsForCollectionFollower,"bottom":"","btn-class":"mt-3 ml-3","loading":_vm.showSpinner},on:{"click":function($event){return _vm.$emit('changeToEdit')}}},[_vm._v(" Edit ")]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }