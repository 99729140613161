<template>
  <app-action-btn
    :access="canModifyTableRows(role)"
    show-tooltip
    top
    @click="canModifyTableRows(role).allowed && onDuplicateRow({
      tableId: scheduleId,
      tableType,
      rowId,
      projectId: $route.params.id,
    })">
    <template #iconWrapper>
      <v-icon
        small
        color="lightBlack">
        mdi-content-copy
      </v-icon>
    </template>
    Duplicate
  </app-action-btn>
</template>
<script>
import {
  mapActions, mapState, mapGetters,
} from 'vuex';
export default {
  name: 'TableCopyRow',
  props: {
    rowId: {
      type: String,
      default: null,
    },
    tableType: {
      type: String,
      default: 'schedule',
    },
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('ScheduleRows', ['checkboxSelectedRows']),
    ...mapState(['role']),
    ...mapGetters('UserRoles', ['canModifyTableRows']),
    ...mapGetters('ScheduleRows', ['isMultipleRowsSelected']),
  },
  methods: {
    ...mapActions('ScheduleRows', ['duplicateRow', 'clearSelectedRows']),
    async onDuplicateRow(params) {
      if (this.isMultipleRowsSelected) {
        await this.onDuplicateSelectedRows(params);
        this.clearSelectedRows();
      }
      else {
        await this.onDuplicateSingleRow(params);
      }
    },
    async onDuplicateSingleRow(params) {
      this.duplicateRow(params);
    },
    async onDuplicateSelectedRows(params) {
      return Promise.all(this.checkboxSelectedRows.map( async (seletedRowId)=>{
        await this.duplicateRow(
          {
            ...params,
            rowId: seletedRowId,
          }
        );
      }));
    },
  },
};
</script>
