<template>
  <v-card
    id="card"
    class="dialog-content"
    @click="onFocusIn"
    @paste="onPaste">
    <v-card-title>
      <div class="d-flex align-center justify-space-between w-100">
        <div>
          <slot name="title">
            <div>
              Project Documents
            </div>
            <div class="caption">
              Attach files for reference by other Project Members. Add
              Description and Tags after upload to keep organized
            </div>
            <app-focus-warning-input ref="dialogInput" />
          </slot>
        </div>
        <v-icon
          color="#fff"
          @click="$emit('closeDialog',false)">
          mdi-close
        </v-icon>
      </div>
    </v-card-title>
    <div class="p-4">
      <v-tabs
        v-model="tab"
        @change="updatedDocument">
        <v-tab
          v-for="item in itemsTab"
          :key="item.id"
          :href="`#${item.type}`">
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="content">
      <div
        v-if="!isRestoreMode"
        id="attach-collection-details"
        class="d-block">
        <app-focus-warning :show-warning="showFocusWarning" />
        <file-upload
          ref="upload"
          v-model="files"
          style="cursor: pointer"
          chunk-enabled
          :disabled="!isAllowedCanWorkWithProjectDocuments"
          :input-id="idFileUpload"
          :multiple="true"
          :drop="true"
          :drop-directory="false">
          <img
            src="@/assets/icons/dragAndDrop.png"
            alt="dragAndDrop">
          <span :style="!isAllowedCanWorkWithProjectDocuments && {color: 'grey'}">Copy and paste or drag and drop documents here or click to upload</span>
        </file-upload>
      </div>
      <v-tabs-items
        v-model="tab">
        <v-tab-item
          v-for="itemTab in itemsTab"
          :key="itemTab.id"
          :value="itemTab.type">
          <project-table-documents
            :arr-type-designation="arrTypeDesignation"
            :selected-doc.sync="selectedDoc[tab]"
            :is-init-data="isInitData"
            :current-tab="tab"
            :is-restore-mode="isRestoreMode"
            :is-modal-open="isModalOpen"
            :tags="tags"
            :items="items[tab]"
            :headers="replacedHeaders"
            @removeDocument="removeDocument"
            @setTags="setTags"
            @addDescription="addDescription"
            @addAssociatedType="addAssociatedType">
            <template #customActions="{item}">
              <slot
                :item="item"
                name="customActions" />
            </template>
          </project-table-documents>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-card-actions
      v-if="!isRestoreMode"
      class="d-flex justify-space-between footer">
      <v-btn
        :disabled="!counterSelectedDoc"
        color="lightBlue"
        @click="downloadFiles">
        download {{ counterSelectedDoc }} document{{ counterSelectedDoc > 1 ? 's' : '' }}
      </v-btn>
      <app-action-btn
        class="trash"
        @click="$refs.restore.openDialog()">
        <v-icon size="30">
          mdi-delete-outline
        </v-icon>
        <span>Trash</span>
      </app-action-btn>
    </v-card-actions>
    <restore-table-document
      v-if="!isRestoreMode"
      ref="restore"
      :type-document="tab"
      :can-work-with-project-documents="canWorkWithProjectDocuments"
      :is-modal-open="isModalOpen"
      @restoreDeletedDocument="restoreDeletedDocument" />
  </v-card>
</template>
<script>
import FileUpload from 'vue-upload-component';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ProjectDocumentsApi from '@/services/graphql/projectDocuments';
import ProjectTableDocuments from '@/components/ProjectDetails/ProjectTableDocuments';
import FocusOutModal from '@/mixins/FocusOutModal';
import ParseItemsAttachment from '@/mixins/ParseItemsAttachment';
import { Storage } from 'aws-amplify';
import RestoreTableDocument from '@/components/ProjectDetails/RestoreTableDocument';
import { TYPE_DOCUMENTS } from '@/constants/projectDocument';
import PasteClipboard from '@/mixins/PasteClipboard';
import ErrorsText from '@/constants/errors';
import AppFocusWarning from '@/components/App/AppFocusWarning';
import AppFocusWarningInput from '@/components/App/AppFocusWarningInput';
export default {
  name: 'ProjectListDocuments',
  components: {
    RestoreTableDocument,
    ProjectTableDocuments,
    FileUpload,
    AppFocusWarning,
    AppFocusWarningInput,
  },
  mixins: [ParseItemsAttachment, PasteClipboard, FocusOutModal],
  props: {
    idFileUpload: {
      type: String,
      default: 'mainDocument',
    },
    documentType: {
      type: String,
      required: true,
    },
    isRestoreMode: {
      type: Boolean,
      default: false,
    },
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    canWorkWithProjectDocuments: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: TYPE_DOCUMENTS[0].type,
      isInitData: false,
      tags: [],
      selectedDoc: {
        submittals: [],
        designDocument: [],
      },
      files: [],
      items: {
        submittals: [],
        designDocument: [],
      },
      itemsTab: TYPE_DOCUMENTS,
      headerProjectDocuments: [
        {
          text: `Thumbnail <br>(click to preview)`,
          value: 'thumbnails',
          width: 145,
          type: 'IMAGE',
        },
        {
          text: `Filename <br>(filesize)`,
          value: 'filename',
          type: 'NAME_SIZE',
          width: 145,
        },
        {
          text: 'Description',
          value: 'description',
          width: 205,
          type: 'SINGLE',
        },
        {
          text: 'Date Uploaded',
          value: 'uploadedAt',
          type: 'UPLOADED_DATE',
          width: 130,
        },
        {
          text: `Uploaded <br> By`,
          value: 'uploadedBy',
          type: 'ICON',
          width: 90,
        },
        {
          text: `Document Tag <br>(click to add)`,
          value: 'tag',
          width: 135,
          type: 'TAGS',
        },
        {
          text: 'Associated Type(s)',
          value: 'associatedTypes',
          width: 205,
          type: 'ASSOCIATED_TYPE',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('UserProfile', ['identityId']),
    ...mapGetters('ScheduleRows', ['arrTypeDesignation']),
    ...mapGetters(['userId']),
    ...mapGetters('UserRoles', ['canModifyNotOwnProjectDocuments']),
    ...mapState('ProjectDetailsTableSchedule', [
      'scheduleId',
    ]),
    ...mapState('Workspace', ['activeWorkspaceId']),
    isAllowedCanWorkWithProjectDocuments() {
      const { allowed = false } = this.canWorkWithProjectDocuments ?? {
      };
      return allowed;
    },
    projectId() {
      return this.$route.params.id;
    },
    replacedHeaders() {
      if (!this.isRestoreMode) return this.headerProjectDocuments;
      return this.headerProjectDocuments.map(e => {
        if (e.type === 'UPLOADED_DATE') {
          return {
            ...e, text: 'Last Modified',
          };
        } else if (e.type === 'ICON') {
          return {
            ...e, text: 'Deleted By',
          };
        }
        return e;
      });
    },
    counterSelectedDoc() {
      return this.selectedDoc[this.tab]?.length || 0;
    },
    requestForGetDocuments() {
      return this.isRestoreMode ? 'getDeletedProjectDocuments' : 'getProjectDocumentsV2';
    },
  },
  watch: {
    isModalOpen: {
      async handler(val) {
        if (!val) {
          for (const valKey in this.items) {
            this.items[valKey] = [];
            if (!this.isRestoreMode) {
              this.removeFocusEvent();
            }
          }
        } else {
          this.tab = this.documentType;
          this.getDocument(null, true, this.documentType);
          await this.getTags();
          this.createFocusEvent();
        }
      },
      immediate: true,
    },
    tab(val) {
      this.$emit('update:activeTab', val);
    },
    files(newValue, oldValue) {
      const oldFileIds = oldValue.map(e => e.id);
      const filterNewFiles = newValue.filter(e => !oldFileIds.includes(e.id));
      this.setDocument(filterNewFiles, this.tab);
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['handleError', 'downloadFileByBlob']),
    updatedDocument(type) {
      this.getDocument(null, true, type);
      this.getTags();
    },
    downloadFiles() {
      const items = this.selectedDoc[this.tab];
      if (items.length) {
        items.forEach(e => {
          const { url, filename, type } = e;
          console.log(url);
          this.downloadFileByBlob({
            type,
            filename,
            url,
          });
        });
      }
    },
    async restoreDeletedDocument(item, typeDocument) {
      try {
        this.spinner(true);
        const { activeWorkspaceId: workspaceId, projectId, scheduleId } = this;
        await ProjectDocumentsApi.restoreProjectDocument({
          workspaceId,
          scheduleId,
          projectId,
          documentId: item.id,
        });
        this.spinner(false);
        if (typeDocument !== this.tab) return false;
        await this.getDocument(null, true);
      } catch (err) {
        this.handleError(err);
        this.spinner(false);
      }
    },
    async removeDocument(item) {
      try {
        this.isInitData = true;
        const { activeWorkspaceId: workspaceId, projectId, scheduleId } = this;
        const { id: documentId } = item;
        const { data } = await ProjectDocumentsApi.deleteProjectDocument({
          workspaceId,
          scheduleId,
          projectId,
          documentId,
        });
        const id = data.response?.id;
        this.items[this.tab] = this.items[this.tab].filter(e => e.id !== id);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isInitData = false;
      }
    },
    async setTags(tag, documentId) {
      try {
        this.isInitData = true;
        const { activeWorkspaceId: workspaceId, scheduleId, projectId } = this;
        const { data } = await ProjectDocumentsApi.setProjectDocumentTag({
          workspaceId, scheduleId, projectId, documentId, tag,
        });
        if (data.response) {
          const findIndex = this.items[this.tab].findIndex(e => e.id === data.response.id);
          this.$set(this.items[this.tab][findIndex], 'tag', data.response.tag);
        }
        await this.getTags();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isInitData = false;
      }
    },
    async getTags() {
      const { activeWorkspaceId: workspaceId, scheduleId, projectId } = this;
      const { data } = await ProjectDocumentsApi.getProjectDocumentDropdowns({
        workspaceId, scheduleId, projectId,
      });
      if (!data.response) return false;
      const currentColumn = this.itemsTab.find(e => e.type === this.tab).column;
      const findTagsForCurrentDoc = data.response.find(e => e.column === currentColumn);
      this.tags = [...findTagsForCurrentDoc.fixedValues, ...findTagsForCurrentDoc.customValues];
    },
    async addDescription(description, oldDescription, documentId) {
      try {
        this.isInitData = true;
        if (description === oldDescription) return false;
        const { activeWorkspaceId: workspaceId, scheduleId, projectId } = this;
        const { data } = await ProjectDocumentsApi.addProjectDocumentDescription({
          workspaceId,
          scheduleId,
          projectId,
          documentId,
          description,
        });
        if (data.response) {
          const findIndex = this.items[this.tab].findIndex(e => e.id === data.response.id);
          this.$set(this.items[this.tab][findIndex], 'description', data.response.description);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isInitData = false;
      }
    },
    async addAssociatedType(savedIds, deletedIds, documentId) {
      try {
        await this.savedAssociatedTypes(savedIds, documentId);
        await this.deletedAssociatedTypes(deletedIds, documentId);
        await this.getDocument(null, true);
      } catch (err) {
        this.handleError(err);
      }
    },
    async savedAssociatedTypes(ids, documentId) {
      if (!ids.length) return false;
      const { activeWorkspaceId: workspaceId, scheduleId, projectId } = this;
      const requests = ids.map(async associatedRowId => (
        await ProjectDocumentsApi.addProjectDocumentAssociatedType({
          workspaceId,
          scheduleId,
          projectId,
          documentId,
          associatedRowId,
        })
      ));
      await Promise.all(requests);
    },
    async deletedAssociatedTypes(ids, documentId) {
      if (!ids.length) return false;
      const { activeWorkspaceId: workspaceId, scheduleId, projectId } = this;
      let rejectedIds = [];
      const requests = ids.map(async associatedRowId => {
        try {
          await ProjectDocumentsApi.removeProjectDocumentAssociatedType({
            workspaceId,
            scheduleId,
            projectId,
            documentId,
            associatedRowId,
          });
        } catch (err) {
          const isVersionConflict = err.errors?.some(e => e?.errorType === ErrorsText.ERROR_VERSION_CONFLICT);
          isVersionConflict && rejectedIds.push(associatedRowId);
        }
      }
      );
      await Promise.allSettled(requests).then(async () => {
        rejectedIds.length && await this.deletedAssociatedTypes(rejectedIds, documentId);
      });
    },
    async getDocument(nextToken = null, isUpdate = false, document = null) {
      try {
        this.isInitData = true;
        const {
          activeWorkspaceId: workspaceId,
          scheduleId,
          projectId,
          requestForGetDocuments,
        } = this;
        const documentType = document || this.tab;
        const { data } = await ProjectDocumentsApi[requestForGetDocuments]({
          workspaceId,
          documentType,
          scheduleId,
          projectId,
          nextToken,
          limit: 100,
        });
        const { data: items, nextToken: responseNextToken } = data.response;
        if (items?.length) {
          const newItems = await this.getImageFromStorage(items);
          const oldItems = isUpdate ? [] : [...this.items[documentType]];
          const newItemsWithOwner = newItems.map(item => ({
            ...item,
            canModifyNotOwnProjectDocuments: {
              ...this.canModifyNotOwnProjectDocuments,
              allowed: this.canModifyNotOwnProjectDocuments?.allowed || this.userId === item?.uploadedBy?.id,
            },
          }));
          this.$set(this.items, documentType, [...oldItems, ...newItemsWithOwner]);
        }
        if (responseNextToken) await this.getDocument(responseNextToken);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isInitData = false;
      }
    },
    async setDocument(files, documentType) {
      const { identityId, scheduleId: tableId, projectId, activeWorkspaceId: workspaceId } = this;
      try {
        this.isInitData = true;
        await Promise.all(files.map(async (file) => {
          const { data } = await ProjectDocumentsApi.declareProjectDocument({
            projectId,
            identityId,
            tableId,
            tableType: 'schedule',
            filename: file.name,
            size: `${file.size}`,
            type: file.type,
            workspaceId,
            documentType,
          });
          const { id } = data.response;
          const metadata = {
            identity_id: identityId,
            attachmentId: id,
            schedule_id: tableId,
            projectId,
            tableType: 'schedule',
            filename: file.name,
            workspace_id: workspaceId,
            attachmentDocType: 'document',
          };
          await Storage.put(`${id}/${file.name}`, file.file, {
            level: 'protected',
            identityId,
            contentType: file?.type,
            metadata,
          });
        }));
        await this.getDocument(null, true);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isInitData = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#attach-collection-details {
  margin-bottom: 25px;
}
.trash {
  ::v-deep p {
    margin-bottom: 0 !important;
  }
  cursor: pointer;
}
.content {
  padding: 10px 0 0 0;
}
::v-deep .v-text-field {
  margin: 0 !important;
  padding: 0 !important;
}
.footer {
  padding: 23px 32px !important;
}
</style>
