var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-block"},[_c('ProjectDetailsExportScheduleModal',{attrs:{"custom-views":_vm.customViews,"value":_vm.dialogForLimitByTag},on:{"update:value":function($event){_vm.dialogForLimitByTag=$event},"export":_vm.exportProject}}),_c('v-tooltip',{attrs:{"disabled":true,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","content-class":"edit-menu-main","nudge-left":"90","nudge-bottom":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"select-view-container__edit-button mr-2",attrs:{"outlined":"","color":"borderGrey"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},on),[_c('v-icon',{attrs:{"size":"20","color":"rgba(0, 0, 0, 0.45)"}},[_vm._v(" mdi-file-export ")]),_vm._v(" Export to ... ")],1)]}}],null,true),model:{value:(_vm.showExportMenu),callback:function ($$v) {_vm.showExportMenu=$$v},expression:"showExportMenu"}},[_c('v-card',[_c('v-list',{key:_vm.listKey},_vm._l((_vm.filteredExportItems),function(item,i){return _c('v-list-group',{key:i,attrs:{"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',[_c('v-list-item-title',{staticClass:"pointer",on:{"click":function($event){return _vm.selectItem(item.template, 'templateName')}}},[_vm._v(" "+_vm._s(item.name.title)+" ")])],1)]},proxy:true}],null,true)},[(_vm.isShowLimitProducts && item.name.value !== _vm.CURRENT_VIEW_VALUE)?_c('ExportToByCustomViewBlock',{attrs:{"value":_vm.customLimitsByView[item.name.value],"show-tooltip":_vm.showCustomViewsTooltip},on:{"change":function($event){return _vm.changeCustomLimit({
                      value: $event,
                      type: item.name.value
                    })}}}):_vm._e(),_vm._l((item.types),function(typeItem,typeIndex){return _c('v-list-item',{key:typeIndex,class:{ 'item-disabled': typeItem.disabled },attrs:{"disabled":typeItem.disabled}},[[_c('v-tooltip',{attrs:{"disabled":!typeItem.showTooltip,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex"},'div',attrs,false),on),[(typeItem.icon)?_c('v-icon',{style:(typeItem.margin && 'margin-right: 2px;'),attrs:{"color":typeItem.disabled ? 'rgb(187,187,187)' : ''}},[_vm._v(" "+_vm._s(typeItem.icon)+" ")]):_c('img',{staticClass:"type-item-custom-icon",attrs:{"src":typeItem.customIcon,"alt":typeItem.customIcon}}),_c('v-list-item-title',{staticClass:"pointer",on:{"click":function($event){return _vm.exportProjectHandle({
                                name: typeItem.customPdfType || item.name.value,
                                templateName: item.template,
                                fileFormat: typeItem.name,
                                exportType: typeItem.value,
                              })}}},[_vm._v(" "+_vm._s(typeItem.label ? typeItem.label : typeItem.name)+" "),(typeItem.desc)?_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s((" - " + (typeItem.desc)))+" ")]):_vm._e()])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(typeItem.tooltipText))])])]],2)})],2)}),1)],1)],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.NOT_PERMITTED))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }