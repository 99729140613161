var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-wrapper",staticStyle:{"width":"100%"}},[_c('v-combobox',{ref:"menu",staticClass:"custom-combobox border-outline",class:{'disabled-input':!_vm.editMode,'hide-border': !_vm.isExpandedMode},attrs:{"value":_vm.select,"items":_vm.items,"item-text":"name","search-input":_vm.textSearch,"menu-props":{ closeOnContentClick:false,
                   maxWidth: _vm.isExpandedMode ? 'inherit' : '250px',
    },"return-object":"","hide-selected":"","multiple":_vm.multipleSelects,"dense":"","hide-no-data":_vm.isHideNoDataText,"flat":"","deletable-chips":"","outlined":_vm.isExpandedMode,"disabled":_vm.readMode,"append-icon":"","hide-details":"","allow-overflow":"","chips":""},on:{"update:searchInput":function($event){_vm.textSearch=$event},"update:search-input":function($event){_vm.textSearch=$event},"change":_vm.save},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.tradeShowInMobile)?_c('v-list-item',{attrs:{"ripple":false}},[_vm._v(" Click "),_c('v-btn',{staticClass:"custom-combobox__here-btn",attrs:{"color":"lightBlue"},on:{"click":_vm.addNewItemByClickingHereBtn}},[_vm._v(" HERE ")]),_vm._v(" to add item ")],1):_c('v-list-item',{attrs:{"ripple":false}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Press "),_c('v-chip',{attrs:{"x-small":"","color":"mainTurquoise","label":""}},[_vm._v(" enter ")]),_vm._v(" to add item ")],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
    var attrs = ref.attrs;
return [_c('v-chip',_vm._b({staticClass:"ma-1 pointer",attrs:{"ripple":false,"outlined":item.outlined,"color":item.backgroundColor,"text-color":item.textColor}},'v-chip',attrs,false),[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"selection",fn:function(ref){
    var parent = ref.parent;
    var item = ref.item;
    var attrs = ref.attrs;
    var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"ma-1 selection-chip",attrs:{"ripple":false,"outlined":item.outlined,"color":item.backgroundColor,"close-icon":"mdi-close","text-color":item.textColor,"input-value":selected,"close":!_vm.disabledEditMode},on:{"click:close":function($event){_vm.multipleSelects ? parent.selectItem(item) : parent.deleteCurrentItem()}}},'v-chip',attrs,false),[_c('div',{staticClass:"chip-text",style:(Object.assign({}, _vm.header.columnSize && _vm.header.columnSize.size && { 'max-width': _vm.getColumnSize }))},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }