<template>
  <v-tooltip
    top>
    <template
      #activator="{ on }">
      <v-icon
        class="action-icons"
        size="22"
        color="#FBC02D"
        @click="getRowDiffProduct"
        v-on="on">
        mdi-alert
      </v-icon>
    </template>
    <span>Modified</span>
  </v-tooltip>
</template>
<script>
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
import ComparisonApi from '@/services/graphql/comparison';
export default {
  name: 'ProductCellModifiedWarning',
  props: {
    rowId: {
      type: String,
      default: null,
    },
    tableId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentVersion: 'ProjectVersions/getCurrentVersion',
    }),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('ScheduleCompare', [
      'compareVersion',
    ]),
  },
  methods: {
    ...mapActions(['handleError']),
    ...mapMutations({
      spinner: 'spinner',
      setModifiedRow: 'ScheduleCompare/setModifiedRow',
    }),
    async getRowDiffProduct() {
      try {
        const { tableId, rowId, currentVersion, compareVersion, activeWorkspaceId: workspaceId } = this;
        const jsonForRequest = {
          rowVersionId: currentVersion === DEFAULT_PROJECT_VERSION ? '' : currentVersion,
          compareVersion: compareVersion === DEFAULT_PROJECT_VERSION ? '' : compareVersion,
        };
        this.spinner(true);
        const { data } = await ComparisonApi.getRowDiffProduct({
          tableId,
          rowId,
          tableType: 'schedule',
          ...jsonForRequest,
          workspaceId,
        });
        const { fields } = data.response;
        this.setModifiedRow({
          fields,
          rowId,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>