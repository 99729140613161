import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions({
      setCopiedContent: 'ProjectDetailsTableSchedule/setCopiedContent',
      setSelectedCells: 'ProjectDetailsTableSchedule/setSelectedCells',
    }),
    async setMultipleCtrlCells({
      currentRowIndex: index,
      currentRowId: rowId,
      columnName: col,
      value,
    } = {
    }) {
      this.setCopiedContent();
      const newSelectedCell = {
        rowId,
        col,
        index,
        value,
      };
      const filteredCells = this.selectedCells.filter(({ col, rowId }) =>
        newSelectedCell.col === col && newSelectedCell.rowId !== rowId);
      if (filteredCells.length === this.selectedCells.length) {
        this.setSelectedCells([...this.selectedCells, newSelectedCell]);
      } else {
        this.setSelectedCells(filteredCells);
      }
    },
    setMultipleShiftCells({ mappedDataIds, columnName: col } = {
    }) {
      const selectedSellsIndexOrdering = this.selectedCells.map(item => item.index).sort((a, b) => a - b);
      const min = selectedSellsIndexOrdering[0];
      const max = selectedSellsIndexOrdering[selectedSellsIndexOrdering.length - 1];
      const selectedCellsWithShift = mappedDataIds.reduce((result, option, index) => {
        if (index >= min && index <= max) {
          return [...result, {
            rowId: option,
            col,
            index,
            value: this.data.find(e => e.id === option)[col],
          }];
        }
        return result;
      }, []);
      this.setSelectedCells(selectedCellsWithShift);
    },
  },
};
