var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',_vm._b({scopedSlots:_vm._u([{key:"list",fn:function(ref){
  var filterHeadersByFlexItem = ref.filterHeadersByFlexItem;
  var filterNamesGroupItems = ref.filterNamesGroupItems;
  var isRequiredField = ref.isRequiredField;
  var canBeRendered = ref.canBeRendered;
  var isDialogCollection = ref.isDialogCollection;
  var collectionId = ref.collectionId;
  var renderClass = ref.renderClass;
  var searchMode = ref.searchMode;
  var showPensil = ref.showPensil;
  var view = ref.view;
  var disabledEditMode = ref.disabledEditMode;
  var isAsideForm = ref.isAsideForm;
  var isLoading = ref.isLoading;
return [_vm._l((filterHeadersByFlexItem),function(headerItem,i){return _c('WrapperFormRow',{key:((headerItem.value) + "__" + i),attrs:{"item":headerItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var header = ref.header;
  var single = ref.single;
return [(canBeRendered(header , _vm.item))?_c('AppFormRow',{class:{ 'hide-when-aside-shows': _vm.isAsideHeader(header.text) && !isAsideForm },attrs:{"single-item":single,"filter-names-group-items":filterNamesGroupItems,"is-required-field":isRequiredField(header.id),"header-text":header.value,"split-header-and-cell":_vm.isAsideHeader(header.text)},scopedSlots:_vm._u([{key:"rowCell",fn:function(){return [_c('ProductCell',{staticClass:"pa-0",class:renderClass(header),style:({ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }),attrs:{"is-dialog-collection":isDialogCollection,"collection-id":collectionId,"is-loading":_vm.isHeaderLoading(header.text),"view":view,"expanded-row":!showPensil(header.column.type),"should-expand-height":_vm.isAsideHeader(header.text),"header":header,"disabled-edit-mode":_vm.isHeaderDisabledForEditing(disabledEditMode, header),"item":_vm.item,"open-link-in-new-tab":"","mapped-dropdowns-of-view":_vm.mappedDropdownsOfView,"search-mode":searchMode},on:{"updateProductField":function($event){return _vm.$emit('updateProductField', $event)}}})]},proxy:true}],null,true)}):_vm._e()]}}],null,true)})}),(isLoading)?[(!isAsideForm)?_c('ProductCellFormSkeleton'):_c('ProductCellFormAsideSkeleton')]:_vm._e()]}}])},'BaseLayout',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }