<template>
  <div :class="[wrapperClass]">
    <v-row class="ma-0 search-row">
      <v-col
        class="search-col"
        :cols="cols">
        <v-text-field
          v-model="searchText"
          :disabled="disabled"
          :placeholder="placeholder"
          clearable
          hide-details
          solo
          autocomplete="off" />
      </v-col>
    </v-row>
    <div
      ref="searchResults"
      :style="searchItemsHeight"
      :class="[searchWrapperClass]"
      @scroll="onScroll">
      <slot
        name="items"
        v-bind="{ searchItems, searchOn, searchText }" />
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import {
  debounce, throttle,
} from 'lodash';
import { DEBOUNCE_TIME_FOR_SEARCHING } from '@/constants';
export default {
  name: 'AppSearchRowsProvider',
  props: {
    cols: {
      type: String,
      default: '12',
    },
    compareFunction: {
      type: Function,
      default: (items, searchText, compareParam = 'value') => items.filter(item => {
        if (!searchText) {
          return true;
        }
        return item[compareParam].toLowerCase().includes(searchText.toLowerCase());
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Search:',
    },
    searchWrapperClass: {
      type: String,
      default: 'search-items__wrapper',
    },
    searchItemsHeight: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: 'search-component__wrapper',
    },
  },
  data() {
    return {
      searchText: '',
      searchItems: [],
    };
  },
  computed: {
    searchOn() {
      return !!this.searchText;
    },
  },
  watch: {
    searchText: {
      handler: debounce(function () {
        this.searchItems = this.compareFunction(this.items, this.searchText);
      }, DEBOUNCE_TIME_FOR_SEARCHING),
    },
    items: {
      handler() {
        this.searchItems = this.compareFunction(this.items, this.searchText);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setScrollValueInProductDialog: 'setScrollValueInProductDialog',
    }),
    onScroll: throttle(function() {
      this.setScrollValueInProductDialog(true);
    }, DEBOUNCE_TIME_FOR_SEARCHING),
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.search-col {
  padding: 0 10px !important;
}
.search-row {
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  margin-right: 20px !important;
}
.search {
  padding-right: 24px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
}
.search-items {
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
  &__wrapper {
    overflow: auto;
    height: calc(100% - 148px);
    &-base {
      overflow: auto;
      height: calc(100%);
    }
    &-reference {
      overflow: auto;
      height: calc(100% - 118px);
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        height: 100%;
      }
    }
    &-equal {
      overflow: auto;
      height: calc(100% - 68px);
    }
    &-collections {
      overflow: auto;
      height: calc(100vh - 360px);
      @media (min-height: 900px) {
        height: calc(100vh - 400px);
      }
      @media (min-height: 1500px) {
        height: calc(100vh - 440px);
      }
      @media (min-height: 1700px) {
        height: calc(100vh - 460px);
      }
      @media (min-height: 1900px) {
        height: calc(100vh - 480px);
      }
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        height: calc(100vh - 205px);
      }
      &-search {
        overflow: auto;
        height: calc(100vh - 428px);
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          height: calc(100vh - 280px);
        }
      }
    }
  }
}
.search-component__wrapper {
  height: inherit;
  &-equal {
    height: 100%;
  }
  &-equal_fixed {
    height: 100%;
  }
}
</style>
