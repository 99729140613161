<template>
  <fragment>
    <app-dialog
      v-model="searchOpen"
      :value.sync="searchOpen"
      width="1124"
      persistent
      content-class="v-dialog__form"
      :close-on-content-click="false">
      <v-card
        class="pa-0">
        <v-card-title>
          <div class="d-flex align-center">
            Product Search
          </div>
          <v-icon
            color="#fff"
            @click="closeSearchDialog">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <CreateProductSearchHandler
            :value="value"
            :dialog="searchOpen"
            class="mt-4"
            @searchProductCallback="debouncedSearchProductCallback" />
        </v-card-text>
      </v-card>
    </app-dialog>
    <CreateProductSearchResDialog
      :product="selectedProduct"
      :collection-id-for-get-schema="collectionId"
      :show-search-res-dialog="showSearchResDialog"
      :no-modal-for-duplicate-follow="true"
      @dialogClosed="showSearchResDialog = false" />
  </fragment>
</template>
<script>
import CreateProductSearchHandler from '../CreateProductSearchHandler';
import CreateProductSearchResDialog from '@/components/Collections/CollectionsCreateProductDialog/CreateProductSearch/CreateProductSearchResDialog';
import ComparisonApi from '@/services/graphql/comparison';
import {
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';
import { parseGetRows } from '@/utils/manageGetRows';
import {
  EventBus,
  FOLLOWED_PRODUCT_IN_CURR_COLL,
  DUPLICATED_PRODUCT_IN_CURR_COLL,
} from '@/utils/eventBus';
import { debounce } from 'lodash';
const DEBOUNCE_SEARCH_CALLBACK_TIME = 300;

export default {
  name: 'ProductCellHeaderSearchDialog',
  components: {
    CreateProductSearchHandler,
    CreateProductSearchResDialog,
  },
  props: {
    collectionId: {
      type: String,
      default: null,
    },
    searchOpen: {
      type: Boolean,
      default: false,
    },
    value: {
      type: undefined,
      default: null,
    },
  },
  data() {
    return {
      showSearchResDialog: false,
      selectedProduct: null,
      selectedProductCollectionName: '',
      selectedProductCollectionLink: '',
      // Timestamp to prevent race conditions + cancel opening res dialog on close
      latestSearchCbTimestamp: 0,

    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
  },
  mounted() {
    EventBus.$on(FOLLOWED_PRODUCT_IN_CURR_COLL, () => {
      this.closeSearchDialog();
    });
    EventBus.$on(DUPLICATED_PRODUCT_IN_CURR_COLL, () => {
      this.closeSearchDialog();
    });
  },
  destroyed() {
    EventBus.$off(FOLLOWED_PRODUCT_IN_CURR_COLL);
    EventBus.$off(DUPLICATED_PRODUCT_IN_CURR_COLL);
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['handleError']),

    debouncedSearchProductCallback: debounce(function(product) {
      this.searchProductCallback(product);
    }, DEBOUNCE_SEARCH_CALLBACK_TIME),

    async searchProductCallback(product) {
      if (!product) {
        console.error('Selected empty product?!');
        return;
      }
      try {
        this.spinner(true);
        this.clearSearchResultProduct();

        const collId = product.collection ?? '';
        const prodId = product.id ?? '';
        const timestamp = Date.now();
        this.latestSearchCbTimestamp = timestamp;

        const { data } = await ComparisonApi.getRowDiffProduct({
          tableId: collId,
          tableType: 'collection',
          rowId: prodId,
          rowVersionId: '',
          compareVersion: '',
          workspaceId: this.activeWorkspaceId,
        });

        if (this.latestSearchCbTimestamp !== timestamp) return;

        const fields = data.response.fields;
        const parsedData = parseGetRows(fields, null, true);

        this.selectedProduct = {
          ...parsedData,
          mode: '',
          SK: `ROW#${prodId}`,
          id: prodId,
          collectionId: collId,
          editable: false,
        };
        this.selectedProductCollectionName = product.collectionName;
        this.selectedProductcollectionLink = {
          name: 'collection-library',
          params: {
            id: product.collection,
          },
        };
        this.showSearchResultDialog();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    showSearchResultDialog() {
      this.showSearchResDialog = true;
    },
    closeSearchResultDialog() {
      this.clearSearchResultProduct();
      this.showSearchResDialog = false;
    },
    closeSearchDialog() {
      this.$emit('searchDialogClosed');
      this.closeSearchResultDialog();
    },
    clearSearchResultProduct() {
      this.selectedProduct = null;
      this.selectedProductCollectionName = '';
      this.selectedProductCollectionLink = '';
      this.latestSearchCbTimestamp = 0;
    },
  },
};
</script>
<style scoped lang="scss">
.v-dialog {
  overflow: hidden;
  .v-card {
    &__text {
      padding: 0 24px !important;
    }
  }
}
</style>
