<template>
  <div>
    <app-dialog
      v-model="isModalOpen"
      :value.sync="isModalOpen"
      width="1450px"
      content-class="v-dialog__form attachments-documents-wrapper">
      <project-list-documents
        :id-file-upload="idFileUpload"
        :document-type="documentType"
        :can-work-with-project-documents="canModifyProjectDocuments"
        :is-modal-open="isModalOpen"
        @closeDialog="closeDialog" />
    </app-dialog>
    <app-action-btn
      v-if="!hideBtn"
      bottom
      show-tooltip
      :access="canModifyProjectDocuments"
      @click="openDocument">
      <v-btn
        outlined
        rounded
        color="borderGrey"
        class="select-view-container__edit-button mr-2"
        style="border-radius: 50px !important; height: 29px !important;">
        <span class="buttonText">
          <v-icon
            size="20"
            color="rgba(0, 0, 0, 0.45)">
            mdi-folder
          </v-icon>
          Project Documents
        </span>
      </v-btn>
    </app-action-btn>
  </div>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';
import ProjectListDocuments from '@/components/CollectionDetails/ProjectListDocuments';
import { TYPE_DOCUMENTS } from '@/constants/projectDocument';
export default {
  name: 'ProjectDetailsDocuments',
  components: {
    ProjectListDocuments,
  },
  props: {
    idFileUpload: {
      type: String,
      default: '',
    },
    hideBtn: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: String,
      default: TYPE_DOCUMENTS[0].type,
    },
  },
  data: function () {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState(['role']),
    ...mapGetters('UserRoles', ['canModifyProjectDocuments']),
  },
  watch: {
    isModalOpen(val) {
      if (!val) {
        this.$emit('getDocuments');
        this.$emit('clearAllItems');
      }
    },
  },
  methods: {
    openDocument() {
      if (!this.canModifyProjectDocuments.allowed) {
        return false;
      }
      this.openDialog();
    },
    openDialog() {
      this.isModalOpen = true;
    },
    closeDialog() {
      this.isModalOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .collections-photos-icon {
  display: flex;
  cursor: pointer;
  align-items: center;
  p {
    margin-bottom: 0px !important;
  }
}
.v-card__text {
  max-height: 300px;
  overflow: auto;
}
</style>
