<template>
  <div v-if="alertStatus">
    <div
      class="border-item"
      :style="`background-color: ${alertOptions.color}`" />
    <v-tooltip
      v-if="alertOptions.showTooltip"
      top>
      <template #activator="{ on }">
        <v-icon
          class="show-warning-block"
          size="22"
          :color="alertOptions.color"
          v-on="on">
          mdi-alert
        </v-icon>
      </template>
      <span>{{ alertOptions.title }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  ADDED, DELETED, MODIFIED,
} from '@/constants';
export default {
  name: 'TableActionsCompareModified',
  props: {
    alertStatus: {
      type: String,
      validator(value) {
        return [ADDED, DELETED, MODIFIED].includes(value);
      },
      default: '',
    },
  },
  computed: {
    alertOptions() {
      const { alertStatus: status } = this;
      if (!status) return {
      };
      const ALERTS = [
        {
          title: 'Added',
          color: '#64B5F6',
          value: ADDED,
          showTooltip: true,
        },
        {
          title: 'Deleted',
          color: '#EF5350',
          value: DELETED,
          showTooltip: true,
        },
        {
          color: 'rgb(251, 192, 45)',
          value: MODIFIED,
          showTooltip: false,
        },
      ];
      return ALERTS.find(({ value } ) => value === status);
    },
  },
};
</script>
<style scoped lang="scss">
.border-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
}
.show-warning-block {
  position: absolute;
  top: 0px;
  right: 0;
  cursor: pointer;
  &__bottom {
    bottom: 0;
    top: inherit;
  }
}
</style>
