<template>
  <v-badge
    :content="unReadCommentsLength"
    class="pointer"
    :value="unReadCommentsLength > 0">
    <span
      class="comments-container"
      @click="$emit('click')">
      <img
        v-if="$route.name !== 'collection-library' && isCellBadge"
        src="@/assets/icons/comments-icon.svg"
        class="comments-icon"
        :class="{'comments-icon__clicked-comment': clickedComment}">
      <span
        v-if="Number(allCommentsLength) > 0"
        class="comments-container__text">
        {{ allCommentsLength }}
      </span>
    </span>
  </v-badge>
</template>
<script>
import {
  TYPE_CELL_BADGE,
  TYPE_ROW_BADGE,
} from '@/constants';
export default {
  name: 'ProjectDetailsCommentBadge',
  props: {
    item: {
      type: Object,
      default: null,
    },
    badgeType: {
      type: String,
      default: TYPE_CELL_BADGE,
      validator(value) {
        return [TYPE_CELL_BADGE, TYPE_ROW_BADGE].includes(value);
      },
    },
  },
  computed: {
    clickedComment() {
      const { item, allCommentsLength, isCellBadge } = this;
      return this.$route.query.rowId === item.id || Number(allCommentsLength) && isCellBadge;
    },
    slant() {
      if (this.isRowBadge) {
        return '';
      }
      const { item } = this;
      return this.$store.getters['Comments/slant'](item.id);
    },
    isCellBadge() {
      return this.badgeType == TYPE_CELL_BADGE;
    },
    isRowBadge() {
      return this.badgeType == TYPE_ROW_BADGE;
    },
    unReadCommentsLength() {
      const { item, slant, isRowBadge } = this;
      if (isRowBadge) {
        return item.commentsUnread;
      }
      if (item?.resolvedComments) {
        return item.unresolved;
      }
      return slant?.commentsUnread;
    },
    allCommentsLength() {
      const { item, isCellBadge } = this;
      return isCellBadge
        ? item.commentsAmount
        : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.comments-container {
  position: relative;
  &__text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 94.69%;
    letter-spacing: -0.085em;
    position: absolute;
    color: #ABAAAA;
    top: 3px;
    left: 0;
    text-align: center;
    width: 100%;
  }
}
.v-badge__expanded {
  .comments-container__text {
    top: -3px;
  }
  &.row-badge {
    position: absolute;
    top: 0;
    right: 25px;
  }
}
::v-deep .v-badge {
  &__wrapper {
    top: 7px;
  }
}
</style>
