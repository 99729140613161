var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-block"},[_c('v-tooltip',{attrs:{"disabled":Boolean(_vm.userId),"content-class":"btn-tooltip","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-menu',{attrs:{"offset-y":"","disabled":!Boolean(_vm.userId),"rounded":"70","content-class":"edit-menu","close-on-content-click":false,"nudge-bottom":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"disabled":_vm.showTitle === 'Compare' && _vm.showTitle.length < 10,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"select-view-container__edit-button mr-2 customButtonStyle",attrs:{"outlined":"","disabled":!Boolean(_vm.userId),"color":"borderGrey"}},Object.assign({}, onMenu, onTooltip)),[_c('span',{staticClass:"buttonText"},[_c('v-icon',{attrs:{"size":"20","color":"rgba(0, 0, 0, 0.45)"}},[_vm._v(" mdi-compare ")]),_vm._v(" "+_vm._s(_vm.showTitle)+" ")],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.showTitle))])])]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.changeMenu)?_c('ChooseVersions',{on:{"closeMenu":_vm.closeMenu,"changeModal":_vm.changeModal}}):_c('ChooseColumns',{attrs:{"menu":_vm.menu},on:{"closeMenu":_vm.closeMenu,"changeModal":_vm.changeModal}})],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.NOT_PERMITTED_ACCESS))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }