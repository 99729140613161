<template>
  <div class="add-product-modal">
    <app-dialog
      v-model="showSearchResDialog"
      :width="1600"
      content-class="v-dialog__form v-dialog__product-expanded-view"
      persistent
      @keydown.esc="closeDialog">
      <div class="dialog-content">
        <v-card v-if="showSearchResDialog">
          <v-card-title>
            <div class="d-flex align-center">
              <span>Product Details</span>
            </div>
            <v-icon
              color="#fff"
              @click="closeDialog">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text class="items-wrapper">
            <div class="items h-100vh">
              <AppFormRow
                v-if="productCollectionName"
                class="flex-grow-0"
                header-text="Collection">
                <template #rowCell>
                  <a @click="goNextPageBlank">
                    {{ productCollectionName }}
                  </a>
                </template>
              </AppFormRow>
              <div class="product-details-flex-wrapper">
                <app-search-rows-provider
                  ref="searchWrapper"
                  placeholder="Search by field name"
                  cols="11"
                  :search-wrapper-class="productCollectionName
                    ? 'search-items__wrapper-collections-search'
                    : 'search-items__wrapper-collections'"
                  :items="renderHeaders"
                  :search-items-height="searchItemsHeight"
                  @hook:mounted="mountedappSearchRowsProvider">
                  <template #items="{ searchItems, searchOn }">
                    <component
                      :is="getProductComponent"
                      :is-loading="isFetchingAny"
                      :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
                      :is-dialog-collection="true"
                      :collection-id="collectionIdForViewSchema"
                      :headers="searchItems"
                      :item="item"
                      :field-errors="fieldErrors"
                      :search-mode="searchOn"
                      :mapped-dropdowns-of-view="getMappedDropdownsOfView"
                      view="productCreate" />
                  </template>
                </app-search-rows-provider>
                <div
                  v-if="moveTipsAndTricks"
                  class="product-details-aside-wrapper"
                  :style="searchItemsWithBarHeight">
                  <component
                    :is="getProductComponent"
                    :is-loading="isFetchingAny"
                    :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
                    :is-dialog-collection="true"
                    :collection-id="collectionIdForViewSchema"
                    :headers="renderAsideHeaders"
                    :item="item"
                    :field-errors="fieldErrors"
                    :search-mode="false"
                    :mapped-dropdowns-of-view="getMappedDropdownsOfView"
                    :is-aside-form="true"
                    view="productCreate" />
                </div>
              </div>
            </div>
          </v-card-text>
          <ProductSearchResDialogActions
            ref="dialogActions"
            :user-role-in-collection="userRoleInCollection"
            :item="item"
            :product-id="productId"
            :followed-collection-id="followedCollectionId"
            :action-collection-id="collectionIdForGetSchema" />
        </v-card>
      </div>
    </app-dialog>
  </div>
</template>
<script>
import AppFormRow from '@/components/App/AppFormRow';
import ProductCellFormList from '@/components/Product/ProductCell/ProductCellCollectionsFormList';
import { DEBOUNCE_TIME_TO_RESIZE_EVENTS } from '@/constants';
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import AppSearchRowsProvider from '@/components/App/AppSearchRowsProvider';
import ProductSearchResDialogActions from './ProductSearchResDialogActions/index.vue';
import {
  isAsideHeader,
  sortAsideHeadersLast,
} from '@/utils/productHeaders';
import {
  setCollectionSchema,
  setCollectionSchemaMapping,
  setSelectedCollectionView,
  setMappedDropdownsOfView,
} from '@/utils/collectionSchemaAndViews';
import { debounce } from 'lodash';
import { COLLECTION } from '@/constants/cores';
import { DEFAULT_PRODUCT_REQUIRED_FIELDS } from '@/constants/productHeaders';
import API from '@/services/graphql';
import CollectionsApi from '@/services/graphql/collections';
export default {
  name: 'CreateProductSearchResDialog',
  components: {
    ProductCellFormList,
    AppFormRow,
    AppSearchRowsProvider,
    ProductSearchResDialogActions,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    collectionIdForGetSchema: {
      type: String,
      default: '',
    },
    productCollectionName: {
      type: String,
      default: '',
    },
    productCollectionLink: {
      type: String,
      default: '',
    },
    propLibraryId: {
      type: String,
      default: '',
    },
    autoHideUnpopulatedFields: {
      type: Boolean,
      default: false,
    },
    noModalForDuplicateFollow: {
      type: Boolean,
      default: false,
    },
    noModalCollectionId: {
      type: String,
      default: null,
    },
    showSearchResDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      rowData: {
      },
      fieldErrors: [],
      productId: null,
      isFollowedProduct: false,
      userRoleInCollection: null,
      collectionsSchemaV2: {
      },
      selectedCollectionView: {
      },
      searchItemsHeight: '',
      searchItemsWithBarHeight: '',
      isFetching: {
        initialOpening: true,
        collectionViews: false,
        collectionSchema: false,
      },
    };
  },
  computed: {
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    ...mapState(['showSpinner', 'activeHeader', 'isMobile']),
    ...mapState({
      generalAccessToken: state => state.accessToken,
    }),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', [
      'useLightfairRequiredFields',
      'moveTipsAndTricks',
      'useLazyLoading',
    ]),
    getMappedDropdownsOfView() {
      const { dropdowns } = this.selectedCollectionView || {
      };
      return setMappedDropdownsOfView(dropdowns);
    },
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    getProductComponent() {
      return 'ProductCellFormList';
    },
    collectionIdForViewSchema() {
      return this.collectionIdForGetSchema || this.getDefaultLibraryId;
    },
    renderHeaders() {
      return [...this.schema];
    },
    renderAsideHeaders() {
      return this.schema.filter(isAsideHeader);
    },
    item: {
      get() {
        return this.rowData;
      },
      set(value) {
        this.$set(this.rowData, value.col, value.value);
      },
    },
    schema() {
      if (!this.collectionsSchemaV2.schema) return [];
      return this.collectionsSchemaV2.schema.toSorted(sortAsideHeadersLast);
    },
    requiredFieldsAccordingToResolution() {
      const { requiredFields } = this.schema || {
      };
      if (!this.useLightfairRequiredFields || this.isMobile) {
        return requiredFields;
      }
      return DEFAULT_PRODUCT_REQUIRED_FIELDS;
    },
    formattedRequiredFields() {
      return this.requiredFieldsAccordingToResolution.map(el => {
        const item = this.schema.find((shemaObj) => shemaObj.id === el);
        return item.value;
      });
    },
    followedCollectionId() {
      const collectionId = this.item?.collectionId || this.rowItem?.collectionId;
      return collectionId || this.$route.params.id;
    },
    isFetchingAny() {
      if (!this.useLazyLoading) return;

      return this.isFetching.initialOpening
        || this.isFetching.collectionViews
        || this.isFetching.collectionSchema;
    },
  },
  watch: {
    product(val) {
      if (val) {
        this.setProductId(val);
      }
    },
    async showSearchResDialog(value) {
      if (value) {
        this.initRowData();
        this.showProductModalInCollections();
        await this.getUserRoleInCollection();
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.getSearchResultHeight);
  },
  methods: {
    ...mapMutations(['spinner']),
    mountedappSearchRowsProvider() {
      window.addEventListener('resize', this.getSearchResultHeight);
    },
    ...mapActions({
      getCustomLibraryCollectionsList: 'Collections/getCustomLibraryCollectionsList',
    }),
    /**
      * Calculates the height of the search results based on the current viewport size and element offsets.
      */
    getSearchResultHeight: debounce(function () {
      const { $refs: refs } = this;
      if (!refs) {
        this.resetSearchResultHeight();
        return;
      }
      const { dialogActions, searchWrapper } = refs || {
      };
      const { $el: el } = dialogActions || {
      };
      if (!el) {
        this.resetSearchResultHeight();
        return;
      }
      const { clientHeight } = el || {
      };
      const { searchResults } = searchWrapper?.$refs || {
      };
      if (!searchResults) {
        this.resetSearchResultHeight();
        return;
      }
      const { offsetTop } = searchResults || {
      };
      const { innerHeight } = window || {
      };
      // Determine the maximum height for the search results
      // based on the current viewport height
      const MAX_HEIGHT_COEFFICIENT = 0.9; // 90% of the viewport height for mdAndUp breakpoint
      // Calculate the available height for the search results
      const calcInnerHeight = this.$vuetify.breakpoint.smAndDown ? innerHeight : MAX_HEIGHT_COEFFICIENT * innerHeight;
      // Calculate the height of the search items based on the available space
      const height = calcInnerHeight - (offsetTop + clientHeight);
      // Set the height of the search items CSS property
      this.searchItemsHeight = height <= 0 ? '' : `height: ${height}px`;
      this.searchItemsWithBarHeight = height <= 0 ? '' : `height: ${height + 55}px`;
    }, DEBOUNCE_TIME_TO_RESIZE_EVENTS),
    resetSearchResultHeight() {
      this.searchItemsHeight = '';
    },
    async  getUserRoleInCollection() {
      const { collectionId: resourceId } = this.item || {
      };
      if (!resourceId) return;
      try {
        const { data } = await API.getMyRoleInResource({
          workspaceId: this.activeWorkspaceId,
          resourceType: COLLECTION,
          resourceId,
        });
        this.userRoleInCollection = data.response;
      } catch (err) {
        console.error(err);
      }
    },
    goNextPageBlank() {
      const routeData = this.$router.resolve(this.productCollectionLink);
      window.open(routeData.href, '_blank');
    },
    async initRowData() {
      if (this.product) {
        this.rowData = this.product;
      } else {
        this.schema.forEach((item) => {
          this.$set(this.rowData, item.text, this.getInitialValue(item.column.type));
        });
      }
    },
    async getSchemaAndViewsToCollection(data) {
      if (!data) return;
      if (!this.useLazyLoading) this.spinner(true);
      await this.getCollectionsViews(data);
      await this.getCollectionsSchema(data);
      this.getSearchResultHeight();
    },
    async getCollectionsViews(values) {
      const { propLibraryId: libraryId } = this;
      const data = {
        ...values,
        ...libraryId && {
          libraryId,
        },
      };
      try {
        this.isFetching.collectionViews = true;
        await this.getCollectionsViewsV2(data);
      } finally {
        this.isFetching.collectionViews = false;
      }
    },
    async getCollectionsSchema(values) {
      const { propLibraryId: libraryId } = this;
      const data = {
        ...values,
        ...libraryId && {
          libraryId,
        },
      };
      try {
        this.isFetching.collectionSchema = true;
        await this.getCollectionsSchemaV2(data);
      } finally {
        this.isFetching.collectionSchema = false;
      }
    },
    async getCollectionsViewsV2(values = {
    }) {
      try {
        const { generalAccessToken: accessToken, activeWorkspaceId: workspaceId } = this;
        const { data } = await CollectionsApi.collectionsViews({
          ...values,
          workspaceId,
          ...(accessToken && {
            accessToken,
          }),
        });
        const { response: views } = data;
        this.selectedCollectionView = setSelectedCollectionView(views);
      } catch (err) {
        console.log('getCollectionsViews err', err);
      }
    },
    async getCollectionsSchemaV2(values = {
    }) {
      try {
        const { generalAccessToken: accessToken, activeWorkspaceId: workspaceId } = this;
        const { data } = await CollectionsApi.collectionsSchema({
          ...values,
          workspaceId,
          ...(accessToken && {
            accessToken,
          }),
        });
        const { response: collectionsSchema } = data || {
        };
        const { schema } = collectionsSchema || {
        };
        this.collectionsSchemaV2 = setCollectionSchema({
          selectedCollectionView: this.selectedCollectionView,
          collectionsSchemaMapping: setCollectionSchemaMapping(schema),
          collectionsSchema,
        });
      } catch (err) {
        console.log('getCollectionsSchema err', err);
      }
    },
    async getLibraryCollectionList() {
      const { propLibraryId: libraryId, activeWorkspaceId: workspaceId } = this;
      const isAutocomplete = true;
      await this.getCustomLibraryCollectionsList({
        libraryId,
        workspaceId,
        isAutocomplete,
      });
    },
    async showProductModalInCollections() {
      if (!this.useLazyLoading) this.spinner(true);
      this.getSchemaAndViewsToCollection({
        collectionId: this.collectionIdForViewSchema,
      });
      this.isFetching.initialOpening = false;
    },
    closeDialog() {
      this.$emit('dialogClosed');
      this.onCloseDialog();
    },
    async onCloseDialog() {
      this.collectionsSchemaV2 = {
      };
      this.isFetching.initialOpening = true;
    },
    checkValidateErrors() {
      let errors = [];
      this.formattedRequiredFields.forEach((f) => {
        if (!this.item[f] || !this.item[f].length) {
          errors.push(f);
        }
      });
      this.fieldErrors = errors;
    },
    showPensil(type) {
      return ['DATE', 'ARRAY_AIRTABLE_IMAGE', 'COMBOBOX', 'DROPDOWN'].includes(type);
    },
    setProductId(val) {
      const SK = val.SK;
      if (!SK || !SK.includes('ROW#')) {
        this.productId = '';
        return;
      }
      this.productId = SK.split('ROW#')[1];
    },
    getInitialValue(item) {
      return item.includes('ARRAY') ? [] : '';
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.add-product-modal {
  margin-left: auto;
}
.items-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-height: 660px;
}
::v-deep .item-wrapper {
  margin-top: 20px;
  height: auto;
}
.items {
  display: flex;
  flex-direction: column;
}
::v-deep .v-application p {
  margin-bottom: 0px !important;
}
.required-field {
  color: red;
}
::v-deep.v-btn {
  width: 154px;
}
.product-details-flex-wrapper {
  display: flex;
  column-gap: 20px;
  width: 100%;
  div:first-child {
    flex-grow: 3;
    flex-basis: 0;
  }
}
.product-details-aside-wrapper {
  padding: 0 4px;
  overflow: auto;
  height: 100%;
  flex-grow: 2;
  flex-basis: 0;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    // The aside items show at the bottom of the original now.
    display: none;
  }
}
</style>
