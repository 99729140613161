<template>
  <div
    class="expand-icon__wrapper pb-3">
    <v-icon
      class="expand-icon"
      small
      @click="showExpandedDialog">
      mdi-arrow-expand
    </v-icon>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { TYPE_READONLY } from '@/constants';
export default {
  name: 'ProductCellExpandedView',
  methods: {
    ...mapMutations('Collections', ['changeProductModalVariant']),
    showExpandedDialog() {
      // reset the product modal view
      this.changeProductModalVariant(TYPE_READONLY);
      this.$emit('showExpandedDialog');
    },
  },
};
</script>
<style scoped lang="scss">
.expand-icon__wrapper {
  .expand-icon {
    display: inline-flex !important;
  }
}
</style>
