<template>
  <div>
    <slot
      name="list"
      :filterHeadersByFlexItem="filterHeadersByFlexItem"
      :filterNamesGroupItems="filterNamesGroupItems"
      :isRequiredField="isRequiredField"
      :canBeRendered="canBeRendered"
      :isDialogCollection="isDialogCollection"
      :collectionId="collectionId"
      :renderClass="renderClass"
      :showPensil="showPensil"
      :view="view"
      :disabledEditMode="disabledEditMode"
      :searchMode="searchMode"
      :isAsideForm="isAsideForm"
      :isLoading="isLoading" />
  </div>
</template>
<script>
import DisableEditMode from '@/mixins/DisableEditMode';
import {
  mapGetters,
  mapState,
} from 'vuex';
import { filterHeadersGroup } from '@/utils';
import { DEFAULT_PRODUCT_REQUIRED_FIELDS } from '@/constants/productHeaders';
export default {
  name: 'ProductCellCollectionsFormListBaseLayout',
  mixins: [DisableEditMode],
  props: {
    isDialogCollection: {
      type: Boolean,
      default: true,
    },
    collectionId: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    fieldErrors: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
      default: 'productCreate',
    },
    isProductPreview: {
      type: Boolean,
      default: false,
    },
    searchMode: {
      type: Boolean,
      default: false,
    },
    autoHideUnpopulatedFields: {
      type: Boolean,
      default: false,
    },
    isAsideForm: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('FeatureFlags', ['useLightfairRequiredFields', 'moveTipsAndTricks']),
    ...mapGetters('Collections', [
      'isReadOnlyModalVariant',
      'isSaveAsNewVariant',
      'isAddModalVariant',
      'collectionsSchema',
    ]),
    ...mapState(['showSpinner', 'isMobile']),
    filterNamesGroupItems() {
      return this.groupItems.reduce((result, { items }) => {
        return [...result, ...items];
      }, []);
    },
    groupItems() {
      return [{
        items: ['Image', 'Line Drawing'],
      }];
    },
    filterHeadersByFlexItem() {
      const { headers, groupItems } = this;
      return filterHeadersGroup({
        headers,
        groupItems,
        disabled: false,
      });
    },
  },
  methods: {
    showPensil(type) {
      return ['DATE', 'ARRAY_AIRTABLE_IMAGE', 'COMBOBOX', 'DROPDOWN'].includes(type);
    },
    isRequiredField(id) {
      if (!this.useLightfairRequiredFields) {
        return this.collectionsSchema?.requiredFields?.includes(id);
      }
      if (this.isMobile) {
        return this.collectionsSchema?.requiredFields?.includes(id);
      } else {
        return DEFAULT_PRODUCT_REQUIRED_FIELDS.includes(id);
      }
    },
    renderClass(header) {
      return this.fieldErrors.includes(header.value) ? 'field-error' : '';
    },
    canBeRendered(header, item) {
      if (header.value == 'Choose Collection') {
        return this.isSaveAsNewVariant || this.isAddModalVariant;
      }
      const { autoHideUnpopulatedFields: autoHide, filterUnpopulatedFields } = this;
      if (autoHide) {
        return filterUnpopulatedFields(item, header);
      }
      return true;
    },
    filterUnpopulatedFields(item, header) {
      const { value: headerVal } = header || {
      };
      if (!headerVal) return false;
      const itemVal = item[headerVal] || '';
      if (!itemVal) return false;
      return this.isNotEmpty(itemVal);
    },
    isNotEmpty(value) {
      if (Array.isArray(value)) {
        return !!value.length;
      }
      if (typeof value === 'object') {
        return !!Object.keys(value).length;
      }
      if (typeof value === 'string') {
        return !!value.trim().length;
      }
    },
  },
};
</script>
