<template>
  <th
    :data-ref="`${headerName}__ref`"
    :data-index="index"
    role="columnheader"
    :class="[{ 'v-data-table-header__draggable': index > 2 && !isFocusedOnActions }, isActiveResizable && 'resizable-element__header', thClass]"
    :style="headerName != 'order' && {
      width: `${headerSize}px`,
    }"
    scope="col"
    aria-label
    @click="onSort"
    @mouseleave="onMouseLeave(headerName)"
    @mouseover="onMouseOver(headerName)">
    <v-tooltip
      top
      :disabled="!deprecated">
      <template #activator="{ on }">
        <div
          :style="{'margin-left': renderedDraggableRef ? '0' : '-16px'}"
          class="v-data-table-header__wrapper"
          v-on="deprecated && on">
          <vue-draggable-resizable
            v-if="isAllowedToResize"
            class-name-handle="resizable-handle"
            :w="headerSize"
            :h="48"
            :min-width="headerMinSize"
            :axis="'x'"
            :handles="['mr']"
            :on-resize="onResizeCallback"
            :resizable="canWorkWithResizeColumn && isResizable && !disabledEditMode"
            :active="isResizable"
            :draggable="false"
            class="d-flex align-center resizable-element"
            :class="{
              'table-container__header-cell': activeCellHeader === headerName,
              'table-container__header-cell--hover': getActiveCellsNames.includes(headerName),
              'table-container__header-cell--hover-active': getActiveCellsNames.includes(headerName) && activeCellHeader === headerName,
            }"
            @resizing="onResizeColumn({ width: arguments[2], colName: headerName, name: headerName, })"
            @resizestop="onResizeColumnStop(
              { width: arguments[2],
                colId: columnId, resourceType: 'schedule',
                projectId, resourceId: scheduleId, viewId: selectedView.id, colName: headerName, isCustomSortable: isCustomSortable})">
            <!-- handles prop name -->
            <template #mr>
              <div
                class="resizable-handle-element"
                @mouseover.stop="isCanceledResizable ? setActiveColumn(headerName): null"
                @mouseleave.stop="setHoveredColumn(isCanceledResizable ? { name: headerName } : { })">
                <div
                  :class="{ 'resizable-handle-element__selected-border': isActiveResizable }" />
                <div
                  v-show="isActiveResizable"
                  :style="{height: `${tableHeight - 48}px`}"
                  class="resizable-handle-element__selected-border__bottom" />
              </div>
            </template>
            <template
              v-if="headerName == 'Tag'">
              <TableFilterTags
                :disabled-edit-mode="disabledEditMode"
                :is-free-workspace="isFreeWorkspace"
                :value="headerName" />
              <app-sort-arrows
                v-if="isCustomSortable && !renderedDraggableRef"
                :sort-by="headerName"
                :sorting-data="sortingData"
                :sortable-options="SORTABLE_OPTIONS"
                @mouse-action="$emit('focused-on-actions', true)"
                @mouse-action-end="$emit('focused-on-actions', false)" />
            </template>
            <template v-else>
              <span>
                {{ headerName }}
                <app-sort-arrows
                  v-if="isCustomSortable
                    && !renderedDraggableRef"
                  :sort-by="headerName"
                  :sorting-data="sortingData"
                  :sortable-options="SORTABLE_OPTIONS"
                  @mouse-action="$emit('focused-on-actions', true)"
                  @mouse-action-end="$emit('focused-on-actions', false)" />
              </span>
            </template>
          </vue-draggable-resizable>
          <template v-else-if="headerName!=='order'">
            <div
              :class="{
                'table-container__header-cell': activeCellHeader === headerName,
                'table-container__header-cell--hover': getActiveCellsNames.includes(headerName),
                'table-container__header-cell--hover-active': getActiveCellsNames.includes(headerName) && activeCellHeader === headerName,
              }"
              class="d-flex align-center table-header__not-resizable">
              <span>{{ headerName }}</span>
              <app-sort-arrows
                v-if="isCustomSortable
                  && !renderedDraggableRef"
                :sort-by="headerName"
                :sorting-data="sortingData"
                :sortable-options="SORTABLE_OPTIONS"
                @mouse-action="$emit('focused-on-actions', true)"
                @mouse-action-end="$emit('focused-on-actions', false)" />
            </div>
          </template>
          <table-header-actions
            v-if="isCustomSortable
              && !renderedDraggableRef
              && !(selectedViewTag && headerName == productHeaders.TAG)"
            :sort-by="headerName"
            :sorting-data="sortingData"
            :default-sort-by="selectedView.sortingField"
            @sort="setViewsSorting({sortingData: sortHelper(selectedViewId, $event.sortBy, $event.sortOrder)})" />
        </div>
      </template>
      <span>Deprecated</span>
    </v-tooltip>
  </th>
</template>
<script>
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import ResizableMixin from '@/mixins/ResizableMixin';
import tableSortingMixin from '@/mixins/tableSortingMixin';
import activeCellsMixin from '@/mixins/activeCellsMixin';
import VueDraggableResizable from 'vue-draggable-resizable';
import { hasEditorAccess } from '@/utils';
import TableFilterTags
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableFilterTags';
import AppSortArrows from '@/components/App/AppSortArrows';
import tableHeaderActions
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableHeaderActions';
import {
  mapGetters, mapState,
} from 'vuex';
import { SORT_TOP } from '@/constants/scheduleViews';
export default {
  name: 'TableHeaderItem',
  components: {
    VueDraggableResizable,
    TableFilterTags,
    AppSortArrows,
    tableHeaderActions,
  },
  mixins: [ResizableMixin, tableSortingMixin, activeCellsMixin],
  props: {
    projectId: {
      type: String,
      default: '',
    },
    renderedDraggableRef: {
      type: Object,
      default: null,
    },
    isFocusedOnActions: {
      type: Boolean,
      default: false,
    },
    isAllowedToResize: {
      type: Boolean,
      default: false,
    },
    deprecated: {
      type: Boolean,
      default: false,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: [Number],
      default: 0,
    },
    isCustomSortable: {
      type: Boolean,
      default: false,
    },
    columnId: {
      type: String,
      default: '',
    },
    headerName: {
      type: String,
      default: '',
    },
    thClass: {
      type: String,
      default: '',
    },
    headerSize: {
      type: Number,
      default: 0,
    },
    headerMinSize: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
    };
  },
  computed: {
    ...mapGetters('Workspace', ['isFreeWorkspace']),
    ...mapState('ScheduleViews', ['selectedViewId']),
    ...mapState('ProjectDetailsTableSchedule', [
      'scheduleId',
    ]),
    ...mapGetters('ScheduleViews', ['selectedViewTag', 'isSortingTop', 'isSortingBottom', 'selectedView']),
  },
  methods: {
    ...hasEditorAccess,
    onSort() {
      if (this.isActiveResizable) {
        return;
      }
      this.isCustomSortable
      && !this.renderedDraggableRef
        ? this.setViewsSorting({
          sortingData: this.sortHelper(this.selectedViewId, this.sortingData.sortBy !== this.headerName ? this.headerName : this.selectedView.sortingField,
            SORT_TOP),
        })
        : () => {};
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/resizableElement.scss";
$accent-color: rgba(#71d6e0, .2);
$accent-color-header: #DEEFF1;
$grey-color: rgba(#757575, .1);
::v-deep {
  // Changes background of header cell
  .table-container__header-cell {
    background: $grey-color;
    &--hover {
      background: $accent-color;
    }
    &--hover-active {
      background: $accent-color-header;
    }
  }
}
.table-header__not-resizable {
  padding-left: 16px;
  height: 100%;
}
</style>
