<template>
  <BaseLayout
    v-bind="$attrs">
    <template
      #list="{
      filterHeadersByFlexItem,
      filterNamesGroupItems,
      isRequiredField,
      canBeRendered,
      isDialogCollection,
      collectionId,
      renderClass,
      searchMode,
      showPensil,
      view,
      disabledEditMode,
    }">
      <v-row
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mx-0 my-4 title-of-product">
        <v-col
          v-for="product in item"
          :key="product.title"
          style="padding-left: 10px"
          :md="12 / productsLength"
          cols="12">
          <h2 class="title-of-product__title">
            {{ product.title }}
          </h2>
        </v-col>
      </v-row>
      <wrapper-form-row
        v-for="(headerItem, i) in mappedFilterHeadersByFlexItem(filterHeadersByFlexItem)"
        :key="`${headerItem.value}__${i}`"
        :render-additional-cell="!Array.isArray(headerItem)"
        :item="headerItem">
        <template
          #default="{ header: head, single }">
          <v-row
            v-if="single"
            class="ma-0">
            <v-col
              v-for="({ product, readOnly = true, shortTitle, loading = false, notAccessible = false} = {
              }, index) in item"
              :key="index"
              :class="[
                shortTitle === 'Reference' && $vuetify.breakpoint.smAndDown ? 'bg-f7' : ''
              ]"
              :cols="12"
              :md="12 / productsLength"
              class="pa-0">
              <AppFormRowToProductCell
                :can-be-rendered="canBeRendered(head)"
                :is-required-field="isRequiredField(head.id)"
                :single-item="single"
                :filter-names-group-items="filterNamesGroupItems"
                :search-mode="searchMode"
                :header="head"
                :mapped-dropdowns-of-view="mappedDropdownsOfView"
                :product="product"
                :short-title="shortTitle"
                :read-only="readOnly"
                :loading="loading"
                :not-accessible="notAccessible"
                :cell-class="renderClass(head)"
                :view="view"
                :is-dialog-collection="isDialogCollection"
                :disabled-edit-mode="disabledEditMode"
                :expanded-row="!showPensil(head.column.type)"
                :collection-id="collectionId"
                @updateProductField="$emit('updateProductField', $event)" />
            </v-col>
          </v-row>
          <v-row
            v-else
            class="ma-0">
            <v-col
              cols="12"
              class="pa-0"
              md>
              <v-row
                class="ma-0">
                <v-col
                  v-for="(header, index) in head.columns"
                  :key="index"
                  :class="[
                    head.item.shortTitle === 'Reference' && $vuetify.breakpoint.smAndDown ? 'bg-f7' : ''
                  ]"
                  :cols="12"
                  :md="12 / item.length"
                  class="pa-0">
                  <AppFormRowToProductCell
                    :sm-cols="12"
                    :can-be-rendered="canBeRendered(header)"
                    :is-required-field="isRequiredField(header.id)"
                    :single-item="single"
                    :filter-names-group-items="filterNamesGroupItems"
                    :search-mode="searchMode"
                    :header="header"
                    :product="head.item.product"
                    :short-title="head.item.shortTitle"
                    :read-only="head.item.readOnly"
                    :cell-class="renderClass(header)"
                    :view="view"
                    :loading="head.item.loading"
                    :not-accessible="head.item.notAccessible"
                    :is-dialog-collection="isDialogCollection"
                    :disabled-edit-mode="head.item.readOnly ? true : disabledEditMode"
                    :expanded-row="!showPensil(header.column.type)"
                    :collection-id="collectionId"
                    @updateProductField="$emit('updateProductField', $event)" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" />
          </v-row>
        </template>
      </wrapper-form-row>
    </template>
  </BaseLayout>
</template>
<script>
import BaseLayout from '../ProductCellCollectionsFormListBaseLayout';
import WrapperFormRow from '@/components/App/AppFormRow/WrapperFormRow';
import AppFormRowToProductCell from '../AppFormRowToProductCell';
export default {
  name: 'ProductCellCollectionsFormListToComparison',
  components: {
    WrapperFormRow,
    BaseLayout,
    AppFormRowToProductCell,
  },
  props: {
    item: {
      type: Array,
      default: () => [],
    },
    mappedDropdownsOfView: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    productsLength() {
      const { item } = this;
      if (!Array.isArray(item)) return 0;
      return item.length;
    },
  },
  methods: {
    mappedFilterHeadersByFlexItem(headers) {
      const items = headers.reduce((result, option) => {
        if (!Array.isArray(option)) return [...result, option];
        const { item } = this;
        const mappedHeaderItems = item.map(i => ({
          item: i,
          columns: option,
        }));
        return [
          ...result,
          mappedHeaderItems,
        ];
      }, []);
      return items;
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_variables';
  .bg-f7 {
    background-color: #f7f7f7;
  }
  .title-of-product {
    position: sticky;
    background: #fff;
    top: -4px;
    z-index: 11;
    &__title {
      font-weight: 600;
      font-size: 20px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 17px;
      }
    }
  }
</style>
