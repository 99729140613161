<template>
  <v-tooltip
    top>
    <template
      #activator="{ on }">
      <v-icon
        class="action-icons action-icons__paste-icon"
        size="22"
        color="mainGrey"
        @click.stop="$emit('pasteContent')"
        v-on="on">
        mdi-content-paste
      </v-icon>
    </template>
    <span>Paste content</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'ProductCellPaste',
};
</script>