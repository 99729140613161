<template>
  <div class="create-product-search">
    <v-text-field
      class="create-product-search-field border-outline elevation-1"
      :placeholder="placeholderText"
      :readonly="true"
      :outlined="true"
      hide-details
      @click="openSearch" />
    <CreateProductSearchDialog
      :search-open="searchOpen"
      v-bind="$attrs"
      v-on="$listeners"
      @searchDialogClosed="closeSearch" />
  </div>
</template>
<script>
import CreateProductSearchDialog from './CreateProductSearchDialog';
import { mapMutations } from 'vuex';
export default {
  name: 'CreateProductSearch',
  components: {
    CreateProductSearchDialog,
  },
  data() {
    return {
      searchOpen: false,
      placeholderText: 'Click here to search for a Product in this Workspace.',
    };
  },
  destroyed() {
    this.setProductModalInvisible(false);
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations('Collections', ['setProductModalInvisible']),
    openSearch() {
      this.searchOpen = true;
      this.setProductModalInvisible(true);
    },
    closeSearch() {
      this.searchOpen = false;
      this.setProductModalInvisible(false);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-text-field__slot {
  input {
    cursor: pointer;
  }
}
.create-product-search {
  padding: 0 10px;
  margin-right: 20px;
}
.create-product-search-field {
  max-width: 91.67%;
}
</style>
