<template>
  <div class="add-product-modal">
    <app-dialog
      v-model="showProductModal"
      :value.sync="showProductModal"
      :width="useComparisonTool ? 1600 : 1200"
      content-class="v-dialog__form v-dialog__product-expanded-view"
      persistent
      @keydown.esc="showProductModal = false">
      <div
        class="dialog-content"
        :style="{ 'opacity': productModalInvisible ? 0 : 1 }">
        <v-card v-if="showProductModal">
          <v-card-title>
            <div class="d-flex align-center">
              <span v-if="isEditModalVariant">Update Product</span>
              <span v-else-if="isAddModalVariant">Create or follow an existing Product</span>
              <span
                v-else-if="isReadOnlyModalVariant || isCompareModalVariant || isSpecSheetModalVariant">
                Product Details
              </span>
              <span v-else-if="isSaveAsNewVariant">Save as new product</span>
              <span v-else-if="isUpdateReferencedProduct">Update Referenced Product</span>
              <div class="ml-3 mb-1">
                <AppVerifiedMark
                  :is-verified="isVerifiedProduct"
                  :child-verified-ws="childVerifiedWS"
                  :verified-page-id="verifiedPageId"
                  :verified-page-name="verifiedPageName"
                  :size="22" />
              </div>
            </div>
            <v-icon
              color="#fff"
              @click="showProductModal = false">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text class="items-wrapper">
            <div class="items h-100vh">
              <!-- header options -->
              <div class="mb-3 d-flex flex-wrap gap-6 justify-start justify-md-end">
                <div
                  v-if="(isReadOnlyModalVariant || isEditModalVariant || isCompareModalVariant || isSpecSheetModalVariant) && getRoutesForProductModal"
                  class="cursor-pointer"
                  @click="copyToClipBoard(renderProductLink)">
                  Share This Product
                </div>
                <div
                  v-if="shouldShowReportLink"
                  class="cursor-pointer"
                  @click="reportProduct">
                  Report This Product
                </div>
                <v-btn
                  v-if="useComparisonTool && !(isEditModalVariant || isCompareModalVariant)"
                  outlined
                  small
                  @click="changeProductModalVariant(TYPE_COMPARE);">
                  <v-icon
                    :size="20"
                    color="lightBlue"
                    class="white rounded-pill">
                    mdi-check-circle
                  </v-icon>
                  Compare with Verified
                </v-btn>
                <v-btn
                  v-if="useComparisonTool && isCompareModalVariant && $vuetify.breakpoint.smAndDown"
                  outlined
                  small
                  @click="changeProductModalVariant(TYPE_READONLY);">
                  <v-icon
                    :size="20"
                    color="lightBlue"
                    class="white rounded-pill">
                    mdi-check-circle
                  </v-icon>
                  Close Verified Compare
                </v-btn>
              </div>
              <AppFormRow
                v-if="isReadOnlyModalVariant && !hasAccessToProduct && productCollectionName"
                class="flex-grow-0"
                header-text="Collection">
                <template #rowCell>
                  <a @click="goNextPageBlank">
                    {{ productCollectionName }}
                  </a>
                </template>
              </AppFormRow>
              <!-- content -->
              <div class="product-details-flex-wrapper">
                <!-- left panel -->
                <div>
                  <div v-if="isAddModalVariant">
                    <h3 class="section-title">
                      Search to <b>follow</b> an existing product
                    </h3>
                    <CreateProductSearch
                      class="mt-2 mb-7"
                      :collection-id="collectionIdForGetSchema" />
                  </div>
                  <h3
                    v-if="isAddModalVariant"
                    class="section-title">
                    Create a custom product
                  </h3>
                  <AppSearchRowsProvider
                    ref="searchWrapper"
                    placeholder="Search by field name"
                    :cols="useComparisonTool && isCompareModalVariant && $vuetify.breakpoint.mdAndUp ? '6' : '12'"
                    :search-wrapper-class="isReadOnlyModalVariant && !hasAccessToProduct && productCollectionName
                      ? 'search-items__wrapper-collections-search'
                      : 'search-items__wrapper-collections'"
                    :items="renderHeaders"
                    :search-items-height="searchItemsHeight"
                    @hook:mounted="mountedappSearchRowsProvider">
                    <template #items="{ searchItems, searchOn }">
                      <div class="d-flex flex-row">
                        <div class="followed-product">
                          <component
                            :is="($vuetify.breakpoint.smAndDown && isCompareModalVariant) ? 'ProductCellFormListToComparison' : 'ProductCellFormList'"
                            :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
                            :collection-id="collectionIdForViewSchema"
                            :field-errors="fieldErrors"
                            :headers="searchItems"
                            :loading-headers="loadingEditBlockedHeaders"
                            :is-aside-form="isCompareModalVariant"
                            :is-dialog-collection="isDialogCollection"
                            :is-loading="isFetchingAny"
                            :item="($vuetify.breakpoint.smAndDown && isCompareModalVariant) ? productsToComparison : item"
                            :mapped-dropdowns-of-view="getMappedDropdownsOfView"
                            :search-mode="searchOn"
                            view="productCreate"
                            @updateProductField="updateProductField" />
                          <div
                            v-if="shouldShowDisclaimer"
                            class="show-only-if-collapsed mt-8 pl-3 pr-10 red--text darken-1">
                            {{ disclaimerText }}
                            <br>
                            <v-btn
                              class="request-verification-btn"
                              plain
                              @click="requestVerification">
                              Request Verification
                            </v-btn>
                          </div>
                        </div>
                        <div
                          v-if="isCompareModalVariant"
                          class="product-details-compare-wrapper">
                          <!-- icons -->
                          <div class="d-flex flex-row justify-space-between px-3 pt-2 icon-wrapper">
                            <div>
                              <v-icon
                                :size="20"
                                color="lightBlue"
                                class="white rounded-pill cursor-pointer">
                                mdi-check-circle
                              </v-icon>
                              Verified Version
                            </div>
                            <v-icon
                              :size="20"
                              color="black"
                              class="white rounded-pill cursor-pointer"
                              @click="changeProductModalVariant(TYPE_READONLY);">
                              mdi-close-circle
                            </v-icon>
                          </div>
                          <ProductCellFormList
                            :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
                            :collection-id="collectionIdForViewSchema"
                            :headers="searchItems"
                            :loading-headers="loadingEditBlockedHeaders"
                            :field-errors="fieldErrors"
                            :is-aside-form="true"
                            :is-dialog-collection="isDialogCollection"
                            :is-loading="isFetchingAny"
                            :item="origin"
                            :mapped-dropdowns-of-view="getMappedDropdownsOfView"
                            :render-additional-cell="false"
                            :search-mode="searchOn"
                            view="productCreate"
                            @updateProductField="updateProductField" />
                        </div>
                      </div>
                    </template>
                  </AppSearchRowsProvider>
                </div>
                <!-- to show tips and tricks -->
                <div
                  v-if="moveTipsAndTricks && !(isCompareModalVariant || isSpecSheetModalVariant)"
                  class="product-details-aside-wrapper"
                  :style="searchItemsWithBarHeight">
                  <ProductCellFormList
                    :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
                    :collection-id="collectionIdForViewSchema"
                    :field-errors="fieldErrors"
                    :headers="renderAsideHeaders"
                    :loading-headers="loadingEditBlockedHeaders"
                    :is-aside-form="true"
                    :is-dialog-collection="isDialogCollection"
                    :is-loading="isFetchingAny"
                    :item="item"
                    :mapped-dropdowns-of-view="getMappedDropdownsOfView"
                    :search-mode="false"
                    view="productCreate"
                    @updateProductField="updateProductField" />
                  <div
                    v-if="shouldShowDisclaimer"
                    class="red--text darken-1 mt-8 px-3">
                    {{ disclaimerText }}
                    <br>
                    <v-btn
                      class="request-verification-btn"
                      plain
                      @click="requestVerification">
                      Request Verification
                    </v-btn>
                  </div>
                </div>
                <!-- to show spec sheet -->
                <div
                  v-if="isSpecSheetModalVariant"
                  class="prodcut-details-spec-sheet-wrapper"
                  :style="searchItemsWithBarHeight">
                  <v-icon
                    :size="20"
                    color="black"
                    class="white rounded-pill cursor-pointer"
                    @click="changeProductModalVariant(TYPE_READONLY);">
                    mdi-close-circle
                  </v-icon>
                  <embed
                    v-if="getProductModalSpecSheetUrl"
                    :src="getProductModalSpecSheetUrl"
                    type="application/pdf">
                  <div v-else>
                    Some error occured!
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <DialogActions
            ref="dialogActions"
            :can-work-with-modify-product="canWorkWithModifyProduct"
            :user-role-in-collection="userRoleInCollection"
            :item="item"
            :followed-collection-id="followedCollectionId"
            :has-access-to-product="hasAccessToProduct"
            :is-store-product-preload="isStoreProductPreload"
            :selected-collection-to-create-product="selectedCollectionToCreateProduct"
            :product-id="productId"
            :editable="editable"
            :no-modal-for-duplicate-follow="noModalForDuplicateFollow"
            :no-modal-collection-id="noModalCollectionId"
            @save="onSave"
            @changeToEdit="changeToEdit"
            @is-followed-product="isFollowedProduct = $event"
            @show-product-modal="showProductModal = $event"
            @delete-current-product="deleteCurrentProduct" />
        </v-card>
      </div>
    </app-dialog>
  </div>
</template>
<script>
// scraping takes a while, so let the success/error message last longer too or it gets missed!
const SCRAPING_SNACKBAR_TIMEOUT = 60000;

// core
import { debounce } from 'lodash';
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';

// constants
import PRODUCT_HEADERS, {
  SCRAPING_HEADERS,
  DEFAULT_PRODUCT_REQUIRED_FIELDS,
} from '@/constants/productHeaders';
import { FOLLOW_EDIT_REASON } from '@/constants/userPermissions';
import { COLLECTION } from '@/constants/cores';
import { INFO_EMAIL } from '@/constants/urls';
import {
  TYPE_FOLLOW,
  TYPE_DUPLICATE,
  TYPE_COMPARE,
  TYPE_EDIT,
  TYPE_READONLY, PRIVATE,
  DEBOUNCE_TIME_TO_RESIZE_EVENTS,
} from '@/constants';

// components
import AppFormRow from '@/components/App/AppFormRow';
import AppSearchRowsProvider from '@/components/App/AppSearchRowsProvider';
import AppVerifiedMark from '@/components/App/AppVerifiedMark';
import CreateProductSearch from '@/components/Collections/CollectionsCreateProductDialog/CreateProductSearch';
import DialogActions from '@/components/Collections/CollectionsCreateProductDialog/DialogActions/index.vue';
import ProductCellFormList from '@/components/Product/ProductCell/ProductCellCollectionsFormList';
import ProductCellFormListToComparison
  from '@/components/Product/ProductCell/ProductCellCollectionsFormList/ProductCellCollectionsFormListToComparison';

// mixins
import updateCollectionProductField from '@/mixins/updateCollectionProductField';
import DisableEditMode from '@/mixins/DisableEditMode';

// services
import API from '@/services/graphql';
import CollectionsApi from '@/services/graphql/collections';
import ComparisonApi from '@/services/graphql/comparison';

// utils
import RequestAbortController from '@/utils/RequestAbortController';
import { parseGetRows } from '@/utils/manageGetRows';
import {
  EventBus,
  DUPLICATED_PRODUCT_IN_CURR_COLL,
  EDITED_FOLLOWED_PRODUCT,
  FOLLOWED_PRODUCT_IN_CURR_COLL,
  SCRAPE_PRODUCT_DATA,
} from '@/utils/eventBus';
import {
  askAndPerform, isLimitOfProducts,
  copyToClipBoard, accessHelper, hasViewerAccess,
} from '@/utils';
import {
  isAsideHeader,
  sortProductDialogSchemaHeaders,
} from '@/utils/productHeaders';
import {
  setCollectionSchema,
  setCollectionSchemaMapping,
  setMappedDropdownsOfView,
  setSelectedCollectionView,
} from '@/utils/collectionSchemaAndViews';

export default {
  name: 'CollectionsCreateProductDialog',
  components: {
    AppFormRow,
    AppSearchRowsProvider,
    AppVerifiedMark,
    CreateProductSearch,
    DialogActions,
    ProductCellFormList,
    ProductCellFormListToComparison,
  },
  mixins: [updateCollectionProductField, DisableEditMode],
  props: {
    isSourceLibraryId: {
      type: Boolean,
      default: false,
    },
    isDialogCollection: {
      type: Boolean,
      default: true,
    },
    statusDialog: {
      type: Boolean,
      default: false,
    },
    isStatusDialogFromState: {
      type: Boolean,
      default: true,
    },
    sharedLinkCollectionId: {
      type: String,
      default: () => '',
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    collectionIdForGetSchema: {
      type: String,
      default: '',
    },
    propLibraryId: {
      type: String,
      default: '',
    },
    localProduct: {
      type: Object,
      default: () => {
      },
    },
    isAllowComparison: {
      type: Boolean,
      default: true,
    },
    autoHideUnpopulatedFields: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines whether to use the same schema and views.
     *
     * @type {boolean}
     * @default false
     * @description If set to true, the component will use the same schema and views as the rendered page.
     */
    useTheSameSchemaAndViews: {
      type: Boolean,
      default: false,
    },
    noModalForDuplicateFollow: {
      type: Boolean,
      default: false,
    },
    noModalCollectionId: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      abortController: new RequestAbortController(),
      collectionsSchemaV2: {
      },
      fieldErrors: [],
      isFetching: {
        initialOpening: true,
        collectionViews: false,
        collectionSchema: false,
      },
      isFollowedProduct: false,
      origin: {
      },
      productsToComparison: [],
      productId: null,
      rowData: {
      },
      selectedCollectionToCreateProduct: null,
      selectedCollectionView: {
      },
      searchItemsHeight: '',
      searchItemsWithBarHeight: '',
      showTooltip: true,
      showProductComparison: false,
      TYPE_COMPARE,
      TYPE_DUPLICATE,
      TYPE_FOLLOW,
      TYPE_READONLY,
      // optimistic updating after editing followed product
      tempChildVerifiedWs: null,
      userRoleInCollection: null,
      viewMode: 'default',
    };
  },
  computed: {
    ...mapGetters('Collections', [
      'collectionGroupsForFollowProduct',
      'collectionsWithPermissions',
      'findSaveAsNewCollectionsById',
      'getProductModalSpecSheetUrl',
      'getRoutesForProductModal',
      'getSaveAsNewCollections',
      'isAddModalVariant',
      'isCompareModalVariant',
      'isDisallowClosingProductDialogAfterUpdate',
      'isEditModalVariant',
      'isReadOnlyModalVariant',
      'isSpecSheetModalVariant',
      'isUpdateReferencedProduct',
      'isSaveAsNewVariant',
      'updatedProduct',
    ]),
    ...mapGetters('FeatureFlags', [
      'useActionsProductFromSearch',
      'useEditedFollowComparisonTool',
      'useLightfairRequiredFields',
      'creatorUnableToCreateFollowProducts',
      'moveTipsAndTricks',
      'useLazyLoading',
    ]),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    ...mapGetters('UserRoles', ['canAddProductToCollection', 'canFollowProduct', 'findUserRoleInLibraryForActiveHeader', 'usersRolesListInCollection']),
    ...mapState({
      generalAccessToken: state => state.accessToken,
    }),
    ...mapState(['showSpinner', 'activeHeader', 'isMobile']),
    ...mapState('Collections', ['privateCollectionsList', 'productToUpdate', 'publishedCollectionsList', 'productModalInvisible',
      'showCreateProductModal', 'productCollectionName', 'productCollectionLink', 'currentCollectionSharingOption', 'isCollectionInCustomLibrary']),
    ...mapState({
      isScrapingProductData: state => state.Collections.isFetching.scrapingProductData,
    }),
    ...mapState('Workspace', ['activeWorkspaceId']),
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    canWorkWithModifyProduct() {
      const {
        useActionsProductFromSearch: flag,
        isEditableFromSearch,
        findUserRoleInLibraryForActiveHeader,
        usersRolesListInCollection,
        isUpdateReferencedProduct,
      } = this;
      const { members } = this.activeHeader || {
      };
      let role = findUserRoleInLibraryForActiveHeader;
      if (isEditableFromSearch && flag) return accessHelper(true);
      else if (isUpdateReferencedProduct) {
        role = this.roleInResource;
      } else if (members) {
        role = usersRolesListInCollection;
      }
      return this.canModifyProduct(role, this.isFollowedProduct && FOLLOW_EDIT_REASON);
    },
    childVerifiedWS() {
      return this.tempChildVerifiedWs || this.currentProduct?.childVerified;
    },
    collectionIdForViewSchema() {
      return this.collectionIdForGetSchema || this.collectionIdForCreateProduct || this.getDefaultLibraryId;
    },
    collectionsList() {
      return this.collectionsWithPermissions();
    },
    collectionsSchemaAccordingToFlag() {
      const { creatorUnableToCreateFollowProducts: flag, useTheSameSchemaAndViews } = this;
      return flag && !useTheSameSchemaAndViews ? this.collectionsSchemaV2 : this.collectionsSchema;
    },
    currentProduct() {
      const {
        isStatusDialogFromState = false,
        productToUpdate = {
        },
        localProduct = {
        },
      } = this;
      return !isStatusDialogFromState ? localProduct : productToUpdate;
    },
    disclaimerText() {
      return '*Note that this is a non-verified product created by a Sourcery user and not the manufacturer.\
              We encourage that you verify all information with the manufacturer directly.';
    },
    formatedRequiredFields() {
      return this.requiredFieldsAccordingToResolution.map(el => {
        const item = this.schema.find((shemaObj) => shemaObj.id === el);
        return item.value;
      });
    },
    getMappedDropdownsOfView() {
      const { selectedCollectionView: view } = this;
      const { dropdowns } = view || {
      };
      return setMappedDropdownsOfView(dropdowns);
    },
    hasAccessToProduct() {
      const { useActionsProductFromSearch: flag, isEditableFromSearch, editable } = this;
      return (isEditableFromSearch && flag) || editable;
    },
    isDisabledCreateButton() {
      return (this.isAddModalVariant || this.isSaveAsNewVariant) && !this.selectedCollectionToCreateProduct?.id;
    },
    isEditableFromSearch() {
      return this.currentProduct?.editable || false;
    },
    isEditMode() {
      return this.currentProduct?.mode === 'edit';
    },
    isEditModalRelatedVariant() {
      const { isEditModalVariant, isUpdateReferencedProduct } = this;
      return isEditModalVariant || isUpdateReferencedProduct;
    },
    isFetchingAny() {
      if (!this.useLazyLoading) return;

      return this.isFetching.initialOpening
        || this.isFetching.collectionViews
        || this.isFetching.collectionSchema;
    },
    isVerifiedProduct() {
      return this.currentProduct?.isVerified;
    },
    item: {
      get() {
        return this.rowData;
      },
      set(value) {
        this.$set(this.rowData, value.col, value.value);
      },
    },
    loadingEditBlockedHeaders() {
      return this.isScrapingProductData ? SCRAPING_HEADERS : [];
    },
    removeText() {
      return 'This will DELETE the Product(s) from this Collection. Are you sure?';
    },
    renderAsideHeaders() {
      return this.schema.filter(isAsideHeader);
    },
    renderHeaders() {
      const predefined = [];
      predefined.push({
        name: 'Choose Collection',
        value: 'Choose Collection',
        selectValue: this.selectedCollectionToCreateProduct?.id,
        items: this.isSaveAsNewVariant ? this.getSaveAsNewCollections : this.collectionsList,
        column: {
          type: 'SELECT',
        },
      });
      return [...predefined, ...this.schema];
    },
    renderProductLink() {
      const workspace = `/workspace/${this.activeWorkspaceId}`;
      const isPrivateCollection = this?.currentCollectionSharingOption?.type == PRIVATE;
      const renderWsString = isPrivateCollection ? workspace : '';
      let additionalPath = '';
      const urlParams = new URLSearchParams();
      if (['shared-collection-product-link', 'embed-collection-product-link'].includes(this.$route.name)) {
        additionalPath = '/shared';
        Object.entries(this.$route.query).forEach(entry => {
          urlParams.set(entry[0], entry[1] ?? '');
        });
      }
      const query = urlParams?.toString();
      // return `https://${window.location.hostname}${renderWsString}/collection/${this.$route.params.id}${additionalPath}/product/${this.item.id}${query ? '?'.concat(query) : ''}`;
      return `https://${window.location.hostname}${renderWsString}/collection/${this.collectionIdForCreateProduct}${additionalPath}/product/${this.item.id}${query ? '?'.concat(query) : ''}`;
    },
    requiredFieldsAccordingToResolution() {
      const { requiredFields } = this.collectionsSchemaAccordingToFlag || {
      };
      if (!this.useLightfairRequiredFields || this.isMobile) {
        return requiredFields;
      }
      return DEFAULT_PRODUCT_REQUIRED_FIELDS;
    },
    schema() {
      const { schema } = this.collectionsSchemaAccordingToFlag || {
      };
      if (!schema) return [];
      return sortProductDialogSchemaHeaders(schema);
    },
    shouldShowDisclaimer() {
      return !this.isVerifiedProduct && !this.isAddModalVariant;
    },
    shouldShowReportLink() {
      return !this.isVerifiedProduct
      && (this.isReadOnlyModalVariant || this.isEditModalVariant || this.isCompareModalVariant || this.isSpecSheetModalVariant);
    },
    showProductModal: {
      get() {
        return this.isStatusDialogFromState ? this.updatedProduct : this.statusDialog;
      },
      set(value) {
        if (value === false) {
          this.$emit('productDialogClosed');
        }
        this.isStatusDialogFromState ? this.changeUpdateProductMode(value) : this.$emit('update:statusDialog', value);
      },
    },
    useComparisonTool() {
      const {
        useEditedFollowComparisonTool: flag,
        isEditMode,
        isAllowComparison,
        isAddModalVariant,
        usersRolesListInCollection,
        findUserRoleInLibraryForActiveHeader,
      } = this;
      return flag && !isAddModalVariant && isEditMode && isAllowComparison && hasViewerAccess(findUserRoleInLibraryForActiveHeader ?? usersRolesListInCollection);
    },
    verifiedPageId() {
      return this.currentProduct?.verifiedPageId;
    },
    verifiedPageName() {
      return this.currentProduct?.verifiedPageName;
    },
  },
  watch: {
    currentProduct(val) {
      if (val) {
        if (this.isUpdateReferencedProduct) {
          this.getSchemaAndViewsToCollection({
            collectionId: val?.referencedCollectionId,
          });
        } else {
          this.getProductId(val);
        }
      }
    },
    selectedCollectionToCreateProduct(value) {
      if (!value) return;
      const { id: collectionId } = value;
      const { creatorUnableToCreateFollowProducts: flag } = this;
      if (this.isSaveAsNewVariant || flag) {
        this.getSchemaAndViewsToCollection({
          collectionId,
        });
      }
    },
    async showProductModal(value) {
      if (!value) {
        this.onCloseModal();
        this.productsToComparison = [];
        if (this.getRoutesForProductModal.includes(this.$route.name)) {
          const matched = this.$route.matched;
          const { propLibraryId } = this;
          const libraryId = propLibraryId || this.$route.params?.libraryId;
          this.$router.replace({
            name: matched[matched.length - 2]?.name,
            params: {
              disableProxyDialog: true,
              id: this.$route.params.id,
              ...this.isCollectionInCustomLibrary && libraryId && {
                libraryId,
              },
            },
            query: {
              ...this.$route.query,
              ...!this.canWorkWithModifyProduct?.allowed
              && this.currentCollectionSharingOption?.type == 'unlistedPublish'
              && {
                accessToken: ['shared-collection-product-link', 'embed-collection-product-link'].includes(this.$route.name)
                  ? this.$route.query?.accessToken
                  : this.currentCollectionSharingOption?.accessToken,
              },
            },
          }).catch(() => {
          });
        }
        return;
      }
      this.initRowData();
      if (this.isUpdateReferencedProduct) {
        this.isFetching.initialOpening = false;
        return;
      }
      if (this.isSaveAsNewVariant) {
        this.showProductModalInSchedule();
        return;
      }
      this.showProductModalInCollections();
      this.checkComparisonExecution();
      await this.getUserRoleInCollection();
    },
  },
  created() {
    this.abortController = new RequestAbortController();
  },
  mounted() {
    EventBus.$on(FOLLOWED_PRODUCT_IN_CURR_COLL, () => {
      this.showProductModal = false;
    });
    EventBus.$on(DUPLICATED_PRODUCT_IN_CURR_COLL, () => {
      this.showProductModal = false;
    });
    EventBus.$on(EDITED_FOLLOWED_PRODUCT, this.editedFollowedProduct);
    EventBus.$on(SCRAPE_PRODUCT_DATA, ({ url }) => this.scrapeProductDataFrom(url));
  },
  beforeDestroy() {
    this.abortController.abort();
  },
  destroyed() {
    EventBus.$off(FOLLOWED_PRODUCT_IN_CURR_COLL);
    EventBus.$off(DUPLICATED_PRODUCT_IN_CURR_COLL);
    EventBus.$off(EDITED_FOLLOWED_PRODUCT);
    EventBus.$off(SCRAPE_PRODUCT_DATA);
    window.removeEventListener('resize', this.getSearchResultHeight);
  },
  methods: {
    askAndPerform,
    copyToClipBoard,
    ...mapMutations(['spinner', 'openSnackBar']),
    ...mapMutations('Collections', [
      'changeUpdateProductMode',
      'setProductToUpdate',
      'changeProductModalVariant',
      'changeProductCollectionLink',
      'changeProductCollectionName',
    ]),
    mountedappSearchRowsProvider() {
      window.addEventListener('resize', this.getSearchResultHeight);
    },
    ...mapActions({
      handleError: 'handleError',
      getCustomLibraryCollectionsList: 'Collections/getCustomLibraryCollectionsList',
      getCollectionsSchemaV1: 'Collections/getCollectionsSchema',
      getCollectionsViewsV1: 'Collections/getCollectionsViews',
      getCollectionsList: 'Collections/getCollectionsList',
      getlistCollectionsForProduct: 'Collections/getlistCollectionsForProduct',
      deleteProduct: 'Collections/deleteProduct',
      deleteProductFromSearch: 'Collections/deleteProductFromSearch',
      scrapeProductData: 'Collections/scrapeProductData',
    }),
    async getUserRoleInCollection() {
      const { collectionId: resourceId } = this.item || {
      };
      if (!resourceId) return;
      try {
        const { data } = await API.getMyRoleInResource({
          workspaceId: this.activeWorkspaceId,
          resourceType: COLLECTION,
          resourceId,
        });
        this.userRoleInCollection = data.response;
      } catch (err) {
        console.error(err);
      }
    },
    async getProductToComparison() {
      try {
        const {
          activeWorkspaceId: workspaceId,
          getMasterCollection: tableId,
          getMasterProduct: rowId,
        } = this;
        if (!tableId || !rowId) {
          this.setProductsToComparison({
            origin: {
            },
            notAccessible: true,
          });
          return;
        }
        this.setProductsToComparison({
          loading: true,
          origin: {
          },
        });
        const { data } = await ComparisonApi.getRowDiffProduct({
          tableType: 'collection',
          tableId,
          rowId,
          rowVersionId: '',
          compareVersion: '',
          workspaceId,
        });
        const { fields = [] } = data?.response || {
        };
        const parsedFields = parseGetRows(fields);
        this.setProductsToComparison({
          origin: parsedFields,
        });
      } catch (e) {
        this.setProductsToComparison({
          origin: {
          },
          notAccessible: true,
        });
        console.log('getProductToComparison errr', e);
      } finally {
        this.spinner(false);
      }
    },
    async checkComparisonExecution() {
      const { useComparisonTool } = this;
      if (!useComparisonTool) return;
      const { isEditMode } = this;
      if (!isEditMode) return;
      this.getProductToComparison();
    },
    async deleteCurrentProduct() {
      const {
        isEditableFromSearch,
        followedCollectionId: collectionId = '',
        productId = '',
        deleteProduct,
        deleteProductFromSearch,
        removeText: text,
        useActionsProductFromSearch: flag,
      } = this;
      const action = isEditableFromSearch && flag ? deleteProductFromSearch : deleteProduct;
      const res = await this.$openConfirm({
        text,
      });
      if (!res) return;
      try {
        this.spinner(true);
        await action({
          collectionId,
          productIds: [productId],
        });
      } finally {
        this.spinner(false);
        this.showProductModal = false;
      }
    },
    async initRowData() {
      if (this.currentProduct) {
        this.rowData = this.currentProduct;
      } else {
        this.schema.forEach((item) => {
          this.$set(this.rowData, item.text, this.getInitialValue(item.column.type));
        });
      }
    },
    async showProductModalInSchedule() {
      this.spinner(true);
      await this.getlistCollectionsForProduct({
        projectId: this.rowData.projectId,
      });
      const item = this.findSaveAsNewCollectionsById(this.getDefaultLibraryId);
      const [firstCollection = null] = this.getSaveAsNewCollections || [];
      if (!isLimitOfProducts(item)) {
        this.selectedCollectionToCreateProduct = item || firstCollection;
      }
      this.spinner(false);
      this.isFetching.initialOpening = false;
    },
    async getSchemaAndViewsToCollection(data) {
      if (!data) return;
      if (!this.useLazyLoading) this.spinner(true);
      await this.getCollectionsViews(data);
      await this.getCollectionsSchema(data);
      this.getSearchResultHeight();
    },
    async getCollectionsViews(values) {
      const { creatorUnableToCreateFollowProducts: flag, propLibraryId: libraryId } = this;
      const data = {
        ...values,
        ...libraryId && {
          libraryId,
        },
      };
      try {
        this.isFetching.collectionViews = true;
        flag ? await this.getCollectionsViewsV2(data) : await this.getCollectionsViewsV1(data);
      } finally {
        this.isFetching.collectionViews = false;
      }
    },
    async getCollectionsSchema(values) {
      const { creatorUnableToCreateFollowProducts: flag, propLibraryId: libraryId } = this;
      const data = {
        ...values,
        ...libraryId && {
          libraryId,
        },
      };
      try {
        this.isFetching.collectionSchema = true;
        flag ? await this.getCollectionsSchemaV2(data) : await this.getCollectionsSchemaV1(data);
      } finally {
        this.isFetching.collectionSchema = false;
      }
    },
    async getCollectionsViewsV2(values = {
    }) {
      try {
        const { generalAccessToken: accessToken, activeWorkspaceId: workspaceId } = this;
        const { data } = await CollectionsApi.collectionsViews({
          ...values,
          workspaceId,
          ...(accessToken && {
            accessToken,
          }),
        });
        const { response: views } = data;
        this.selectedCollectionView = setSelectedCollectionView(views);
      } catch (err) {
        console.log('getCollectionsViews err', err);
      }
    },
    async getCollectionsSchemaV2(values = {
    }) {
      try {
        const { generalAccessToken: accessToken, activeWorkspaceId: workspaceId } = this;
        const { data } = await CollectionsApi.collectionsSchema({
          ...values,
          workspaceId,
          ...(accessToken && {
            accessToken,
          }),
        });
        const { response: collectionsSchema } = data || {
        };
        const { schema } = collectionsSchema || {
        };
        this.collectionsSchemaV2 = setCollectionSchema({
          selectedCollectionView: this.selectedCollectionView,
          collectionsSchemaMapping: setCollectionSchemaMapping(schema),
          collectionsSchema,
        });
      } catch (err) {
        console.log('getCollectionsSchema err', err);
      }
    },
    async getCollectionList() {
      const { getDefaultLibraryId: libraryId } = this;
      await Promise.all(this.collectionGroupsForFollowProduct.map(async item => (
        await this.getCollectionsList({
          libraryId,
          collectionGroup: item.group,
          limit: 99,
          showSpinner: false,
          isToAutocomplete: true,
          forProduct: true,
          accessToken: this.accessToken,
        })
      )));
    },
    async getLibraryCollectionList() {
      const { propLibraryId: libraryId, activeWorkspaceId: workspaceId } = this;
      const isAutocomplete = true;
      await this.getCustomLibraryCollectionsList({
        libraryId,
        workspaceId,
        isAutocomplete,
      });
    },
    async showProductModalToCreating() {
      this.selectedCollectionToCreateProduct = null;
      const { isSourceLibraryId } = this;
      isSourceLibraryId ? await this.getLibraryCollectionList() : await this.getCollectionList();
      if (!this.collectionsList.length) return;
      let collection = this.collectionsList[0];
      const route = this.$route;
      if (route.name == 'collection-library') {
        const colId = route.params.id;
        collection = this.collectionsList.find(col => col.id == colId);
      }
      if (!isLimitOfProducts(collection)) {
        this.selectedCollectionToCreateProduct = collection;
      }
      this.getSearchResultHeight();
    },
    async showProductModalInCollections() {
      if (!this.useLazyLoading) this.spinner(true);

      const {
        collectionIdForViewSchema,
        creatorUnableToCreateFollowProducts: flag,
        useTheSameSchemaAndViews,
      } = this;
      const collectionId = this.sharedLinkCollectionId ? this.sharedLinkCollectionId : collectionIdForViewSchema;
      if (this.isAddModalVariant) {
        await this.showProductModalToCreating();
        this.isFetching.initialOpening = false;
        if (flag) return;
      }
      if (flag && useTheSameSchemaAndViews) {
        this.spinner(false);
        this.getSearchResultHeight();
        this.isFetching.initialOpening = false;
        return;
      }
      this.getSchemaAndViewsToCollection({
        collectionId,
      });
      this.isFetching.initialOpening = false;
    },
    async onCloseModal() {
      this.rowData = {
      };
      this.fieldErrors = [];
      this.selectedCollectionToCreateProduct = null;
      this.collectionsSchemaV2 = {
      };
      this.selectedCollectionView = {
      };
      this.setProductToUpdate(null);
      this.changeProductModalVariant(null);
      this.changeProductCollectionLink('');
      this.changeProductCollectionName('');
      this.isFetching.initialOpening = true;
      this.abortController.abort();
    },
    async onSave() {
      if (this.showSpinner) {
        return;
      }
      const canSave = await this.allowToSave(this.currentProduct?.mode, this.isUpdateReferencedProduct ? this.item?.referencedProductId : this.currentProduct?.id);
      if (!canSave) {
        return;
      }
      this.checkValidateErrors();
      const {
        saveProduct,
        addProduct,
        isUpdateReferencedProduct,
        isEditModalRelatedVariant,
        closingModalLogic,
      } = this;
      if (this.fieldErrors.length === 0) {
        let productToCreate = [];
        this.schema.forEach((s) => {
          for (const element in this.item) {
            if (this.item[element] === undefined) {
              this.item[element] = '';
            }
          }
          if ((isEditModalRelatedVariant) && this.item[s.text] !== undefined
            || this.item && this.item[s.text]?.length !== 0 && this.item[s.text] !== undefined) {
            productToCreate.push(this.generateProduct({
              id: s.id,
              value: this.item[s.text] || '',
            }));
          }
        });
        const product = (isEditModalRelatedVariant) && !this.isCreateNewProduct ? await saveProduct({
          product: productToCreate,
          productId: this.item.id,
          ...(isUpdateReferencedProduct && {
            collectionId: this.item?.referencedCollectionId,
            productId: this.item?.referencedProductId,
          }),
        }) : await addProduct(productToCreate);
        closingModalLogic();
        this.$emit('savedProduct', product);
        this.isCreateNewProduct = false;
      }
    },
    changeToEdit() {
      this.changeProductModalVariant(TYPE_EDIT);
      this.getSearchResultHeight();
    },
    checkValidateErrors() {
      let errors = [];
      this.formatedRequiredFields.forEach((f) => {
        if (!this.item[f] || !this.item[f].length) {
          errors.push(f);
        }
      });
      this.fieldErrors = errors;
    },
    closingModalLogic() {
      const { isDisallowClosingProductDialogAfterUpdate: isDisallow } = this;
      if (isDisallow) {
        this.getSearchResultHeight();
        this.changeProductModalVariant(TYPE_READONLY);
        return;
      }
      this.showProductModal = false;
    },
    editedFollowedProduct(product) {
      if (!product || !this.isVerifiedProduct) return;

      for (const field of product.fields) {
        if (field.name === PRODUCT_HEADERS.CHILD_VERIFIED) {
          this.tempChildVerifiedWs = field.string;
          break;
        }
      }
    },
    getInitialValue(item) {
      if (item.includes('ARRAY')) {
        return [];
      } else {
        return '';
      }
    },
    getProductId(val) {
      const { SK } = val ?? {
      };
      if (!SK) {
        this.productId = '';
        return;
      }
      const [, productId] = SK.split('ROW#') || [];
      if (!productId) {
        this.productId = '';
        return;
      }
      this.productId = productId;
    },
    /**
      * Calculates the height of the search results based on the current viewport size and element offsets.
      */
    getSearchResultHeight: debounce(function () {
      const { $refs: refs } = this;
      if (!refs) {
        this.resetSearchResultHeight();
        return;
      }
      const { dialogActions, searchWrapper } = refs || {
      };
      const { $el: el } = dialogActions || {
      };
      if (!el) {
        this.resetSearchResultHeight();
        return;
      }
      const { clientHeight } = el || {
      };
      const { searchResults } = searchWrapper?.$refs || {
      };
      if (!searchResults) {
        this.resetSearchResultHeight();
        return;
      }
      const { offsetTop } = searchResults || {
      };
      const { innerHeight } = window || {
      };
      // Determine the maximum height for the search results
      // based on the current viewport height
      const MAX_HEIGHT_COEFFICIENT = 0.9; // 90% of the viewport height for mdAndUp breakpoint
      // Calculate the available height for the search results
      const calcInnerHeight = this.$vuetify.breakpoint.smAndDown ? innerHeight : MAX_HEIGHT_COEFFICIENT * innerHeight;
      // Calculate the height of the search items based on the available space
      const height = calcInnerHeight - (offsetTop + clientHeight);
      // Set the height of the search items CSS property
      this.searchItemsHeight = height <= 0 ? '' : `height: ${height}px`;
      this.searchItemsWithBarHeight = height <= 0 ? '' : `height: ${height + 55}px`;
    }, DEBOUNCE_TIME_TO_RESIZE_EVENTS),
    goNextPageBlank() {
      const routeData = this.$router.resolve(this.productCollectionLink);
      window.open(routeData.href, '_blank');
    },
    reportProduct() {
      const subject = 'Request to report a product';
      let body = `I would like to report a product:\n\r${this.renderProductLink}\n\r\n\r`;
      body = encodeURIComponent(body);

      window.open(`mailto:${INFO_EMAIL}?subject=${subject}&body=${body}`);
    },
    requestVerification() {
      const subject = 'Request Verification';
      let body = `I would like to request verification for a product:\n\r${this.renderProductLink}\n\r\n\r`;
      body = encodeURIComponent(body);

      window.open(`mailto:${INFO_EMAIL}?subject=${subject}&body=${body}`);
    },
    resetSearchResultHeight() {
      this.searchItemsHeight = '';
    },
    setProductsToComparison({ origin, follow, loading = false, notAccessible = false } = {
    }) {
      this.origin = origin;
      this.productsToComparison = [
        {
          shortTitle: 'Reference',
          title: 'Original Follow Reference (Read Only)',
          product: origin,
          readOnly: true,
          loading,
          notAccessible,
        },
        {
          shortTitle: 'Follow',
          title: 'Edited/Customized Follow (Editable)',
          readOnly: false,
          product: follow || this.item,
          loading: false,
        },
      ];
    },
    showPensil(type) {
      return ['DATE', 'ARRAY_AIRTABLE_IMAGE', 'COMBOBOX', 'DROPDOWN'].includes(type);
    },
    async scrapeProductDataFrom(url) {
      if (this.isScrapingProductData) return;

      try {
        this.abortController = new RequestAbortController();
        const scrapedProduct = await this.scrapeProductData({
          url: url,
          abortController: this.abortController,
        });
        const filledFields = await this.fillInScrapedData(scrapedProduct);
        if (filledFields.length > 0) {
          this.openSnackBar({
            title: 'Filled data for:',
            text: filledFields.join(', '),
            timeout: SCRAPING_SNACKBAR_TIMEOUT,
          });
        } else {
          throw new Error('No data from url - no fields filled?');
        }
      } catch (err) {
        if (this.abortController?.aborted()) {
          return;
        }
        console.log('Error in web scraping product data', err);
        this.openSnackBar({
          text: 'Sorry, but  we could not import this product.\n At least we tried!',
          timeout: SCRAPING_SNACKBAR_TIMEOUT,
        });
      }
    },
    async fillInScrapedData(productData) {
      const filledFields = [];
      for (const col in productData) {
        let val = productData[col];
        if (col == PRODUCT_HEADERS.IMAGE) {
          try {
            val = [val[0].id];
          } catch (err) {
            console.warn('Error filling in scraped Image data', err);
            continue;
          }
        }
        // We need to check for '0' strings due to how the unit_price + GQL response works
        // I kinda want to change it, but changing how we handle price numbers can be scary.
        if (!val || Array.isArray(val) && val.length === 0 || val == '0') {
          continue;
        }
        filledFields.push(col);
        this.item = {
          col: col,
          value: val,
        };
      }
      return filledFields;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.add-product-modal {
  margin-left: auto;
}
.items-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-height: 660px;
}
::v-deep .item-wrapper {
  margin-top: 20px;
  height: auto;
}
.items {
  display: flex;
  flex-direction: column;
}
::v-deep .v-application p {
  margin-bottom: 0px !important;
}
.required-field {
  color: red;
}
::v-deep.v-btn {
  width: 154px;
}
.product-details-flex-wrapper {
  display: flex;
  column-gap: 20px;
  width: 100%;
  div:first-child {
    flex-grow: 3;
    flex-basis: 0;
  }
}
.product-details-compare-wrapper {
  background: #f7f7f7;
  border-radius: 10px;
  flex-grow: 3;
  flex-basis: 0;
  overflow: auto;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    // The aside items show at the bottom of the original now.
    display: none;
  }

  .icon-wrapper {
    padding-bottom: 10px;
    background-color: #f7f7f7;
  }
}
.prodcut-details-spec-sheet-wrapper  {
  background: #f7f7f7;
  border-radius: 10px;
  flex-grow: 2;
  flex-basis: 0;
  overflow: auto;
  padding: 0 4px;

  .v-icon {
    display: block;
    margin-left: auto;
  }

  embed {
    // 10px extra to keep some space at the bottom
    height: calc(100% - 30px);
    width: 100%;
    padding: 10px;
  }
}
.product-details-aside-wrapper {
  padding: 0 4px;
  overflow: auto;
  height: 100%;
  flex-grow: 2;
  flex-basis: 0;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    // The aside items show at the bottom of the original now.
    display: none;
  }
}
.section-title {
  margin-left: 5px;
  margin-right: 20px;
  max-width: 90vw;

  font-weight: normal;
  font-size: 1.125rem;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.show-only-if-collapsed {
  display: none;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
    // The aside items show at the bottom of the original now.
    display: block;
  }
}
.request-verification-btn {
  width: fit-content;
  height: 29px !important;
  margin-top: 0.2rem;
  padding: 0 !important;
  ::v-deep.v-btn__content {
      font-size: 14px !important;
      text-transform: none;
      color: black;
  }
}

// TODO: remove these styles when resetting the default styles for buttons
.v-size--small {
  height: unset !important;
  width: unset !important;
  padding: 2px 12px !important;
  border-width: 1px !important;
  ::v-deep.v-btn__content {
    font-size: 11px !important;
    line-height: 11px !important;
    letter-spacing: 0.55px !important;
  }
}
</style>
