<template>
  <!-- Product cell representing an order -->
  <div
    :id="`order-${order}`"
    class="py-2 order-cell">
    <!-- Slot for additional actions -->
    <slot
      name="actions" />
    <!-- Display the order number -->
    <span>
      {{ order }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'ProductCellOrder',
  props: {
    order: {
      type: Number,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.order-cell {
  min-height: 10px;
  gap: 3px;
  display: grid;
  min-width: 24px;
}
</style>
