<template>
  <div class="schedule-container app-container-flex__full">
    <ProjectDetailsTitle
      :detail-project-data="detailProjectData"
      :user-info="userInfo"
      class="mx-auto mt-3 flex-shrink-0"
      :user-id="userId" />
    <div
      class="select-view-container o-hidden">
      <div class="d-flex align-center mt-2">
        <ProjectLogo />
        <v-row
          id="select-view-container"
          class="my-sm-6 mx-sm-8 d-flex buttons-wrapper justify-end my-0 mx-2"
          style="position: relative">
          <div class="d-flex flex-wrap mr-auto">
            <Views
              id="select-view-container"
              data-test="select_view_dropdown_button" />
            <Versions
              v-if="versionsLength && !hideVersions && expandedMobileActions"
              id="select-version-container"
              class="versions"
              style="position: relative"
              @updateVersionRow="getDefaultRowsForView" />
            <template
              v-if="detailProjectData !== null && role !== 'viewer' && expandedMobileActions">
              <CreateIssuance
                @updateVersionRow="getDefaultRowsForView" />
            </template>
            <CompareViews
              v-if="!hideCompareViews && expandedMobileActions"
              id="select-compare-version-container"
              data-test="compare_button"
              style="position: relative" />
            <div v-if="!isUnsuccessfulPayment && expandedMobileActions && !hideDocuments">
              <Documents
                :images="images"
                data-test="project_documents_button"
                @saveProjectDocuments="saveProjectDocuments"
                @deleteFiles="deleteFiles"
                @getDocuments="getDocuments({ projectId, scheduleId })"
                @error="onError"
                @clearAllItems="images = []"
                @getUrlForValues="getUrlForValues" />
            </div>
            <ProjectDetailsSearchCells v-if="showSearchCellsBlock && !hideFindInView" />
          </div>
          <SummaryComments
            v-if="isShowSummaryComments"
            :is-rows-loading="progressLinear.show"
            :data="dataForSummaryComments"
            :general-comments-modal.sync="generalCommentsModal"
            @goToRowComments="goToRowComments = !goToRowComments" />
          <v-btn
            v-if="isVisiblePrint"
            outlined
            class="print-doc"
            @click="generateReport">
            <template>
              <v-icon
                size="32"
                color="#8C8C8C">
                mdi-printer-settings
              </v-icon>
              Export to PDF
            </template>
          </v-btn>
          <div v-show="showExportToBlock">
            <ExportTo
              ref="exportToRef"
              :is-shared-link="isSharedLink"
              table-type="schedule" />
          </div>
        </v-row>
      </div>
      <AppExpandMobileActions
        v-if="$vuetify.breakpoint.smAndDown"
        :show-mobile-actions.sync="showMobileActions" />
      <project-details-summary-budget-view v-if="canViewSummaryProjectBudgetViewPanel" />
      <ProjectDetailsSummarySustainabilityView v-if="canViewSummarySustainabilityViewPanel" />
      <div
        class="table-container"
        :class="!isListingView && 'hide-scroll'">
        <div />
        <div v-if="isLoadingRowData">
          <ListingSkeleton v-if="selectedView && isListingView" />
          <ScheduleSkeleton
            v-else
            :body-rows="10" />
        </div>
        <ProjectDetailsGalleryBlock
          v-show="selectedView && isListingView"
          :is-shown="selectedView && isListingView"
          :data="currentViewInfo" />
        <ProjectDetailsTableSchedule
          ref="tableScheduleWrapper"
          custom-id="regularTable"
          :is-show-table="selectedView && !isListingView"
          :shared-link="sharedLink"
          :is-free-workspace="isFreeWorkspace"
          :go-to-row-comments="goToRowComments"
          :is-listing-view="isListingView"
          :disabled-edit-mode="disabledEditMode"
          :data="dataArr"
          @getDocuments="getDocuments({ projectId, scheduleId })"
          @onDragEnd="sortHeadersAfterDrag" />
        <AppProgressLinear />
        <ProjectDetailsFooterActions
          :class="isShowProgressLinear && 'pb-sm-5'"
          :is-listing-view="isListingView"
          :is-version-mode="isVersionMode"
          :hide-adding-new-row="hideAddingNewRow"
          :hide-remove-project="hideRemoveProject"
          :schedule-id="scheduleId"
          :shared-link="sharedLink"
          :project-id="projectId"
          :selected-view-id="selectedViewId"
          :hide-shared-link="hideSharedLink" />
      </div>
      <ProjectDetailsRowExpandedView
        :disabled-edit-mode="disabledEditMode"
        :row-id="modalRowId"
        :item="currentRowItem"
        :mode="modalMode"
        @updateCell="updateCell" />
      <CollectionsCreateProductDialog
        :is-allow-comparison="false"
        :is-dialog-collection="false" />
    </div>
  </div>
</template>
<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import ProjectDetailsTableSchedule from '@/components/ProjectDetails/ProjectDetailsTableSchedule';
import ExportTo from '@/components/ProjectDetails/ProjectDetailsExportTo';
import CollectionsCreateProductDialog
  from '@/components/Collections/CollectionsCreateProductDialog';
import CreateIssuance from '@/components/ProjectDetails/ProjectDetailsCreateIssuance';
import Versions from '@/components/ProjectDetails/ProjectDetailsVersions';
import Views from '@/components/ProjectDetails/ProjectDetailsViews';
import CompareViews from '@/components/ProjectDetails/ProjectDetailsCompareViews';
import SummaryComments from '@/components/ProjectDetails/ProjectDetailsSummaryComments';
import Documents from '@/components/ProjectDetails/ProjectDetailsDocuments';
import ProjectLogo from '@/components/ProjectDetails/ProjectDetailsLogo';
import ScheduleDocuments from '@/mixins/ScheduleDocuments';
import UndoActions from '@/mixins/UndoActions';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import LoadingSpinnerReset from '@/mixins/LoadingSpinnerReset';
import CustomScrollLayout from '@/mixins/CustomScrollLayout';
import productHeaders from '@/constants/productHeaders';
import SetGalleryViewToAllScheduleViews from '@/mixins/SetGalleryViewToAllScheduleViews';
import DisableEditMode from '@/mixins/DisableEditMode';
import { v4 as uuid } from 'uuid';
import {
  applyChangesToAnotherArray, sortHelper,
} from '@/utils';
import { cloneDeep } from 'lodash';
import {
  SORTABLE_ACTIONS_WIDTH, DEFAULT_PROJECT_VERSION,
} from '@/constants/scheduleViews.js';
import ProjectDocumentsApi from '@/services/graphql/projectDocuments';
import ParseItemsAttachment from '@/mixins/ParseItemsAttachment';
import { TYPE_DOCUMENTS } from '@/constants/projectDocument';
import ProjectDetailsSummaryBudgetView
  from '@/components/ProjectDetails/ProjectDetailsSummary/ProjectDetailsSummaryBudgetView/index.vue';
import ProjectDetailsSummarySustainabilityView
  from '@/components/ProjectDetails/ProjectDetailsSummary/ProjectDetailsSummarySustainabilityView/index.vue';
import ProjectDetailsTitle
  from '@/components/ProjectDetails/ProjectDetailsTitle';
import AppExpandMobileActions
  from '@/components/App/AppExpandMobileActions';
import ProjectDetailsGalleryBlock
  from '@/components/ProjectDetails/ProjectDetailsGalleryBlock/index.vue';
import ListingSkeleton from '@/components/App/AppSkeleton/ListingSkeleton/index.vue';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton/index.vue';
import ProjectDetailsRowExpandedView
  from '@/components/ProjectDetails/ProjectDetailsRowExpandedView/index.vue';
import ProjectDetailsFooterActions
  from '@/components/ProjectDetails/ProjectDetailsFooterActions/index.vue';
import ProjectDetailsSearchCells
  from '@/components/ProjectDetails/ProjectDetailsSearchCells/index.vue';
export default {
  name: 'ProjectDetails',
  components: {
    ProjectDetailsSearchCells,
    AppProgressLinear: () => import('@/components/App/AppProgressLinear'),
    ProjectDetailsFooterActions,
    ProjectDetailsRowExpandedView,
    ScheduleSkeleton,
    ListingSkeleton,
    ProjectDetailsGalleryBlock,
    AppExpandMobileActions,
    ProjectDetailsTitle,
    ProjectDetailsSummarySustainabilityView,
    ProjectDetailsSummaryBudgetView,
    ProjectDetailsTableSchedule,
    ExportTo,
    CreateIssuance,
    Versions,
    Views,
    CompareViews,
    SummaryComments,
    Documents,
    CollectionsCreateProductDialog,
    ProjectLogo,
  },
  mixins: [
    ScheduleDocuments,
    UndoActions,
    SetGalleryViewToAllScheduleViews,
    ToggleViewCondition,
    DisableEditMode,
    LoadingSpinnerReset,
    CustomScrollLayout,
    ParseItemsAttachment,
  ],
  props: {
    hideSummaryComments: {
      type: Boolean,
      default: false,
    },
    isSharedLink: {
      type: Boolean,
      default: false,
    },
    hideVersions: {
      type: Boolean,
      default: false,
    },
    hideCompareViews: {
      type: Boolean,
      default: false,
    },
    hideSharedLink: {
      type: Boolean,
      default: false,
    },
    hideRemoveProject: {
      type: Boolean,
      default: false,
    },
    hideAddingNewRow: {
      type: Boolean,
      default: false,
    },
    hideDocuments: {
      type: Boolean,
      default: false,
    },
    hideFindInView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isVisibleTable: false,
    isMasonryPage: true,
    generalCommentsModal: false,
    goToRowComments: false,
    images: [],
    tableType: 'schedule',
    showMobileActions: false,
    firstUpload: true,
    intervalId: null,
    printFormat: 'tabloid',
    inc: 96,
  }),
  computed: {
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState([
      'role',
      'subscriptions',
      'showSpinner',
      'userInfo',
      'isLoadingRowData',
      'progressLinear',
    ]),
    ...mapState('ProjectDetailsTableSchedule',
      [
        'scheduleId',
        'creatorLogo',
        'defaultFields',
        'modalRowId',
        'modalMode',
      ]),
    ...mapState('ScheduleViews', ['activeTags', 'selectedViewId']),
    ...mapGetters([
      'premiumGroup',
      'collaboratorGroup',
      'userId',
      'isShowProgressLinear',
    ]),
    ...mapGetters({
      customViews: 'ScheduleViews/customViews',
    }),
    ...mapGetters('ScheduleRows', ['currentRowItem', 'viewInfoToRendering', 'sortedViewInfo']),
    ...mapGetters('Workspace', ['isFreeWorkspace']),
    ...mapGetters('ScheduleViews', ['isSelectedProjectBudgetView', 'isSelectedSustainabilityView', 'sortingData', 'selectedView', 'getSelectedViewById', 'tableHeaders']),
    ...mapGetters('UserProfile', ['isUnsuccessfulPayment']),
    ...mapGetters({
      userId: 'userId',
    }),
    ...mapGetters('FeatureFlags', [
      'isUsedQuickIndex',
      'isVisiblePrintButton',
    ]),
    ...mapGetters('UserRoles', ['canViewSummaryProjectBudgetView', 'canViewSummarySustainabilityView', 'canUseExportToForCollaboratorRole']),
    ...mapGetters('ProjectVersions', ['versionsLength', 'isVersionMode', 'getCurrentVersion']),
    ...mapGetters('ProjectDetails', ['getDetailProjectTitle']),
    ...mapGetters('ProjectDetails', ['getDetailProjectTitle']),
    /**
     * @typedef {object} DataProperties
     * @property {boolean} canUseExportToForCollaboratorRole - Indicates whether a user with the "collaborator" role can use the Export To feature.
     * @property {object|null} detailProjectData - The project data to be displayed.
     * @property {boolean} expandedMobileActions - Indicates whether mobile actions are expanded.
     * @property {string} role - The user's role in the project.
     */

    /**
     * Determines whether the Export To Block should be displayed.
     *
     * @returns {boolean} True if Export To Block should be displayed, false otherwise.
     */
    showExportToBlock() {
      /**
       * @type {DataProperties}
       */
      const { canUseExportToForCollaboratorRole: canUseExportTo, detailProjectData, expandedMobileActions, role } = this;

      return canUseExportTo(role) && detailProjectData !== null && expandedMobileActions;
    },

    /**
     * Check if the summary comments should be shown.
     *
     * @return {boolean} Returns true if the summary comments should be shown, otherwise false.
     */
    isShowSummaryComments() {
      /**
       * Properties related to the current data.
       *
       * @typedef {object} DataProperties
       * @property {boolean} hideSummaryComments - A boolean flag indicating whether summary comments should be hidden.
       * @property {string} scheduleId - A unique identifier for the schedule.
       */

      /** @type {DataProperties} */
      const { hideSummaryComments, scheduleId } = this;

      return !hideSummaryComments && !!scheduleId;
    },
    isVisiblePrint() {
      return this.detailProjectData && !this.isListingView && this.isVisiblePrintButton;
    },
    getWidthOfTable() {
      let tableWidth = 0;
      for (let i = 0; i < this.tableHeaders.length; i++) {
        const column = this.tableHeaders[i];
        const {
          columnSize = {
            size: 60,
            isCustomSortable: false,
          },
        } = column ?? {
        };
        const {
          size,
          isCustomSortable,
        } = columnSize;
        if (isCustomSortable) {
          tableWidth += size + SORTABLE_ACTIONS_WIDTH;
        } else {
          tableWidth += size;
        }
      }
      let listWidth;
      let result;
      if (this.printFormat === 'tabloid') {
        // Tabloid = 11×17
        listWidth = 17 * this.inc;
        result = 100 * (tableWidth / listWidth) * 1.15;
      }
      if (this.printFormat === 'a0') {
        // A0 = 33.1×46.8
        listWidth = 33.1 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      if (this.printFormat === 'a1') {
        // A1 = 23.4×33.1
        listWidth = 23.4 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      if (this.printFormat === 'a2') {
        // A2 = 16.5×23.4
        listWidth = 16.5 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      return Math.ceil(result);
    },
    htmlToPdfOptions() {
      return {
        margin: 10,
        filename: `${this.getCurrentView} - ${this.getDetailProjectTitle}.pdf`,
        enableLinks: true,
        image: {
          type: 'jpeg', quality: 2,
        },
        html2canvas: {

          scale: 2,
          removeContainer: true,
          useCORS: true,
        },
        jsPDF: {

          format: this.printFormat,
          orientation: 'landscape',
          quality: 2,
        },
      };
    },
    getScale() {
      const width = this.getWidthOfTable;
      const result = 100 / width;
      if (result > 1) {
        return 1;
      }
      return result;
    },
    showSearchCellsBlock() {
      const { isShowProgressLinear, dataArr } = this;
      return !isShowProgressLinear && dataArr.length;
    },
    getCurrentView() {
      const { name = '' } = this.selectedView ?? {
      };
      return name;
    },
    dataArr() {
      return this.viewInfoToRendering;
    },
    isListingView() {
      return this.toggleViewCondition(this.selectedViewId);
    },
    expandedMobileActions() {
      const isMobile = this.$vuetify.breakpoint.smAndDown;
      if (!isMobile) {
        return true;
      }
      return this.showMobileActions;
    },
    renderedCustomDefaultFields() {
      const customDefaultFields = this.selectedView?.customDefaultFields;
      return customDefaultFields?.length ? customDefaultFields : this.defaultFields;
    },
    currentViewInfo: {
      get() {
        return this.sortedViewInfo;
      },
      set(val) {
        this.setViewInfo(val);
      },
    },
    dataForSummaryComments() {
      return this.currentViewInfo.map(item => ({
        id: item.id,
        type: item[productHeaders.TYPE_DESIGNATION],
        commentsAmount: item.commentsAmount || 0,
      }));
    },
    projectId() {
      const {
        params = {
        },
      } = this.$route ?? {
      };
      const { id = '' } = params;
      return id;
    },
    canViewSummaryProjectBudgetViewPanel() {
      return this.canViewSummaryProjectBudgetView(this.role).allowed
        && this.isSelectedProjectBudgetView;
    },
    canViewSummarySustainabilityViewPanel() {
      return this.canViewSummarySustainabilityView(this.role).allowed
        && this.isSelectedSustainabilityView;
    },
    sharedLink() {
      return this.isSharedLink;
    },
  },
  watch: {
    getCurrentVersion(val) {
      val !== DEFAULT_PROJECT_VERSION ? this.unsubscribeRowRelated() : this.subscribeRowRelated();
    },
    selectedViewId(val, oldVal) {
      try {
        const oldView = this.getSelectedViewById(oldVal);
        this.resetState();
        if (!this.firstUpload && oldView?.sortingField !== this.selectedView?.sortingField) {
          this.setViewsSorting({
            sortingData: sortHelper(this.sortingData.viewId, this.selectedView.sortingField),
          });
        }
        if (this.firstUpload) {
          if ((!oldView || oldView?.filterTag
            || oldView?.sortingField !== this.selectedView?.sortingField) && this.selectedView || this.selectedView?.filterTag) {
            this.getDefaultRowsForView();
          }
          this.firstUpload = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    const { projectId, activeWorkspaceId, sharedLink, setUuidToUpdateCellRequest: setUuid } = this;
    setUuid(); // Call the setUuidToUpdateCellRequest method to generate and set the UUID
    this.changeRole('viewer');
    await this.getProjectPermissions();
    this.needUserPremium({
      workspaceId: activeWorkspaceId,
      resourceType: 'project',
      resourceId: projectId,
    });
    await this.getListProjectVersions({
      projectId,
    });
    this.getProjectAggregation({
      projectId,
    });
    await this.getProjectByIdHandle(projectId);
    await this.getSchedule({
      sharedLink,
      projectId,
    });
    const { scheduleId } = this;
    this.getDocuments({
      projectId, scheduleId,
    });
    this.setGalleryViewOnMobile();
    this.userId && this.subscribeRowRelated();
  },
  beforeDestroy() {
    this.clearCommentsModalTrigger();
  },
  destroyed() {
    this.setNeedUserPremium(false);
    this.unsubscribeRowRelated();
    this.setDetailDataOfProject(null);
    this.setAggregationData(null);
    this.$store.commit('ProjectDetailsTableSchedule/setCreatorLogo', null);
    this.$store.commit('ProjectDetailsTableSchedule/setCreatorLogoKey', null);
    this.$store.commit('ProjectDetailsTableSchedule/setSchema', []);
    this.$store.commit('ProjectDetailsTableSchedule/setScheduleId', null);
    this.$store.commit('Comments/clearSlants');
    this.currentViewInfo = [];
    this.setSelectedViewId(null);
    this.closeProgressLinear();
    this.setScheduleSessionUuid(null);
    this.setCellsVersions({
    });
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      getListProjectVersions: 'ProjectVersions/getListProjectVersions',
      getProjectPermissions: 'ProjectDetailsTableSchedule/getProjectPermissions',
      getSchedule: 'ProjectDetailsTableSchedule/getSchedule',
      getProjectLogo: 'ProjectDetailsTableSchedule/getProjectLogo',
      subscribeCommentsAmount: 'Comments/subscribeCommentsAmount',
      setSelectedViewId: 'ScheduleViews/setSelectedViewId',
      needUserPremium: 'Workspace/needUserPremium',
      setViewInfo: 'ScheduleRows/setViewInfo',
      subscribeDeleteRow: 'ScheduleRows/subscribeDeleteRow',
      getRows: 'ScheduleRows/getRows',
      subscribeUnlockProject: 'ProjectDetailsTableSchedule/subscribeUnlockProject',
      subscribeRowRelated: 'ProjectDetailsTableSchedule/subscribeRowRelated',
      unsubscribeRowRelated: 'ProjectDetailsTableSchedule/unsubscribeRowRelated',
      updateViewColumnOrder: 'ScheduleViews/updateViewColumnOrder',
      updateViewOrder: 'ScheduleViews/updateViewOrder',
      getProjectAggregation: 'ProjectAggregation/getProjectAggregation',
      getProjectById: 'ProjectDetails/getProjectById',
      setViewsSorting: 'ScheduleViews/setViewsSorting',
      clearCommentsModalTrigger: 'ProjectDetailsTableSchedule/clearCommentsModalTrigger',
    }),
    ...mapMutations({
      spinner: 'spinner',
      changeRole: 'changeRole',
      showShareModalAction: 'showShareModalAction',
      openSnackBar: 'openSnackBar',
      setAssociatedDocuments: 'ProjectDetails/setAssociatedDocuments',
      setNeedUserPremium: 'Workspace/setNeedUserPremium',
      setScheduleSessionUuid: 'ProjectDetailsTableSchedule/setScheduleSessionUuid',
      setAggregationData: 'ProjectAggregation/setAggregationData',
      setDetailDataOfProject: 'ProjectDetails/setDetailDataOfProject',
      setModalRowId: 'ProjectDetailsTableSchedule/setModalRowId',
      resetState: 'ScheduleCompare/resetState',
      closeProgressLinear: 'closeProgressLinear',
      setCellsVersions: 'ScheduleRows/setCellsVersions',
    }),
    /**
     * Generates a new UUID and sets it as the schedule session UUID for an update cell request.
     * @returns {void}
     */
    setUuidToUpdateCellRequest() {
      const version = uuid();
      this.setScheduleSessionUuid(version);
    },
    hasDownloaded() {
      this.isVisibleTable = false;
    },
    generateReport() {
      const exportToMenu = this.$refs.exportToRef;
      if (exportToMenu) {
        exportToMenu.externalPrint();
      } else {
        console.error("Couldn't find Export Menu in Refs!");
      }
    },
    updateCell(data) {
      const { tableScheduleWrapper: wrapper } = this.$refs ?? {
      };
      if (!wrapper) return;
      /**
       * call updateCell method from ProjectDetailsTableSchedule
       * TODO: need to refactor
       */
      wrapper.updateCell(data);
    },
    async getDocuments({ projectId = '', scheduleId = '' }) {
      if (!scheduleId || !projectId) return false;
      const requests = TYPE_DOCUMENTS.map(async ({ type: documentType }) => await this.getDocumentType({
        documentType,
        projectId,
        scheduleId,
      }));
      const response = await Promise.all(requests);
      let documents = {
      };
      for (const doc of response) {
        documents = {
          ...documents, ...doc,
        };
      }
      this.setAssociatedDocuments(documents);
    },
    async getDocumentType(payload) {
      const {
        documentType,
        projectId,
        nextToken = null,
        oldItems = [],
        scheduleId,
      } = payload;
      let items = [];
      try {
        this.spinner(true);
        const { activeWorkspaceId: workspaceId } = this;
        const { data } = await ProjectDocumentsApi.getProjectDocumentsV2({
          workspaceId,
          documentType,
          scheduleId,
          projectId,
          nextToken,
          limit: 100,
        });
        const { data: newItems, nextToken: responseNextToken } = data.response;
        if (newItems) {
          items = [...oldItems, ...newItems];
        }
        if (responseNextToken) return await this.getDocumentType({
          ...payload,
          oldItems: items,
          nextToken: responseNextToken,
        });
        return {
          [documentType]: await this.getImageFromStorage(items),
        };
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async sortHeadersAfterDrag({
      customColumnOrder,
      oldCustomColumnOrder,
      changes,
    }) {
      const clonedDefaultFields = cloneDeep(this.renderedCustomDefaultFields);
      const oldCustomDefaultFields = clonedDefaultFields;
      const customDefaultFields = applyChangesToAnotherArray(customColumnOrder, clonedDefaultFields, changes);
      const { activeWorkspaceId: workspaceId, scheduleId, projectId, selectedView } = this;
      try {
        this.updateViewOrder({
          ...selectedView,
          customColumnOrder,
          customDefaultFields,
        });
        await this.updateViewColumnOrder({
          variables: {
            workspaceId,
            resourceType: 'schedule',
            resourceId: scheduleId,
            projectId,
            viewId: selectedView.id,
            customColumnOrder,
            customDefaultFields,
          },
          updateViewData: false,
        });
      } catch (err) {
        this.updateViewOrder({
          ...selectedView,
          customColumnOrder: oldCustomColumnOrder,
          customDefaultFields: oldCustomDefaultFields,
        });
      }
    },
    handleRequestAnInvite() {
      const { action, email, type, generalComments } = this.$route.query;
      if (action === 'invite' && email && type) {
        this.showShareModalAction();
      } else if (generalComments) {
        this.generalCommentsModal = true;
      }
    },
    async getDefaultRowsForView({ id, isNewVersion } = {
    }) {
      const { query } = this.$route;
      const versionId = id === undefined ? query?.version : id;
      if (versionId) {
        this.subscribeUnlockProject({
          requests: {
            getRowsForVersion: {
              versionId,
              sharedLink: this.sharedLink,
            },
          },
        });
      }
      await this.getRows({
        variables: {
          versionId,
          sharedLink: this.sharedLink,
        },
      });
      if (versionId && !isNewVersion) {
        this.openSnackBar({
          text: 'Project is in Read-Only Mode',
        });
      }
      this.handleRequestAnInvite();
    },
    async getProjectByIdHandle(id) {
      const { sharedLink } = this;
      await this.getProjectById({
        id,
        sharedLink,
      });
      this.getProjectLogo();
    },
  },
};
</script>
<style scoped lang="scss" src="./index.scss"></style>
