<template>
  <div class="d-inline-block">
    <v-menu
      v-model="menu"
      offset-y
      :disabled="!canWorkWithModifyingBasedOnTag.allowed"
      rounded="70"
      content-class="edit-menu"
      :close-on-content-click="false"
      nudge-bottom="2">
      <template #activator="{ on, attrs }">
        <app-icon-btn
          :access="canWorkWithModifyingBasedOnTag"
          :text="helpTipForCreationCustomView"
          bottom
          disabled
          show-tooltip
          show-custom-tooltip-title
          icon-size="18px"
          icon-color="mainGrey"
          icon-name="mdi-plus-circle-outline"
          v-bind="attrs"
          v-on="on">
          Create New View
        </app-icon-btn>
      </template>
      <div class="formToSaveFilteredView">
        <v-text-field
          v-model="view.name"
          placeholder="Enter custom project name"
          class="editName"
          autocomplete="off" />
        <div class="filteredButtons">
          <v-btn
            outlined
            color="lightBlue"
            @click="view.name = ''">
            Clear
          </v-btn>
          <v-btn
            color="lightBlue"
            :disabled="!view.name"
            @click="saveCustomView">
            Save
          </v-btn>
        </div>
      </div>
    </v-menu>
  </div>
</template>
<script>
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
import ProjectViewsApi from '@/services/graphql/projectViews';
import {
  sortHelper,
  convertStringToArray,
} from '@/utils';
import { SORT_TOP } from '@/constants/scheduleViews';
export default {
  name: 'SaveFilteredView',
  props: {
    view: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState(['role']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('ScheduleViews', ['views']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters({
      canModifyCustomViewBasedOnTag: 'UserRoles/canModifyCustomViewBasedOnTag',
    }),
    canWorkWithModifyingBasedOnTag() {
      return this.canModifyCustomViewBasedOnTag(this.role);
    },
    helpTipForCreationCustomView() {
      const { allowed = false } = this.canWorkWithModifyingBasedOnTag ?? {
      };
      if (!allowed) return '';
      const icon = '<span class="mdi mdi-plus-circle-outline"></span>';
      return `Press ${icon} to create a new view from a selected tag`;
    },
  },
  methods: {
    ...mapActions({
      setSelectedViewId: 'ScheduleViews/setSelectedViewId',
      setViews: 'ScheduleViews/setViews',
      handleError: 'handleError',
    }),
    ...mapMutations({
      spinner: 'spinner',
    }),
    async saveCustomView() {
      try {
        this.spinner(true);
        const { data } = await ProjectViewsApi.saveCustomView({
          scheduleId: this.scheduleId,
          projectId: this.$route.params.id,
          viewId: this.view.id,
          viewName: this.view.name,
          filterTag: this.view.filterTag,
          workspaceId: this.activeWorkspaceId,
        });
        const { response } = data;
        this.setViews({
          arrViews: [
            ...this.views,
            {
              ...response,
              filterTag: !response.filterTag ? [] : convertStringToArray(response.filterTag),
              sortingData: sortHelper(response.id, response.sortingField, SORT_TOP),
            },
          ],
        });
        this.setSelectedViewId({
          ...data.response,
          sortingData: sortHelper(response.id, response.sortingField, SORT_TOP),
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.menu = false;
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .formToSaveFilteredView {
    padding: 16px;
    display: grid;
    gap: 20px;
    .filteredButtons {
      display: flex;
      gap: 8px;
    }
  }
</style>
