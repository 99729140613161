var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{class:[{ 'v-data-table-header__draggable': _vm.index > 2 && !_vm.isFocusedOnActions }, _vm.isActiveResizable && 'resizable-element__header', _vm.thClass],style:(_vm.headerName != 'order' && {
    width: (_vm.headerSize + "px"),
  }),attrs:{"data-ref":(_vm.headerName + "__ref"),"data-index":_vm.index,"role":"columnheader","scope":"col","aria-label":""},on:{"click":_vm.onSort,"mouseleave":function($event){return _vm.onMouseLeave(_vm.headerName)},"mouseover":function($event){return _vm.onMouseOver(_vm.headerName)}}},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.deprecated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({staticClass:"v-data-table-header__wrapper",style:({'margin-left': _vm.renderedDraggableRef ? '0' : '-16px'})},_vm.deprecated && on),[(_vm.isAllowedToResize)?_c('vue-draggable-resizable',{staticClass:"d-flex align-center resizable-element",class:{
            'table-container__header-cell': _vm.activeCellHeader === _vm.headerName,
            'table-container__header-cell--hover': _vm.getActiveCellsNames.includes(_vm.headerName),
            'table-container__header-cell--hover-active': _vm.getActiveCellsNames.includes(_vm.headerName) && _vm.activeCellHeader === _vm.headerName,
          },attrs:{"class-name-handle":"resizable-handle","w":_vm.headerSize,"h":48,"min-width":_vm.headerMinSize,"axis":'x',"handles":['mr'],"on-resize":_vm.onResizeCallback,"resizable":_vm.canWorkWithResizeColumn && _vm.isResizable && !_vm.disabledEditMode,"active":_vm.isResizable,"draggable":false},on:{"resizing":function($event){return _vm.onResizeColumn({ width: arguments[2], colName: _vm.headerName, name: _vm.headerName, })},"resizestop":function($event){return _vm.onResizeColumnStop(
            { width: arguments[2],
              colId: _vm.columnId, resourceType: 'schedule',
              projectId: _vm.projectId, resourceId: _vm.scheduleId, viewId: _vm.selectedView.id, colName: _vm.headerName, isCustomSortable: _vm.isCustomSortable})}},scopedSlots:_vm._u([{key:"mr",fn:function(){return [_c('div',{staticClass:"resizable-handle-element",on:{"mouseover":function($event){$event.stopPropagation();_vm.isCanceledResizable ? _vm.setActiveColumn(_vm.headerName): null},"mouseleave":function($event){$event.stopPropagation();return _vm.setHoveredColumn(_vm.isCanceledResizable ? { name: _vm.headerName } : { })}}},[_c('div',{class:{ 'resizable-handle-element__selected-border': _vm.isActiveResizable }}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveResizable),expression:"isActiveResizable"}],staticClass:"resizable-handle-element__selected-border__bottom",style:({height: ((_vm.tableHeight - 48) + "px")})})])]},proxy:true}],null,true)},[(_vm.headerName == 'Tag')?[_c('TableFilterTags',{attrs:{"disabled-edit-mode":_vm.disabledEditMode,"is-free-workspace":_vm.isFreeWorkspace,"value":_vm.headerName}}),(_vm.isCustomSortable && !_vm.renderedDraggableRef)?_c('app-sort-arrows',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"sortable-options":_vm.SORTABLE_OPTIONS},on:{"mouse-action":function($event){return _vm.$emit('focused-on-actions', true)},"mouse-action-end":function($event){return _vm.$emit('focused-on-actions', false)}}}):_vm._e()]:[_c('span',[_vm._v(" "+_vm._s(_vm.headerName)+" "),(_vm.isCustomSortable
                  && !_vm.renderedDraggableRef)?_c('app-sort-arrows',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"sortable-options":_vm.SORTABLE_OPTIONS},on:{"mouse-action":function($event){return _vm.$emit('focused-on-actions', true)},"mouse-action-end":function($event){return _vm.$emit('focused-on-actions', false)}}}):_vm._e()],1)]],2):(_vm.headerName!=='order')?[_c('div',{staticClass:"d-flex align-center table-header__not-resizable",class:{
              'table-container__header-cell': _vm.activeCellHeader === _vm.headerName,
              'table-container__header-cell--hover': _vm.getActiveCellsNames.includes(_vm.headerName),
              'table-container__header-cell--hover-active': _vm.getActiveCellsNames.includes(_vm.headerName) && _vm.activeCellHeader === _vm.headerName,
            }},[_c('span',[_vm._v(_vm._s(_vm.headerName))]),(_vm.isCustomSortable
                && !_vm.renderedDraggableRef)?_c('app-sort-arrows',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"sortable-options":_vm.SORTABLE_OPTIONS},on:{"mouse-action":function($event){return _vm.$emit('focused-on-actions', true)},"mouse-action-end":function($event){return _vm.$emit('focused-on-actions', false)}}}):_vm._e()],1)]:_vm._e(),(_vm.isCustomSortable
            && !_vm.renderedDraggableRef
            && !(_vm.selectedViewTag && _vm.headerName == _vm.productHeaders.TAG))?_c('table-header-actions',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"default-sort-by":_vm.selectedView.sortingField},on:{"sort":function($event){_vm.setViewsSorting({sortingData: _vm.sortHelper(_vm.selectedViewId, $event.sortBy, $event.sortOrder)})}}}):_vm._e()],2)]}}])},[_c('span',[_vm._v("Deprecated")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }