<template>
  <div class="comments-wrapper pt-6">
    <p
      v-if="cellPointer"
      class="comments-wrapper__cell-title small-p">
      <span
        data-test="row_comments_return_to_row_comments"
        class="pointer related-link"
        @click="setCellPointer(undefined)">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
        Return to row comments
      </span>
      <span>
        {{ cellPointer }} comments
      </span>
    </p>
    <p
      v-if="data.nextToken"
      class="medium-p mb-0 mx-8 mb-2 text-center show-more-comments"
      @click="$emit('getRowComments', {
        scanIndexForward: false,
      })">
      <span class="pointer">
        Show Earlier Comments
      </span>
    </p>
    <v-row
      v-for="(comment, index) in filterComments"
      :id="`commentId_${comment.id}`"
      :key="comment.id"
      ref="commentsRows"
      data-test="project_comment_block"
      class="comments-wrapper-border-items"
      :class="{ 'mb-0' : index === data.comments.length -1,
                'comments-wrapper-border-items__edited': editedRowId === comment.id,
                'comments-wrapper-border-items__scrolled-comment': scrolledComment === comment.id,
      }"
      @mouseover="!$route.query.version ? hoverRowId=comment.id : hoverRowId=null"
      @mouseleave="hoverRowId=null">
      <div>
        <AvatarImage
          :avatar="avatars[getCreatorProp({ comment })]"
          :avatar-key="getCreatorProp({ comment })"
          :color="getCreatorColor({ comment })"
          :size="40" />
      </div>
      <v-col
        class="pa-0"
        sm="11"
        cols="12"
        :class="{'pl-3': $vuetify.breakpoint.mdAndUp }">
        <v-row class="ma-0 d-flex justify-space-between align-center">
          <UserInfo
            :user-info="comment.creator">
            <template
              v-slot="{
                userProjectTeamRole: teamRole,
                userNameTitleAccordingToRole: userNameTitle
              }">
              <p
                data-test="project_comment_author_name"
                class="medium-p font-weight-medium mb-0"
                style="overflow-wrap: anywhere">
                {{ userNameTitle }}
                <span
                  v-if="teamRole"
                  class="subtitle-2">
                  {{ teamRole }}
                </span>
              </p>
            </template>
          </UserInfo>
          <p
            data-test="project_comments_time"
            class="small-p title-in-modal mb-0 d-flex">
            {{ setDateOfComment(comment) }}
            {{ showCommentEditedTitle(comment) }}
            <Actions
              v-if="editMode && !isDisabledComments"
              :hover-row-id="hoverRowId"
              :comment="comment"
              @editRowComment="editRowComment(comment)"
              @manageCellCommentResolved="manageCellCommentResolved(comment)"
              @deleteRowComment="deleteRowComment(comment)" />
          </p>
        </v-row>
        <v-row class="ma-0 d-flex flex-column">
          <p
            data-test="project_comment_author_email"
            class="small-p title-in-modal mb-3 "
            :class="{ 'unread-container' : commentsUnread(comment) }">
            {{ getCreatorProp({comment, prop: 'email'}) }}
            <v-icon
              size="14"
              class="pl-2 pointer">
              {{ setCommentIcon(comment) }}
            </v-icon>
            <template
              v-if="!isDisabledComments">
              <v-tooltip
                bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    data-test="project_comment_resolve_button"
                    :size="isShowResolveAction(comment) ? 22 : 16"
                    v-bind="attrs"
                    class="resolve-reveal-icon"
                    v-on="on"
                    @click="manageCellCommentResolved(comment)">
                    {{ setResolveIcon(comment) }}
                  </v-icon>
                </template>
                <span>{{ setResolveTooltip(comment) }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="!isShowResolveAction(comment)"
                bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    medium
                    v-bind="attrs"
                    class="resolve-reveal-icon"
                    v-on="on"
                    @click="manageRevealing(comment)">
                    {{ setExpandedIcon(comment.id) }}
                  </v-icon>
                </template>
                <span>Reveal</span>
              </v-tooltip>
            </template>
          </p>
        </v-row>
        <v-row class="ma-0 flex-column comment-file">
          <p
            v-if="comment.cellPointer"
            class="small-p title-in-modal">
            Related to <strong>{{ comment.cellPointer }}</strong>
          </p>
          <p
            v-if="comment.commentType !== 'attachment'"
            v-safe-html="formattedTextareaValueForDiv(comment) || ''"
            v-linkified
            data-test="project_comment_text"
            class="small-p mb-0"
            :class="{
              'resolved-comment' : comment.cellCommentResolved,
              'resolved-comment__expanded': expandedComments.includes(comment.id),
            }"
            style="overflow-wrap: anywhere" />
          <div
            v-else-if="comment.commentType === 'attachment'"
            target="_blank"
            :class="{
              'resolved-comment' : comment.cellCommentResolved,
              'resolved-comment__expanded': expandedComments.includes(comment.id),
            }"
            class="comment-file-container"
            @click.stop="attachmentClicked(comment.documentId)">
            <app-dialog
              ref="dialog"
              v-model="isDialogOpen"
              :value.sync="isDialogOpen"
              content-class="v-dialog__form attachments-documents-wrapper"
              @click:outside="closePreviewDialog">
              <v-overlay class="v-overview__preview-image">
                <v-icon
                  class="preview-close"
                  @click="closePreviewDialog">
                  mdi-close
                </v-icon>
                <img
                  :src="attachmentPreviewUrl"
                  class="preview-image">
                <v-btn
                  class="inverted-btn preview-download"
                  color="grey"
                  outlined
                  @click="downloadFile(comment.documentId)">
                  Download
                </v-btn>
              </v-overlay>
            </app-dialog>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-row
                  no-gutters
                  v-bind="attrs"
                  class="comment-file-container__content pointer"
                  v-on="on">
                  <v-col
                    class="pa-0"
                    cols="12">
                    <img
                      v-if="!comment.thumbnailId || !getThumbnail(comment.thumbnailId)"
                      src="@/assets/icons/file-icon.svg">
                    <p
                      v-if="!comment.thumbnailId"
                      class="small-p mb-0 overflow-dots"
                      style="max-width: 105px">
                      {{ comment.documentId.split('/')[1] || '' }}
                    </p>
                    <img
                      v-if="comment.thumbnailId"
                      v-lazy="{
                        src: getThumbnail(comment.thumbnailId),
                        loading: require('@/assets/icons/file-icon.svg'),
                      }"
                      class="img-screen thumbnail-img">
                  </v-col>
                  <v-col
                    class="pa-0"
                    cols="12">
                    <p
                      class="small-p download-p mb-0 d-flex"
                      :style="{
                        justifyContent: comment.thumbnailId? 'flex-start' : 'initial',
                      }">
                      <a
                        :href="`/getFile?fileId=${comment.documentId}&force_update=true`"
                        class="ml-0"
                        @click.prevent="downloadFile(comment.documentId)">
                        Download
                      </a>
                    </p>
                  </v-col>
                </v-row>
              </template>
              <span>
                {{ comment.documentId.split('/')[1] || '' }}
              </span>
            </v-tooltip>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <p
      v-if="data.nextTokenForUnread && !type"
      class="medium-p mb-0 mt-2 mx-8 text-center show-more-comments"
      @click="$emit('getRowComments', {
        scanIndexForward: true,
      })">
      <span class="pointer">
        Show the Latest Comments
      </span>
    </p>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import Actions from '../RowCommentsActions';
import linkify from 'vue-linkify';
import focusOutModal from '@/mixins/FocusOutModal';
import { Storage } from 'aws-amplify';
import LoadingFileSvg from '@/assets/icons/file-icon.svg';
import { isImageUrl } from '@/utils/url';
export default {
  name: 'RowComments',
  components: {
    Actions,
    UserInfo: () => import('@/components/App/AppShareModal/AppShareModalUserInfo'),
  },
  directives: {
    linkified: linkify,
  },
  mixins: [focusOutModal],
  props: {
    allowResolveComments: {
      type: Boolean,
      default: false,
    },
    filesSrc: {
      type: Object,
      default: () => {
      },
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    editedRowId: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => ({
        comments: [],
        nextToken: null,
      }),
    },
    type: {
      type: String,
      default: null,
    },
    rowId: {
      type: String,
      default: null,
    },
    scrolledComment: {
      type: String,
      default: null,
    },
    isDisabledComments: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isDialogOpen: false,
    previewId: '',
    previewUrl: '',
    hoverRowId: null,
    expandedComments: [],
  }),
  computed: {
    ...mapState(['avatars', 'userInfo']),
    ...mapState('ProjectDetailsTableSchedule', ['cellPointer']),
    ...mapState('Comments', ['hideResolvedComments']),
    slant() {
      return this.$store.getters['Comments/slant'](this.rowId);
    },
    filterComments() {
      if (this.hideResolvedComments) {
        return this.data.comments.filter(el => !el.cellCommentResolved);
      }
      return this.data.comments;
    },
    attachmentPreviewUrl() {
      return this.previewUrl ?? LoadingFileSvg;
    },
  },
  watch: {
    isDialogOpen(val) {
      if (val) {
        this.createFocusEvent();
      } else {
        this.removeFocusEvent();
      }
    },
  },
  destroyed() {
    this.expandedComments = [];
    this.$emit('update:filesSrc', {
    });
    this.removeFocusEvent();
  },
  methods: {
    ...mapActions(['getFileFromS3']),
    ...mapMutations('ProjectDetailsTableSchedule', ['setCellPointer']),
    getCreatorColor({ comment }) {
      return this.getCreatorProp({
        comment, prop: 'id',
      })?.substring(0, 6);
    },
    getCreatorProp({ comment, prop = 'picture' }) {
      return comment.creator && comment.creator[prop];
    },
    getThumbnail(id) {
      return this.filesSrc[id];
    },
    setExpandedIcon(id) {
      return this.expandedComments.includes(id) ?
        'mdi-chevron-up' : 'mdi-chevron-down';
    },
    setResolveIcon(comment) {
      return this.isShowResolveAction(comment) ?
        'mdi-checkbox-marked-circle' : 'mdi-history';
    },
    setResolveTooltip(comment) {
      if (!this.editMode) {
        return 'Go to row comments to resolve or unresolve the comment';
      }
      return this.isShowResolveAction(comment) ?
        'Resolve' : 'Re-open';
    },
    isShowResolveAction({ cellCommentResolved }) {
      return !cellCommentResolved;
    },
    showCommentEditedTitle({ dateModified, cellCommentResolved }) {
      let title = undefined;
      if (cellCommentResolved) {
        title = '(resolved)';
      } else if (dateModified) {
        title = '(edited)';
      }
      return title;
    },
    commentsUnread(comment) {
      const {
        type,
        slant,
        userInfo,
        allowResolveComments,
      } = this;
      const {
        id = '',
        creator,
      } = comment || {
      };
      const { email: emailFromComment = '' } = creator || {
      };
      const { email: emailCurrentUser = '' } = userInfo || {
      };
      const { unreadMessageIDs } = slant || [];
      const unreadComment = unreadMessageIDs?.includes(id);
      const useInGeneralChat = allowResolveComments;
      return unreadComment && (!type || useInGeneralChat) && emailCurrentUser !== emailFromComment;
    },
    setDateOfComment(comment) {
      const { dateModified, createdDate } = comment;
      const date = dateModified ? dateModified : createdDate;
      return this.$moment(date).fromNow();
    },
    async downloadFile(key) {
      this.getFileFromS3({
        key,
        forceUpdate: true,
      });
    },
    manageCellCommentResolved(comment) {
      this.$emit('manageCellCommentResolved', comment);
    },
    manageRevealing({ cellCommentResolved, id }) {
      const { expandedComments } = this;
      if (!cellCommentResolved || expandedComments.includes(id)) {
        this.expandedComments = expandedComments.filter(commentId => commentId !== id);
      } else {
        this.expandedComments = [...expandedComments, id];
      }
    },
    deleteRowComment(comment) {
      this.$emit('deleteRowComment', comment);
    },
    editRowComment(comment) {
      this.$emit('editRowComment', comment);
    },
    setCommentIcon({ commentType, privacy }) {
      let icon = '';
      if (privacy === 'private') {
        icon = commentType === 'offer' ? 'mdi-currency-usd' : 'mdi-lock-outline';
      } else if (commentType === 'attachment') {
        icon = 'mdi-attachment';
      }
      return icon;
    },
    formattedTextareaValueForDiv(comment) {
      if (comment.message) {
        let formattedString = comment.message.replace(/\r?\n/g, '<br>');
        comment.mentioned.forEach((user) => {
          const { email = '' } = user || {
          };
          if (user) {
            const getAllIndexes = (arr, val) => {
              const indexes = [];
              let i = -1;
              while ((i = arr.indexOf(val, i + 1)) != -1) {
                indexes.push(i);
              }
              return indexes;
            };
            const indexes = getAllIndexes(formattedString, `@${email}`);
            indexes.forEach((foundIndex, indexOfArr) => {
              let index = foundIndex;
              if (indexOfArr !== 0) {
                index = foundIndex + indexOfArr * 7;
              }
              if (index >= 0) {
                formattedString = `${formattedString.slice(0, index)}<a>${formattedString
                  .slice(index, index + email.length + 1)}</a>${formattedString
                  .slice(index + email.length + 1)}`;
              }
            });
          }
        });
        return formattedString;
      }
      return '';
    },
    async attachmentClicked(docId) {
      if (isImageUrl(docId)) {
        this.openPreviewDialog();
        if (this.previewId != docId) {
          try {
            const res = await Storage.get(docId, {
              level: 'public',
            });
            this.previewUrl = res;
          } catch (err) {
            console.error('Failed to get url for img: ', docId);
            console.error(err);
          }
        }
      } else {
        window.open(`/getFile?fileId=${docId}`, '_blank');
      }
    },
    openPreviewDialog() {
      this.isDialogOpen = true;
    },
    closePreviewDialog() {
      this.isDialogOpen = false;
    },
  },
};
</script>
<style scoped lang="scss">
.comments-wrapper {
  display: grid;
  gap: 13px;
  &__cell-title {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    background: white;
    text-align: center;
  }
  &-border-items {
    border-bottom: 1px solid #E2E4E4;
    width: 100%;
    margin: 0;
    padding: 0px 20px 16px 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    &:last-child {
      border-bottom: none;
    }
    &__edited {
      border: 1px solid lightBlue;
      opacity: 0.8;
    }
    &__scrolled-comment {
      background: #f3f3f9;
    }
  }
  .resolve-reveal-icon {
    margin-left: 4px;
    &.mdi-checkbox-marked-circle:hover {
      color: var(--v-success-base);
    }
  }
}
.show-more-comments {
  color: var(--v-lightBlue-base);
  &:hover {
    opacity: 0.6;
  }
}
.resolved-comment, .resolved-comment br {
  &__expanded {
    display: block;
  }
  display: none;
  color: var(--v-mainGrey-base);
}
.comment-file {
  display: grid;
  justify-content: start;
  gap: 5px;
  &-container {
    max-width: 105px;
    &__content {
      display: grid;
      img {
        border-radius: 5px;
      }
    }
  }
}
.unread-container {
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: var(--v-lightBlue-base);
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.v-overlay--active {
  ::v-deep .v-overlay__content {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
.preview-close {
  position: fixed;
  top: 24px;
  right: 48px;
  z-index: 1000000;
}
.preview-image {
  display: block;
  margin: 0 auto;
  max-width: 70%;
  max-height: 58vh;
  align-self: center;
  margin-bottom: 80px;
}
.preview-download {
  position: fixed;
  bottom: 24px;
  right: 48px;
  z-index: 1000000;
}
</style>
