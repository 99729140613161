<template>
  <ProductCellCurrencyLayout
    :id="item.id"
    :is-expanded-mode="isExpandedMode"
    :edit-mode="editMode"
    :disabled-edit-mode="disabledEditMode"
    :col="col"
    :value-for-rendering="valueForRendering">
    <template #inputBlock>
      <v-text-field
        ref="currencyField"
        v-model="newValue"
        v-currency="currencyOptions"
        class="border-outline"
        clearable
        :disabled="!editMode"
        hide-details
        prefix="$"
        :outlined="isExpandedMode"
        :autofocus="!expandedRow"
        @keyup.enter="update"
        @blur="update" />
    </template>
  </ProductCellCurrencyLayout>
</template>
<script>
import ProductCellCurrencyNumber from '@/mixins/ProductCellCurrencyNumber';
export default {
  name: 'ProductCellCurrencyV2',
  components: {
    ProductCellCurrencyLayout: () => import('./ProductCellCurrencyLayout'),
  },
  mixins: [ProductCellCurrencyNumber],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: () => null,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    valueForRendering() {
      const { value, $formatCurrency, checkIsInteger } = this;
      return Array.isArray(value) ? value.join(', ') : checkIsInteger($formatCurrency(value));
    },
  },
  methods: {
    calculateNewValue() {
      const val = this.value;
      this.newValue = val && Number.parseFloat(val) !== 0 ? this.valueForRendering.replace('$', '') : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
