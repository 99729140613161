import { hasEditorAccess } from '@/utils';
import {
  mapState, mapGetters,
} from 'vuex';
export default {
  computed: {
    ...mapState(['role', 'roles']),
    ...mapGetters('UserRoles', ['findUserRoleInLibraryForActiveHeader']),
    disabledEditMode() {
      const { role, isReadOnlyModalVariant, isProductPreview } = this;
      if (isReadOnlyModalVariant || isProductPreview) {
        return true;
      } else if (this.$route.path.includes('/shared')) {
        return true;
      } else if (['collections'].includes(this.$route.name)) {
        return false;
      } else if (['collection-library', 'collection-product-link'].includes(this.$route.name)) {
        return !hasEditorAccess(this.roles);
      }
      return Boolean(!hasEditorAccess(role) || this.$route.query.version);
    },
  },
};
