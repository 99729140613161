<template>
  <app-dialog
    v-model="showCommentsDialog"
    :value.sync="showCommentsDialog"
    width="1520"
    content-class="v-dialog__form v-dialog__row-expanded-view">
    <v-card
      data-test="row_comments_dialog"
      color="#EDF0F7">
      <v-card-title style="z-index: 9999">
        <RowsSwitcher :is-loading-images="isLoadingImages" />
        <div class="d-flex align-center">
          <v-icon
            data-test="row_comments_dialog_collapse_button"
            color="#fff"
            class="ml-2"
            @click="showCommentsModalTrigger">
            mdi-arrow-collapse
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text class="pa-6">
        <v-row no-gutters>
          <v-col
            :md="(shared && !isSpecSheetModalVariant) ? 12 : 7"
            cols="12"
            class="row-data-list pr-0 mr-5">
            <RowDataList
              v-if="isItemExist"
              :disabled-edit-mode="disabledEditMode"
              :item="item"
              @saveFileProcess="saveFileProcess"
              v-on="$listeners" />
          </v-col>
          <v-col
            v-if="!shared && !isSpecSheetModalVariant"
            class="row-comment px-0">
            <CommentsCard
              v-if="showCommentsDialog"
              :show-comments-modal="showCommentsDialog"
              expanded-modal
              :mode="mode"
              :type="null"
              :dialog-for-comments="dialog"
              :row-id="rowId" />
          </v-col>
          <v-col
            v-if="isSpecSheetModalVariant"
            class="row-comment d-flex flex-column px-0">
            <v-icon
              :size="20"
              color="black"
              class="rounded-pill cursor-pointer px-1 ml-auto"
              @click="changeProductModalVariant(TYPE_READONLY);">
              mdi-close-circle
            </v-icon>
            <embed
              v-if="getProductModalSpecSheetUrl"
              class="flex-grow-1"
              :src="getProductModalSpecSheetUrl"
              type="application/pdf">
            <div v-else>
              Some error occured!
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { TYPE_READONLY } from '@/constants';
import productHeaders from '@/constants/productHeaders';
export default {
  name: 'ProjectDetailsRowExpandedView',
  components: {
    CommentsCard: () => import('@/components/ProjectDetails/ProjectDetailsRowCommentsModal/RowCommentsCard'),
    RowDataList: () => import('./RowDataList'),
    RowsSwitcher: () => import('./ProjectDetailsRowExpandedViewRowsSwitcher'),
  },
  props: {
    rowId: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({
      }),
    },
    mode: {
      type: String,
      default: null,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    dialog: false,
    dialogForComments: false,
    isLoadingImages: false,
    TYPE_READONLY,
  }),
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['showCommentsModal']),
    ...mapGetters('Collections', ['getProductModalSpecSheetUrl', 'isSpecSheetModalVariant']),
    showCommentsDialog: {
      get() {
        return this.showCommentsModal;
      },
      set() {
        this.clearCommentsModalTrigger();
      },
    },
    isItemExist() {
      return Object.keys(this.item).length;
    },
    typeCellValue() {
      if (!this.isItemExist) {
        return '';
      }
      const typeValue = this.item[productHeaders.TYPE_DESIGNATION];
      return typeValue === '\b' ? '' : typeValue;
    },
    shared() {
      return this.$route.path.includes('/shared-project');
    },
  },
  watch: {
    showCommentsDialog(val) {
      if (val) {
        return;
      }
      this.clearRowIdQuery();
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['showCommentsModalTrigger', 'clearCommentsModalTrigger']),
    ...mapMutations('Collections', ['changeProductModalVariant']),
    clearRowIdQuery() {
      const { query } = this.$route;
      this.$router.replace({
        query: {
          ...query,
          rowId: undefined,
        },
      }).catch(() => {
      });
    },
    saveFileProcess(e = false) {
      this.isLoadingImages = e;
    },
  },
};
</script>
<style scoped lang="scss">
  @import '~vuetify/src/styles/settings/_variables';
  .v-dialog.v-dialog__row-expanded-view {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      overflow: auto;
    }
    .row-data-list {
      height: 75vh;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-height: inherit;
      }
    }
    .row-comment {
      height: 75vh;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      @media only screen and (max-width: 959px)  {
        margin-top: 12px;
      }
      .v-card {
        padding: 12px 0;
        &__wrapper {
          min-height: 300px;
          height: 100%;
        }
        &__actions {
          position: absolute;
          width: 100%;
          padding-top: 0 !important;
        }
      }
    }
  }
</style>
