<template>
  <div
    class="bottom-items"
    :class="{
      'bottom-items__shared-project': sharedLink,
      'bottom-items__gallery-view': isListingView,
    }">
    <div
      class="d-flex align-baseline bottom-items__container">
      <div
        v-if="!isVersionMode"
        class="d-flex">
        <AddNewRow
          v-if="!hideAddingNewRow"
          :table-id="scheduleId" />
        <ListDeletedRows v-if="!hideRemoveProject" />
      </div>
      <SwitcherToListing
        :value="isListingView"
        class="ml-6"
        @toggleViewMode="toggleViewModeHandler" />
      <SharedLink
        v-if="!isVersionMode && !hideSharedLink"
        class="ml-auto mr-2"
        :project-id="projectId" />
    </div>
  </div>
</template>
<script>
import ToggleViewMode from '@/mixins/ToggleViewMode';
import { mapMutations } from 'vuex';
export default {
  name: 'ProjectDetailsFooterActions',
  components: {
    SharedLink: () => import('@/components/ProjectDetails/ProjectDetailsSharedLink'),
    SwitcherToListing: () => import('@/components/App/AppListingElements/AppSwitcherToListing'),
    AddNewRow: () => import('@/components/ProjectDetails/ProjectDetailsTableSchedule/TableAddNewRow'),
    ListDeletedRows: () => import('@/components/ProjectDetails/ProjectDetailsTableSchedule/TableListDeletedRows'),
  },
  mixins: [ToggleViewMode],
  props: {
    isListingView: {
      type: Boolean,
      default: false,
    },
    isVersionMode: {
      type: Boolean,
      default: false,
    },
    hideSharedLink: {
      type: Boolean,
      default: false,
    },
    hideRemoveProject: {
      type: Boolean,
      default: false,
    },
    hideAddingNewRow: {
      type: Boolean,
      default: false,
    },
    scheduleId: {
      type: String,
      default: null,
    },
    sharedLink: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      default: null,
    },
    selectedViewId: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapMutations('SearchProjectCells', ['clearSearchString']),
    toggleViewModeHandler(e) {
      this.clearSearchString();
      this.toggleViewMode({
        criteria: this.selectedViewId,
        value: e,
      });
    },
  },
};
</script>
<style scoped lang="scss">
  @import '~vuetify/src/styles/settings/_variables';
  .bottom-items {
    display: block;
    position: sticky;
    position: -webkit-sticky;
    z-index: 3;
    background: #fff;
    width: 100%;
    bottom: 0;
    padding: 8px 32px;
    &__gallery-view {
      margin-top: 48px;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 8px;
      &:not(&__shared-project) {
        bottom: 65px;
      }
      ::v-deep .listing-container {
        .v-input__slot {
          flex-direction: column;
        }
      }
    }
    &__container {
      width: 100%
    }
  }
</style>