<template>
  <v-row
    class="ma-0 mr-2">
    <v-col
      :sm="isTwinBlock || splitHeaderAndCell ? '12' : '3'"
      cols="12"
      class="title d-flex pl-0">
      <p
        v-if="isTypeVertical"
        style="font-size: 14px; margin: 0"
        :class="textClass">
        {{ headerText }}
      </p>
      <slot
        v-if="isTwinBlock"
        name="textAdditional" />
      <p
        v-if="isRequiredField"
        class="required-field ma-0">
        *
      </p>
    </v-col>
    <v-col
      class="filed pl-0"
      :sm="splitHeaderAndCell ? '11' : colSizeField"
      cols="11">
      <slot name="rowCell" />
    </v-col>
    <v-col
      v-if="!isTwinBlock"
      class="px-0 comment"
      :sm="!singleItem ? '2' : '1'"
      cols="1">
      <slot
        name="textAdditional" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'AppFormRow',
  props: {
    filterNamesGroupItems: {
      type: Array,
      default: () => [],
    },
    singleItem: {
      type: Boolean,
      default: true,
    },
    headerText: {
      type: String,
      default: '',
    },
    isRequiredField: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'vertical',
    },
    splitHeaderAndCell: {
      type: Boolean,
      default: false,
    },
    /**
     * Only in horizontal type
     */
    textClass: {
      type: String,
      default: 'font-weight-bold d-flex row-data__header',
    },
    smCols: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isFullWidthField() {
      return this.filterNamesGroupItems.includes(this.headerText);
    },
    colSizeField() {
      const { isFullWidthField, singleItem, smCols } = this;
      if (smCols) return smCols;
      if (isFullWidthField && !singleItem) return 12;
      else if (isFullWidthField && singleItem) return 5;
      return 8;
    },
    isTypeVertical() {
      return this.type === 'vertical';
    },
    isTwinBlock() {
      return !this.singleItem || this.filterNamesGroupItems.includes(this.headerText);
    },
  },
};
</script>
<style lang="scss" scoped>
  .title, .filed {
    padding: 10px !important;
  }
  .filed {
    align-self: baseline;
    padding-bottom: 5px !important;
  }
  .title {
    gap: 10px;
    padding-top: 15px !important;
  }
  .comment {
    padding: 10px 0;
  }
  .required-field {
    color: var(--v-mainRed-base);;
  }
</style>
