var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"m-4",attrs:{"loading":_vm.isInitData,"disable-sort":"","must-sort":"","calculate-widths":"","fixed-header":"","mobile-breakpoint":0,"hide-default-footer":"","height":"380px","show-select":"","disable-pagination":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [(!_vm.isRestoreMode && _vm.items.length)?_c('v-checkbox',{attrs:{"hide-details":"","ripple":false},on:{"change":_vm.changeSelectAll},model:{value:(_vm.isSelectAll),callback:function ($$v) {_vm.isSelectAll=$$v},expression:"isSelectAll"}}):_vm._e()]},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"wrapper-prepend-cell"},[(!_vm.isRestoreMode)?_c('v-checkbox',{attrs:{"hide-details":"","multiple":"","value":item,"ripple":false},model:{value:(_vm.selectDoc),callback:function ($$v) {_vm.selectDoc=$$v},expression:"selectDoc"}}):_vm._e(),_c('v-icon',{staticClass:"brightness-icon",attrs:{"medium":"","color":"mainGrey"},on:{"click":function($event){return _vm.setMenuItem({
            item: item,
            event: $event,
          })}}},[_vm._v(" mdi-dots-vertical ")])],1)]}},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('div',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(header.text || ''),expression:"header.text || ''"}],key:header.value})]},proxy:true}}),_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
          var item = ref.item;
return [_c('document-cell',_vm._g({key:header.value,attrs:{"is-restore-mode":_vm.isRestoreMode,"arr-type-designation":_vm.arrTypeDesignation,"item":item,"tags":_vm.tags,"header":header}},_vm.$listeners))]}}})],null,true)}),_c('document-cell-actions',_vm._g({attrs:{"is-disabled-delete":_vm.isDisabledDelete,"show-activator":false,"item":_vm.selectedItem,"position-x":_vm.positionX,"position-y":_vm.positionY,"value":_vm.showActionsCard},on:{"update:value":function($event){_vm.showActionsCard=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._t("customActions",null,{"item":item})]}}],null,true)},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }